import { Injectable } from '@angular/core';
import { http } from "./config.service"

@Injectable({
  providedIn: 'root'
})

export class HomeService {
  checkCookies(temStorage) {
    return http.get('api/home/' + temStorage)
  }
}
