import { Injectable } from '@angular/core';
import { http } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class DocService {
  static readonly urlDocs : string = 'api/docs/';

  constructor() { };

  GetDocs() {
      return http.get(DocService.urlDocs);
  }

  GetDoc(id) {
    return http.get(DocService.urlDocs + id)
  }
}
