import { Injectable } from '@angular/core';
import { http } from "./config.service"

@Injectable({
  providedIn: 'root'
})

export class SchemaService {

  GetSchemas() {
    return http.get('api/schema/')
  }
  GetSchema(id) {
    return http.get('api/schema/' + id)
  }

  Inserir(sch){
    return http.post('api/schema/', sch)
  }

  AtualizarSchema(Schema){
    return http.put('api/schema/', Schema)
  }

  RemoverSchema(id){
    return http.delete('api/schema/' + id)
  }
}
