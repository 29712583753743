<div class="content">
  <form>
    <div id="barraLateral">
      <div (click)="goBack()" class="backButton">
        <mat-icon style="color: #888; float:left;">
          <span class="material-icons-sharp">reply</span>
        </mat-icon>
      </div>
      <a (click)="AbrirGaleria(true)" class="areaButton">
        <mat-icon style="color: #888; float:left;">
          <span class="material-icons-sharp">photo_library</span>
        </mat-icon>
        <span class="buttonTitle">GALERIA</span>
      </a>
      <a (click)="AbrirGaleria(false)" class="areaButton">
        <mat-icon style="color: #888; float:left;">
          <span class="material-icons-sharp">storage</span>
        </mat-icon>
        <span class="buttonTitle">DRIVE</span>
      </a>
    </div>
    <div id="topo" *ngIf="intervention != undefined"><span style="line-height: 30px;">{{intervention.name}}</span></div>

    <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
      [show]="!carregado">
    </ngx-loading>

    <div id="areaDados" *ngIf="!verGaleria">
      <div id="publicacoes" class="quadro">
        <div class="barraTitulo">
          <span class="titulo">PUBLICAÇÕES NA INTERVENÇÃO</span>
        </div>
        <div class="conteudo">
          <div class="fixarConteudoPainel">
            <mat-accordion *ngFor="let file of files">
              <mat-expansion-panel [id]="file.id" hideToggle>
                <mat-expansion-panel-header class="headerPanel">
                  <mat-panel-title>
                    <mat-icon style="color: #1f71b4; float:left;">
                      <span class="material-icons-sharp">folder</span>
                    </mat-icon>
                    <span style="float: left;">{{file.name.split('.')[0]}}</span>

                    <a class="attachLinkTrashFile" ngbTooltip="Remover"  placement="left" (click)="openModalToRemoveAttachAndFile(myModalRemoveAnexoAndFile, file);">
                      <mat-icon matListIcon>delete</mat-icon>
                    </a>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div *ngIf="getLastRevision(file); let lRev">
					<a [ngbTooltip]="lRev.description" [href]="getUrlFile(lRev.content)" [download]="file.name + '.json'">
					  <mat-icon style="float: left;">
						<span class="material-icons-sharp">insert_drive_file</span>
					  </mat-icon>
					  <span style="float:left; line-height: 24px;">
						{{file.name}}
					  </span>
					</a>
					<span style="float: right; font-size: 12px; margin-left: 15px;">N&ordm; de revisões: <b>{{
						file == null ? 0 : lRev.id}}</b></span>
				</div>

				<div *ngIf="getLastRevision(file) == null">
					<span style="float: right; font-size: 12px; margin-left: 15px;">N&ordm; de revisões: <b>0</b></span>
				</div>


                <span *ngIf="getLastRevision(file) == null" style="float: right; font-size: 12px;">Criado: <b>{{calculateDiff(file)}}</b> por
                  <b>{{file.chaveUsuario.toUpperCase()}}</b></span>

				  <span *ngIf="getLastRevision(file)" style="float: right; font-size: 12px;">Última revisão: <b>{{calculateDiff(file)}}</b> por
					<b>{{file.chaveUsuario.toUpperCase()}}</b></span>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div class="funcoesRodape"></div>
      </div>
      <div id="anexos" class="quadro">
        <div class="barraTitulo">
          <span class="titulo">ANEXOS</span>
          <a *ngIf="attachments != undefined" ngbTooltip="Enviar" placement="left"
            class="uploadLink" (click)="open(mymodal)">
            <mat-icon matListIcon>file_upload</mat-icon>
          </a>
          <!-- <input type="file" name="attach" id="attach" (change)="saveFile($event.target)" style="display: none;"> -->
        </div>
        <div class="conteudo">
          <div class="fixarConteudoAnexos">
            <mat-list>
              <mat-list-item *ngFor="let attach of attachments">
                <mat-icon style="color: #1f71b4; float: left;" matListIcon
                  *ngIf="attach.mimeType.substring(0,5) == 'image' && attach.mimeType != 'application/pdf' && attach.mimeType.substring(0,5) != 'video'">
                  photo
                </mat-icon>
                <mat-icon style="color: #1f71b4; float: left;" matListIcon
                  *ngIf="attach.mimeType.substring(0,5) == 'video'">
                  video_library</mat-icon>
                <mat-icon style="color: #1f71b4; float: left;" matListIcon *ngIf="attach.mimeType == 'application/pdf'">
                  picture_as_pdf</mat-icon>

                <mat-icon style="color: #1f71b4; float: left;" matListIcon
                  *ngIf="attach.mimeType != 'application/pdf' && attach.mimeType.substring(0,5) != 'video' && attach.mimeType.substring(0,5) != 'image'">
                  text_snippet</mat-icon>

                <span style="float: left;" class="attachName">{{attach.name.split('__')[0]}}</span>

                <a id={{attach.anexoId}} [download]="attach.name.split('__')[0]" href="#" style="display: none;"></a>
                <a class="attachLink" ngbTooltip="Download" placement="left" (click)="getAttachDownload(attach)">
                  <mat-icon matListIcon>download</mat-icon>
                </a>
                &nbsp;&nbsp;
                <a class="attachLinkTrash" ngbTooltip="Remover"  placement="left" (click)="openModalToRemoveAttachAndFile(myModalRemoveAnexoAndFile, attach)">
                  <mat-icon matListIcon>delete</mat-icon>
                </a>

              </mat-list-item>
            </mat-list>
          </div>
        </div>
        <div class="funcoesRodape"></div>
      </div>
    </div>

    <div id="areaDadosGaleria" *ngIf="verGaleria">

      <mat-accordion style="width: 48%;">
        <mat-expansion-panel style="width: 48%;" hideToggle #mep="matExpansionPanel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon style="color: #1f71b4; float:right;">
                <span class="material-icons-sharp">filter_alt</span>
              </mat-icon>
              <span style="float: right;">Filtro</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">
            <div class="col-md-3" *ngIf="intervention != undefined && intervention.tipoIntervencao == 'Perfuração' ">
              <label>Fase</label>
              <input type="text" [(ngModel)]="filtrosGaleria.fase" style="text-transform: uppercase;" name="faseFiltro"
                class="form-control">
            </div>
            <div class="col-md-4">
              <label>Atividade</label>
              <ng-select notFoundText="Nenhum registro encontrado!" (change)="PreencheOperacoesFiltro($event)"
                (clear)="limparSelecaoAtividadeFiltro()" style="min-width: 250px;" [items]="filtroListaAtividades"
                bindLabel="descricao" bindValue="descricao" [(ngModel)]="filtrosGaleria.atividade"
                placeholder="Selecionar" appendTo="body" name="atividadeFiltro">
                <ng-template ng-option-tmp let-item2="item">
                  <div [ngbTooltip]="item2.descricao">{{item2.descricao}}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-md-5">
              <label>Operação</label>
              <ng-select notFoundText="Selecione uma atividade!" (clear)="limparSelecaoOperacaoFiltro()"
                style="min-width: 250px" [(ngModel)]="filtrosGaleria.operacao" [items]="filtroListaOperacoes"
                bindLabel="descricao" bindValue="descricao" placeholder="Selecionar" appendTo="body"
                name="descricaoFiltro">
                <ng-template ng-option-tmp let-item="item">
                  <div [ngbTooltip]="item.descricao">{{item.descricao}}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <br>
          <div class="row">

            <div class="col-md-4">
              <label>Período</label>
              <mat-form-field appearance="outline">
                <mat-date-range-input [rangePicker]="picker">
                  <input (click)="picker.open()" name="dataInicioFiltro" matStartDate placeholder="Inicio">
                  <input (click)="picker.open()" name="dataFimFiltro" matEndDate placeholder="Fim">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker touchUi #picker></mat-date-range-picker>
              </mat-form-field>
            </div>

            <div class="col-md-7">
              <label>Tags</label>
              <tag-input style="margin-top: -6px; text-transform: uppercase;" name="tagFiltro"
                [secondaryPlaceholder]="'Digite ou selecione'" [onlyFromAutocomplete]="true"
                [placeholder]="'Digite ou selecione'" [onTextChangeDebounce]="10" [(ngModel)]="tagsFiltro"
                [theme]="'bootstrap'" [addOnBlur]="true" [clearOnBlur]="true">
                <tag-input-dropdown [minimumTextLength]="2" [autocompleteItems]="autocompleteItemsFiltro">
                </tag-input-dropdown>
              </tag-input>
            </div>
          </div>

          <br>
          <div class="modal-footer" style="float: left; border-top: none; margin-left: -16px;">
            <button type="button" (click)="filtrar();" class="btn btn-primary">
              Filtrar</button>
          </div>

          <div class="modal-footer" style="float: right; border-top: none; margin-right: 40px;">
            <button type="button" class="btn btn-danger" (click)="mep.expanded = false;">
              Fechar</button>

            <button type="button" class="btn btn-secondary" (click)="limpaCamposFiltros()">
              Limpar</button>
          </div>


        </mat-expansion-panel>
      </mat-accordion>

      <br>
      <mat-tab-group>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon" style="color: #1f71b4;">photo</mat-icon>&nbsp;
            <span style="font-weight: bold; color: black;">Imagens</span>
          </ng-template>
          <br>
          <div class="fixarConteudoGaleria">
            <ngx-gallery [options]="galleryOptions" [images]="galeria" class="ngx-gallery"></ngx-gallery>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon" style="color: #1f71b4;">insert_drive_file</mat-icon>&nbsp;
            <span style="font-weight: bold; color: black;">Arquivos</span>
          </ng-template>
          <br>
          <div class="fixarConteudoGaleria">
            <div class="row">
              <div class="col-sm-2 col-md-2 col-lg-2" *ngFor="let attach of arquivoGenericos">
                <a id={{attach.anexoId}} [download]="attach.name.split('__')[0]" href="#" style="display: none;"></a>
                <div style="width: 100%; text-align: center; cursor: pointer;" (click)="getAttachDownload(attach)">

                  <mat-icon *ngIf="attach.mimeType == 'application/pdf' " class="icone"
                    style="color: rgb(36, 98, 168); font-size: 50px; ">
                    <span class="material-icons-outlined">
                      picture_as_pdf
                    </span>
                  </mat-icon>

                  <mat-icon class="icone" style="color: #1f71b4; font-size: 50px;" *ngIf="attach.mimeType.substring(0,5) == 'image' && attach.mimeType != 'application/pdf' &&
                  attach.mimeType.substring(0,5) != 'video'">
                    <span class="material-icons-outlined">
                      photo
                    </span>
                  </mat-icon>
                  <mat-icon class="icone" style="color: #1f71b4; font-size: 50px;"
                    *ngIf="attach.mimeType.substring(0,5) == 'video'">
                    <span class="material-icons-outlined">
                      video_library
                    </span>
                  </mat-icon>

                  <mat-icon class="icone" style="color: #1f71b4; font-size: 50px;"
                    *ngIf="attach.mimeType != 'application/pdf' && attach.mimeType.substring(0,5) != 'video' && attach.mimeType.substring(0,5) != 'image'">
                    <span class="material-icons-outlined">
                      text_snippet
                    </span>
                  </mat-icon>
                </div>
                <div style="width: 100%; text-align: center; cursor: pointer;">
                  <a class="itemEstrutura" [download]="attach.name.split('__')[0]" (click)="getAttachDownload(attach)">
                    {{attach.name.split('__')[0]}}
                  </a>

                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <ng-template #mymodal let-modal>
      <div class="content">
        <div class="modal-header">
          <h3 class="modal-title fontePetrobras" id="modal-basic-title">Inserir Anexo</h3>
          <button style="outline: none;" type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <label>Selecionar arquivo*</label>
              <input type="file" id="selecionarFile" (change)="onFileSelect($event.target)" accept="image/* video/*"
                class="form-control">
            </div>
            <div class="col-md-6">
              <label>Tags*</label>
              <tag-input style="margin-top: -6px; text-transform: uppercase;" [(ngModel)]="tags" name="tags"
                [secondaryPlaceholder]="'Digite ou selecione'" [placeholder]="'Digite ou selecione'"
                [onTextChangeDebounce]="10" [theme]="'bootstrap'" [editable]="true" [addOnBlur]="true"
                [clearOnBlur]="true">
                <tag-input-dropdown [minimumTextLength]="2" [autocompleteItems]="autocompleteItems">
                </tag-input-dropdown>
              </tag-input>
            </div>


          </div>

          <br>
          <div class="row">
            <div class="col-md-3">
              <label>Data</label>
              <input [(ngModel)]="data" id="inputData" (ngModelChange)="limparSelecaoData()" class="form-control"
                type="date" name="dataInicio" />
            </div>
          </div>

          <br>
          <div class="row">

            <div class="col-md-3" *ngIf="intervention != undefined && intervention.tipoIntervencao == 'Perfuração' ">
              <label>Fase</label>
              <input type="text" [(ngModel)]="fase" style="text-transform: uppercase;" name="fase" class="form-control">
            </div>

            <div class="col-md-4">
              <label>Atividade</label>
              <ng-select notFoundText="Nenhum registro encontrado!" (change)="PreencheOperacoes($event)"
                (clear)="limparSelecaoAtividade()" style="min-width: 250px;" [items]="atividades" bindLabel="descricao"
                bindValue="descricao" placeholder="Selecionar" appendTo="body" name="atividade" [(ngModel)]="atividade">
                <ng-template ng-option-tmp let-item2="item">
                  <div [ngbTooltip]="item2.descricao">{{item2.descricao}}</div>
                </ng-template>
              </ng-select>
            </div>

            <div class="col-md-4">
              <label>Operação</label>
              <ng-select notFoundText="Selecione uma atividade!" (clear)="limparSelecaoOperacao()"
                style="min-width: 250px;" [items]="operacoes" bindLabel="descricao" bindValue="descricao"
                placeholder="Selecionar" appendTo="body" name="descricao" [(ngModel)]="operacao">
                <ng-template ng-option-tmp let-item="item">
                  <div [ngbTooltip]="item.descricao">{{item.descricao}}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <br>
          <div class="row">
            <div class="col-md-12">
              <label>Descrição</label>
              <textarea [(ngModel)]="descricaoAnexo" class="form-control" name="UrlRedirecionamento"></textarea>
            </div>
          </div>
          <br>
          <div class="row" style="font-size: 14px; font-weight: bold; color: red;" *ngIf="arquivoJaExiste">
            <span style="margin-left: 38%;">Este arquivo já existe!</span>
          </div>
        </div>
        <div class="modal-footer" style="float: left; border-top: none;">
          <button type="button" id="btnSalvarAnexo" class="btn btn-primary" (click)="saveFile();"
            [disabled]="!desabilitaCampos() && !loading">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Salvar</button>
        </div>
      </div>
    </ng-template>

    <!-- Modal Remove anexo -->
    <ng-template #myModalRemoveAnexoAndFile let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="width: 100%; text-align: center;">
          Confirmação! </h4>
        <button type="button" style="outline: none" class="close" aria-label="Close"
          (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
            <label style="width: 100%; text-align: center;">Tem certeza que deseja remover? <b style="color: red;">Esta ação não poderá ser desfeita!</b></label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" *ngIf="idAnexoToRemove != undefined"
          (click)="modal.dismiss('Cross click'); removeAttach();">OK</button>

          <button type="submit" class="btn btn-primary" *ngIf="idAnexoToRemove == undefined && idFileToRemove != undefined"
          (click)="modal.dismiss('Cross click'); removeFile();">OK File</button>


      </div>
    </ng-template>
    <!-- FIM Modal Remove anexo -->
  </form>
</div>
