<div class="content">
  <form class="p-3" ngNativeValidate>
    <div class="card">
      <div class="card-header textoNoCentro">
        ATUALIZAR USUÁRIO
      </div>
      <div class="card-body" *ngIf="usuario != null">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>


        <div class="row">

          <div class="col-md-6">
            <label for="chave">Chave</label>
            <input type="text" class="form-control" name="chave" id="chave" [(ngModel)]="usuario.chave" readonly>
          </div>

        </div>
        <br>
        <br>
        <div class="row">

          <div class="col-md-6" *ngIf="listaAplicacoes != null">
            <label style="font-weight: bold;">Adicionar aplicações</label>
            <br><br>
            <mat-selection-list [(ngModel)]="listaAplicacoesSelecionadas" name="AplicacaoSelecionada" #apl class="table">

              <mat-list-option [selected]="selecionaCheckBox(apli.idAplicacao)" [value]="apli.idAplicacao" checkboxPosition="before" [color]="cor"
                *ngFor="let apli of listaAplicacoes">
                {{apli.nomeAplicacao}}
              </mat-list-option>
            </mat-selection-list>
            <p *ngIf="aplica != undefined">
              Item(ns) selecionado(s): {{apl.selectedOptions.selected.length}}
            </p>

          </div>


        </div>
        <br>

        <br>
        <button class="btn btn-default" style="border: 1px solid black;" type="submit" (click)="voltarPagina()">
          Cancelar</button>
        &nbsp;&nbsp;&nbsp;
        <button class="btn btn-success" (click)="updateUsuario()" type="submit">
          Atualizar
        </button>
      </div>
      <div class="card-body" *ngIf="usuario === null">
        <span>Usuário não encontrado ou já foi removido!</span>
      </div>
    </div>
  </form>
</div>
