import { Component, OnInit } from '@angular/core';
import { LoginData } from '../model/login-data';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['../app.component.css']
})
export class ClientComponent implements OnInit {
  loginData : LoginData;

  title = 'PWO - Início';
  constructor() { }

  ngOnInit(): void {
    this.loginData = JSON.parse(localStorage.getItem('loginData'))
  }

}
