<div class="content">
  <form class="p-3">
    <div class="card">
      <div class="card-header textoNoCentro">
        LISTA DE CLIENTS
      </div>
      <div class="card-body">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>
        <button class="btn btn-success" routerLink="../create-client">NOVO</button>
        <br><br><br>



        <div class="row">
          <div class="col-md-6">
            <div class="form-group has-search">
              <span class="form-control-feedback">
                <i class="material-icons">
                  search
                </i>
              </span>
              <input type="text" class="form-control" (keyup)="filtrar($event.target.value)"
                placeholder="Busca rápida por client">
            </div>
          </div>
        </div>
        <div class="mat-elevation-z3" *ngIf="carregado">
          <div class="fixarConteudoAplicaoes">
            <mat-table class="table" #table [dataSource]="dataSource" *ngIf="apps != undefined">

              <!-- chave Column -->
              <ng-container matColumnDef="nomeSonda">
                <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                <mat-cell *matCellDef="let element">

                  {{element.nomeSonda}}
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="aplicacao">
                <mat-header-cell *matHeaderCellDef>Aplicações vinculadas</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <table>
                    <tr *ngFor="let app of element.app">
                      <td [ngClass]="app.status ? '' : 'text-danger' ">{{app.nomeAplicacao}}</td>
                    </tr>
                  </table>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="topic">
                <mat-header-cell *matHeaderCellDef> Topic </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <table>
                    <tr *ngFor="let app of element.app">
                      <td [ngClass]="app.status ? '' : 'text-danger' ">{{app.topic}}</td>
                    </tr>
                  </table>
                </mat-cell>
              </ng-container>

              <!-- Ações -->
              <ng-container matColumnDef="acoes">
                <mat-header-cell class="right" *matHeaderCellDef> Ações </mat-header-cell>
                <mat-cell class="right" *matCellDef="let element">
                  <a [ngbTooltip]="'Editar ' + element.nomeSonda" class="edit"
                    routerLink="../update-client/{{element.sondaId}}">
                    <i class="material-icons edit">edit</i>
                  </a>

                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row class="mat-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>

              <tr class="row" *matNoDataRow>
                <td class="col-md-12" style="color: red; text-align: center; font-weight: bold; font-size: 16px;">
                  <br>Nenhum registro encontrado!</td>
              </tr>

            </mat-table>
          </div>
        </div>
      </div>
    </div>
  </form>
