export class MqttClient {
  sondaId?: number;
  aplicacaoId?: any;
  topicoMQTT?: string;
  dataConexao?: Date;
}

export class MqttSelectedApps extends MqttClient {

  nomeSonda?: string;
  nomeAplicacao?: string;
  chaveUsuarioCadastro?: string;
  chaveUsuarioUpdate?: string;
  status?: boolean;
  situacao?: string;
}

export class MqttHistoricoClient {

  topic?: string;
  createdAt?: Date;
  messageType?: string;
  typeOrName?: string;
  fileExtension?: string;
  parent?: string;
  operation?: string;
}
