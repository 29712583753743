<div class="content">
  <form class="p-3">
    <div class="card">
      <div class="card-header">
        Página inicial
      </div>
      <div class="card-body">
        <div *ngIf="loginData == null">
          <h6>Você não está logado!</h6>
          <br>
          <span>
            <button class="btn btn-success" routerLink="../login">Clique aqui para fazer login</button>
          </span>
        </div>

        <div *ngIf="loginData != null">
          <h6>PÁGINA INICIAL!</h6>
        </div>

      </div>
    </div>
  </form>
</div>
