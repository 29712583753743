<div id="topBar">
    <span class="tituloPagina">Documentação API - PWO</span>
</div>
<div id="principal">
    <div id="sideMenu">
        <ul>
            <div *ngFor="let endpoint of endpoints">
                <h4>{{endpoint.titulo}}</h4>
                <div *ngFor="let metodo of endpoint.metodos">
                    <li (click)="scrollToElement(metodo.id)">{{metodo.titulo}}</li>
                </div>
            </div>
        </ul>
    </div>
    <div id="content">
        <fieldset *ngFor="let endpoint of endpoints">
            <legend>{{endpoint.titulo}}</legend>
            <div class="areaFunction" *ngFor="let metodo of endpoint.metodos">
                <h2 id="{{metodo.id}}">{{metodo.titulo}}</h2>
                <p>{{metodo.descricao}}</p>
                <h3>Parâmetros</h3>
                <ul *ngIf="metodo.parametros.length == 0">
                    <li>Não se enquadra</li>
                </ul>
                <ul *ngIf="metodo.parametros.length > 0">
                    <div *ngFor="let parametro of metodo.parametros">
                        <li>
                            <p>{{parametro.tipo}} {{parametro.nome}} - {{parametro.descricao}}</p>
                        </li>
                    </div>
                </ul>
                <h3>Retorno</h3>
                <p>{{metodo.retorno}}</p>
            </div>
        </fieldset>
    </div>
</div>
