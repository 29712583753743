import { Injectable } from '@angular/core';
import { http } from "./config.service"

@Injectable({
  providedIn: 'root'
})

export class TipoArquivoService {

  AtualizarTipoArquivo(arq){
    return http.put('api/tipoArquivo/', arq)
  }
  GetTiposArquivos() {
    return http.get('api/tipoArquivo/')
  }
  GetTipoArquivo(id) {
    return http.get('api/tipoArquivo/' + id)
  }

  Inserir(arq){
    return http.post('api/tipoArquivo/', arq)
  }
}
