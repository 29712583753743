import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions } from 'chart.js/dist/Chart.js';
import * as moment from 'moment';
import { Colors, Label } from 'ng2-charts';
import { UsuarioService } from 'src/app/services/usuario.service';

declare var $: any

@Component({
	selector: 'app-acesso-usuarios',
	templateUrl: './acesso-usuarios.component.html',
	styleUrls: ['./acesso-usuarios.component.css']
})
export class AcessoUsuariosComponent implements OnInit {

	carregado = false;
	dadosGrafico: any;


	qtdAcessoAnual: any[] = [];
	qtdAcessoMensal: any[] = [];
	qtdAcessoSemanal: any[] = [];

	qtdAcessoMensalComKPI: any[] = [];

	dataPorDia: any[] = [];
	dataPorDiaKPI: any[] = [];
	dataPorSemana: any[] = [];
	diaMensal: any[] = [];

	lineChartData: ChartDataSets[];
	lineChartLabels: Label[];
	lineChartLabelsKPI: Label[];
	lineChartColors: Colors[];
	lineChartColorsKPI: Colors[];
	lineChartLegend: boolean;
	lineChartType: string;

	lineChartDataKPI: ChartDataSets[];

	// minDate = new FormControl(moment([2020, 0, 1]));
	// maxDate = new FormControl(moment([2020, 0, 1]));
	
	// minDate: Date;
	endDate: Date;
	beginDate: Date;

	constructor(private userService: UsuarioService, private router: Router) {
	}

	myFilter = (d: Date | null): boolean => {
		const day = (d || new Date()).getDay();
		// Prevent Saturday and Sunday from being selected.
		return day !== 0 && day !== 6;
	  };

	  handleChange(event) {

		var formatoData = "DD/MM/yyyy";
		var inicioSemFormatacao = $('[name="dataInicioFiltro"]').val();

		var dataInicio = moment(inicioSemFormatacao, formatoData);
		this.beginDate = dataInicio.toDate();
		
		// var dataTermino = dataInicio.add(1, 'days');
		this.endDate = dataInicio.toDate();
		$('[name="dataFimFiltro"]').val("");
		
		
	  }

	ngOnInit(): void {

		window.document.title = 'PWO - Painel de Acessos'

		this.populaDataFiltro()

		var dataFim = moment().subtract(1, 'days').format("YYYY-MM-DD"); //dia anterior

		var dataInicio = moment().subtract(1, 'years').day(1).format("YYYY-MM-DD"); // ano anterior

		this.buscarDados(dataInicio, dataFim)
	}

	populaDataFiltro() {
		var dataInicioFiltro = moment().subtract(1, 'years').day(1).format("DD/MM/yyyy");

		var dataFimFiltro = moment().subtract(1, 'days').format("DD/MM/yyyy");

		$('[name="dataInicioFiltro"]').val(dataInicioFiltro);

		$('[name="dataFimFiltro"]').val(dataFimFiltro);
	}

	async buscarDados(dataInicio, dataFim) {
		try {
			await this.userService.GetUserGraphic(dataInicio, dataFim).then(res => {
				this.dadosGrafico = res.data

				console.log(this.dadosGrafico)
				this.preencherGrafico()
			})
			setTimeout(() => {
				this.carregado = true;
			}, 100);

		} catch (error) {
			this.carregado = true
			console.log(error)
		}
	}

	async preencherGrafico() {

		if (this.dadosGrafico != undefined && this.dadosGrafico != "") {
			for (let index = 0; index < this.dadosGrafico.qtdAnual.length; index++) {

				if( this.dadosGrafico.qtdAnual.length > 60 && this.dadosGrafico.qtdAnual.length <= 90){

					if(index % 2 == 0 ){
						var qtdAno = this.dadosGrafico.qtdAnual[index].qtdAcessos;

						var dataDia = this.dadosGrafico.qtdAnual[index].dia;

						this.dataPorDia.push(dataDia)

						this.qtdAcessoAnual.push(qtdAno)
					}

				}else if(this.dadosGrafico.qtdAnual.length > 90){

					if(index % 3 == 0 || index == 0){
						var qtdAno = this.dadosGrafico.qtdAnual[index].qtdAcessos;

						var dataDia = this.dadosGrafico.qtdAnual[index].dia;

						this.dataPorDia.push(dataDia)

						this.qtdAcessoAnual.push(qtdAno)
					}

				}else{

					var qtdAno = this.dadosGrafico.qtdAnual[index].qtdAcessos;

					var dataDia = this.dadosGrafico.qtdAnual[index].dia;

					this.dataPorDia.push(dataDia)

					this.qtdAcessoAnual.push(qtdAno)
				}
			}

			for (let index = 0; index < this.dadosGrafico.qtdSemanal.length; index++) {

				if(this.dadosGrafico.qtdSemanal.length > 60 && this.dadosGrafico.qtdSemanal.length <= 90){

					if(index % 2 == 0 ){
						var qtdSemana = this.dadosGrafico.qtdSemanal[index].mediaS;

						this.qtdAcessoSemanal.push(qtdSemana)
					}

				}else if(this.dadosGrafico.qtdSemanal.length > 90){

					if(index % 3 == 0 || index == 0){
						var qtdSemana = this.dadosGrafico.qtdSemanal[index].mediaS;

						this.qtdAcessoSemanal.push(qtdSemana)
					}

				}else{

					var qtdSemana = this.dadosGrafico.qtdSemanal[index].mediaS;

					this.qtdAcessoSemanal.push(qtdSemana)
				}
			}

			for (let index = 0; index < this.dadosGrafico.qtdMensal.length; index++) {

				if(this.dadosGrafico.qtdMensal.length > 60 && this.dadosGrafico.qtdMensal.length <= 90){

					if(index % 2 == 0 ){
						var qtdMes = this.dadosGrafico.qtdMensal[index].mediaM;

						this.qtdAcessoMensal.push(qtdMes)
					}

				}else if(this.dadosGrafico.qtdMensal.length > 90){

					if(index % 3 == 0 || index == 0){
						var qtdMes = this.dadosGrafico.qtdMensal[index].mediaM;

						this.qtdAcessoMensal.push(qtdMes)
					}

				}else{

					var qtdMes = this.dadosGrafico.qtdMensal[index].mediaM;

					this.qtdAcessoMensal.push(qtdMes)
				}
			}

			for (let index = 0; index < this.dadosGrafico.qtdKPI.length; index++) {

				if(this.dadosGrafico.qtdKPI.length > 60 && this.dadosGrafico.qtdKPI.length <= 90){
					if(index % 2 == 0 ){
						var qtdMesKPI = this.dadosGrafico.qtdKPI[index].mediaK;

						var diaKpi = this.dadosGrafico.qtdKPI[index].dia;

						this.qtdAcessoMensalComKPI.push(qtdMesKPI)

						this.dataPorDiaKPI.push(diaKpi)
					}
				}else if(this.dadosGrafico.qtdKPI.length > 90){

					if(index % 3 == 0 || index == 0){
						var qtdMesKPI = this.dadosGrafico.qtdKPI[index].mediaK;

						var diaKpi = this.dadosGrafico.qtdKPI[index].dia;

						this.qtdAcessoMensalComKPI.push(qtdMesKPI)

						this.dataPorDiaKPI.push(diaKpi)
					}

				}else{

					var qtdMesKPI = this.dadosGrafico.qtdKPI[index].mediaK;

					var diaKpi = this.dadosGrafico.qtdKPI[index].dia;

					this.qtdAcessoMensalComKPI.push(qtdMesKPI)

					this.dataPorDiaKPI.push(diaKpi)
				}
			}

			this.lineChartData = [
				{ data: this.qtdAcessoAnual, label: 'Quantidade de acessos', type: 'bar', },
				{ data: this.qtdAcessoSemanal, label: 'Média Semanal', type: 'line' },
				{ data: this.qtdAcessoMensal, label: 'Média Mensal', type: 'line' }
			];

			this.lineChartDataKPI = [
				{ data: this.qtdAcessoMensalComKPI, label: 'Indicador de Performance', type: 'line', },
			];

			//dados da parte inferior
			this.lineChartLabels = this.dataPorDia;

			this.lineChartLabelsKPI = this.dataPorDiaKPI;

			this.lineChartColors = [

				{
					backgroundColor: 'rgba(77,83,96,0.2)',
					borderColor: 'rgba(77,83,96,1)',
				},
				{
					backgroundColor: 'transparent',
					borderColor: 'red',
				},
				{
					backgroundColor: 'transparent',
					borderColor: 'blue',
				}
			];

			this.lineChartColorsKPI = [
				{
					backgroundColor: 'rgba(77,83,96,0.2)',
					borderColor: 'green',
				},
				{
					backgroundColor: 'rgba(77,83,96,0.2)',
					borderColor: 'rgba(77,83,96,1)',
				}
			];

			// Mostrando legendas
			this.lineChartLegend = true;

			// Define o tipo
			this.lineChartType = 'bar';
		}
		else {
			this.lineChartData = [
				{ data: [], label: 'Quantidade de acessos' }
			];

			this.lineChartDataKPI = [
				{ data: [], label: 'Indicador de Performance' }
			];
		}
	}

	filtrar() {

		this.carregado = false
		this.dataPorDia = []
		this.dataPorDiaKPI = []
		this.qtdAcessoAnual = []
		this.dataPorSemana = []
		this.qtdAcessoMensal = []
		this.qtdAcessoSemanal = []
		this.qtdAcessoMensalComKPI = []

		var ini = $('[name="dataInicioFiltro"]').val();

		var fim = $('[name="dataFimFiltro"]').val();

		let dataIniFiltro = ini = ini.split('/');

		dataIniFiltro = dataIniFiltro[2].substring(0, 4) + "-" + dataIniFiltro[1] + "-" + dataIniFiltro[0];

		let dataFimFiltro = fim = fim.split('/');

		dataFimFiltro = dataFimFiltro[2].substring(0, 4) + "-" + dataFimFiltro[1] + "-" + dataFimFiltro[0];

		this.buscarDados(dataIniFiltro, dataFimFiltro)
	}

	desabilitarBotaoFiltro() {
		if ($('[name="dataInicioFiltro"]').val() == '' || $('[name="dataFimFiltro"]').val() == '') {
			return false
		}
		return true
	}

	voltaParaListaDeAplicacoes() {
		this.router.navigate(['/AcessoAplicacao'])
	}
	// opções
	lineChartOptions: ChartOptions = {
		responsive: true,
		tooltips: {
			titleFontSize: 14,
			borderWidth: 2,
			borderColor: "white",
			displayColors: false,
		},
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
					display: true,
				},
				scaleLabel: {
					display: true,
					labelString: 'ACESSOS',
					fontSize: 14,
					fontColor: 'blue',
					scaleShowLabels: true,
				}
			}],
			xAxes: [{
				ticks: {
					autoSkip: false,
					maxRotation: 40,
					minRotation: 40,
				 }
			}]
		}

	};

	lineChartOptionsKPI: ChartOptions = {
		responsive: true,
		tooltips: {
			titleFontSize: 14,
			borderWidth: 2,
			borderColor: "white",
			displayColors: false,
		},
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
					display: true,
				},
				scaleLabel: {
					display: true,
					labelString: 'PERCENTUAL DE ATINGIMENTO',
					fontSize: 14,
					fontColor: 'green',
					scaleShowLabels: true,
				}
			}],
			xAxes: [{
				ticks: {
					autoSkip: false,
					maxRotation: 40,
					minRotation: 40,
				}
			}]
		}
	};
}
