import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginData } from 'src/app/model/login-data';
import { MqttService } from 'src/app/services/mqtt.service';

@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.css', '../../app.component.css']
})
export class UpdateClientComponent implements OnInit {

  carregado = false;
  aplica: any;
  listaAplicacoes: any[];
  listaAplicacoesSelecionadas: any;
  cor: string = "primary";
  loginData: LoginData;

  constructor(private snackBar: MatSnackBar,
    private mqttService: MqttService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getAplicacoes()
  }

  async update() {

    //this.carregado = false;

    const loginData = JSON.parse(localStorage.getItem('loginData'))

    if (this.listaAplicacoesSelecionadas.length > 0) {

      let naoMarcados = this.aplica.filter(item => this.listaAplicacoesSelecionadas.indexOf(item) === -1);

      for (let index = 0; index < this.listaAplicacoesSelecionadas.length; index++) {
        this.listaAplicacoesSelecionadas[index].status = true
        this.listaAplicacoesSelecionadas[index].chaveUsuarioUpdate = loginData.chave
      }

      if (naoMarcados.length > 0) {
        for (let index = 0; index < naoMarcados.length; index++) {
          naoMarcados[index].status = false
          naoMarcados[index].chaveUsuarioUpdate = loginData.chave
          this.aplica.find(x => x.aplicacaoId == naoMarcados[index].aplicacaoId).status = false
        }
      }
      else {
        this.aplica = this.listaAplicacoesSelecionadas
      }
    }
    else {
      for (let index = 0; index < this.aplica.length; index++) {
        this.aplica[index].status = false
        this.aplica[index].chaveUsuarioUpdate = loginData.chave
      }
    }

    await this.mqttService.Put(this.aplica).then(response => {
      if (response.data != null) {
        setTimeout(() => {
          this.carregado = true;
        }, 1000);
        this.snackBar.open("Ação concluida.", "OK", { duration: 5000, panelClass: ['sucesso'] });
        this.router.navigate(['administracao/mqtt/list-clients'])
      }
      else {
        this.carregado = true;
        this.snackBar.open("Erro ao atualizar.", "OK", { duration: 5000, panelClass: ['erro'] });
      }

    }).catch(error => {
      console.log(error)
      this.carregado = true;
      this.snackBar.open("Erro ao atualizar.", "OK", { duration: 5000, panelClass: ['erro'] })
    })
  }

  async getAplicacoes() {
    try {
      const idSonda = this.route.snapshot.paramMap.get('id')
      const response = await this.mqttService.GetSuitById(idSonda);
      if (response.data != null) {
        this.listaAplicacoes = response.data[0]
        this.aplica = this.listaAplicacoes[0].app
        setTimeout(() => {
          this.carregado = true;
        }, 500);
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.log(error);
    }
  }


  voltarPagina(): void {
    this.router.navigate(['administracao/mqtt/list-clients'])
  }

  async remover(sondaId, aplicacaoId) {
    try {
      this.carregado = false;
      await this.mqttService.Delete(sondaId, aplicacaoId).then(response => {
        if (response.data == true) {
          this.snackBar.open("Ação concluida.", "OK", { duration: 3000, panelClass: ['sucesso'] });
          setTimeout(() => {
            this.carregado = true;
          }, 500);

          this.getAplicacoes()
        }
        else {
          this.carregado = false;
          this.snackBar.open("Ação não permitida! Este client já recebeu/enviou mensagens.", "OK", { duration: 7000, panelClass: ['alerta'] });
          this.getAplicacoes()
        }
      });
    } catch (error) {
      this.carregado = false;
      this.snackBar.open("Erro ao exlcuir, entre em contato com o administrador!.", "OK", { duration: 5000, panelClass: ['alerta'] });
      this.getAplicacoes()
      console.error(error);
    }
  }

}
