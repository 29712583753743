import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Usuario } from '../../../model/usuario'
import { UsuarioService } from '../../../services/usuario.service';

@Component({
  selector: 'app-listar-usuarios',
  templateUrl: './listar-usuarios.component.html',
  styleUrls: ['../../../app.component.css']
})

export class ListarUsuariosComponent implements OnInit {
  chaveUsuarioLogado: null
  title = 'PWO - Controle de Usuários';

  @ViewChild('paginacao') set paginator(pager:MatPaginator) {
    if (pager) this.dataSource.paginator = pager;
  }

  displayedColumns = ['chave', 'nome', 'acoes'];
  dataSource: MatTableDataSource<Usuario>;
  usuarios: Usuario[];
  carregado = false;

  constructor(private usuarioService: UsuarioService,  public _MatPaginatorIntl: MatPaginatorIntl,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    const loginData = JSON.parse(localStorage.getItem('loginData'))
    this.chaveUsuarioLogado = loginData.chave
    window.document.title = this.title;
    this.getUsuarios()

    this._MatPaginatorIntl.firstPageLabel = 'Início';
    this._MatPaginatorIntl.itemsPerPageLabel = 'Itens por página';
    this._MatPaginatorIntl.lastPageLabel = 'Última';
    this._MatPaginatorIntl.nextPageLabel = 'Próxima';
    this._MatPaginatorIntl.previousPageLabel = 'Anterior';
    this._MatPaginatorIntl.getRangeLabel = this.getRangeDisplayText;
  }

  getRangeDisplayText = (page: number, pageSize: number, length: number) => {
    const initialText = `Mostrando`;  // customize this line
    if (length == 0 || pageSize == 0) {
      return `${initialText} 0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;
    return `${initialText} ${startIndex + 1} - ${endIndex} de ${length}`; // customize this line
  };

  filtrar(filtro: string) {
    this.dataSource.filter = filtro.trim().toLocaleLowerCase()
  }

  async getUsuarios() {
    try {
      const response = await this.usuarioService.GetTodosUsuarioAplicacao();
      if (response.data != null) {
        this.usuarios = response.data;
        this.dataSource = new MatTableDataSource<Usuario>(this.usuarios);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator
          this.carregado = true;
        }, 500);
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  async remover(valor) {
    this.carregado = false;
    await this.usuarioService.RemoverUsuarioAplicacao(valor).then(response => {
      if (response.data != null) {
        setTimeout(() => {
          this.carregado = true;
        }, 500);
        this.snackBar.open("Registro removido com sucesso.", "OK", { duration: 4000, panelClass: ['sucesso'] });
        this.getUsuarios();
      }
      else {
        this.carregado = true;
        this.snackBar.open("Erro ao remover.", "OK", { duration: 4000, panelClass: ['erro'] });
      }

    }).catch(error => {
      this.carregado = true;
      this.snackBar.open("Erro ao remover. ", "OK", { duration: 4000, panelClass: ['erro'] })
      console.error(error);
    })
  }
}

