import { Component, OnInit } from '@angular/core';
import { DocService } from '../services/doc.service';

@Component({
  selector: 'app-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.css']
})
export class DocComponent implements OnInit {
  endpoints: any[];
  title = "PWO Documentação";

  constructor(
    private docService: DocService
  ) { }

  ngOnInit(): void {
    this.getDocs();
    window.document.title = this.title;
  }

  async getDocs() {
    const response = await this.docService.GetDocs()
    if (response.data != null) {
      this.endpoints = response.data;
    }
  }

  scrollToElement(id): void {
    var element = document.getElementById(id)
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
