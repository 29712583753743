<mat-sidenav-container fullscreen >
  <mat-sidenav class="sidenav" style="background-color: white;" *ngIf="loginData != null" mode="side" #sidenav fixedInViewport="true" fixedTopGap="40"
    [opened]="!isShowing && verMenu" position="start">
    <a (click)="voltaParaListaDeAplicacoes()">
      <img style="padding-left: 14px;top: 54px; position: fixed;" height="50px" src="assets/Principal_h_cor_RGB.jpg">
    </a>
    <br><br>
    <div class="input-group mb-1">
      <div (click)="BuscaRapida(filtro)" class="input-group-prepend" style="width: 45px; cursor: pointer;">
        <span title="buscar" class="input-group-text">
          <i style="margin-left: -4px;" class="material-icons">
            search
          </i></span>
      </div>

      <input id="inputFiltro" (keyup.enter)="BuscaRapida(filtro)" [(ngModel)]="filtro"
        style="text-transform: uppercase;" type="text" class="form-control" placeholder="Busca rápida">
    </div>

    <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
      [show]="!carregado">
    </ngx-loading>
    <div class="fixarConteudoMenuPocos">

      <mat-table #table [dataSource]="dataSource" *ngIf="carregado">
        <ng-container matColumnDef="UN">
          <mat-cell *matCellDef="let un">

            <ul class="tree" style="padding-top: 10px;">
              <li [attr.id]="un.url" class="section unidade">
                <button [ngbTooltip]="un.name" style="outline: none" (click)="verCampos(un.name,un.url)" title="Ver campos desta Unidade" mat-icon-button
                class="botaoMenuArvore">
                  {{un.name}}
                </button>

                <input [attr.id]="'un_' + un.name" type="checkbox">
                <label (click)="AlteraEstadoLabel($event.target)" expanded="false" title="expandir" class="label_exp"
                  [attr.for]="'un_' + un.name"></label>

                <ul>
                  <li [attr.id]="nodeOil.url" class="section campo" *ngFor="let nodeOil of un.oilFields">
                    <button [ngbTooltip]="nodeOil.name" style="outline: none" (click)="verPocos(nodeOil.name,nodeOil.url, un.name,un.url)" title="Ver poços deste campo"
                      mat-icon-button class="botaoMenuArvore">
                      {{nodeOil.name}}
                    </button>

                    <input [attr.id]="'oil_' + nodeOil.name" type="checkbox">
                    <label mostraLabelCampos="false" (click)="AlteraEstadoLabel($event.target)" expanded="false" style="cursor: pointer;"
                      class="label_exp" [attr.id]="nodeOil.url" [attr.for]="'oil_' + nodeOil.name"></label>

                    <ul class="ulWell">
                      <li [attr.id]="nodeWell.url" style="white-space: nowrap;" class="section poco"
                        *ngFor="let nodeWell of nodeOil.wells">
                        &nbsp;
                        <button [ngbTooltip]="nodeWell.name" style="outline: none" class="botaoMenuArvore"
                          (click)="verIntervencoes(nodeWell.url, un.name, un.url, nodeOil.name, nodeOil.url,  nodeWell.name)"
                          mat-icon-button title="Ver intervenções">
                          {{nodeWell.name}}
                        </button>

                        <input [attr.id]="'well_' + nodeWell.name"  type="checkbox">
                        <label mostraLabel="false" (click)="AlteraEstadoLabel($event.target)" expanded="false" style="cursor: pointer;"
                          class="label_exp" [attr.id]="nodeWell.url" [attr.for]="'well_' + nodeWell.name"></label>

                        <ul style="margin-left: -2px;">
                          <li [attr.id]="nodeInter.url"
                            *ngFor="let nodeInter of nodeWell.interventions">
                              <a [ngbTooltip]="nodeInter.name" style=" word-break: keep-all; word-wrap: normal; outline: none; color: #4242dd;"
                              (click)="PainelIntervencao(nodeInter.url, nodeWell.url, un.name, un.url, nodeOil.name, nodeOil.url,  nodeWell.name)"
                                mat-icon-button title="Detalhes">
                                {{nodeInter.name}}
                              </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </mat-cell>
        </ng-container>

        <mat-row class="mat-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

    </div>
  </mat-sidenav>

  <mat-sidenav-content>

    <mat-toolbar class="header mat-elevation-z" *ngIf="loginData != null && verMenu">
      <button class="btn" style="cursor: pointer; color: white;" title="Retrair menu" mat-icon-button
        (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>


      </button>
      <label class="title-group" style="padding-top: 8px;">
        PWO
      </label>

      <span class="fill-remaining-space">
        <button class="btn btnOpcao" style="color: white;" title="opções" mat-icon-button [matMenuTriggerFor]="menu">
          <div style="float: right; outline: none">
            Bem vindo, {{loginData.nome}} &nbsp;&nbsp;&nbsp;
          </div>
          <mat-icon style="line-height: 22px;">account_circle</mat-icon>
        </button>
        <br>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
          <button (click)="forceLogout()" mat-menu-item style="outline: none">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sair</span>
          </button>
        </mat-menu>
      </span>

    </mat-toolbar>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
