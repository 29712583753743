<div class="content">
  <form class="p-3" ngNativeValidate>
    <div class="card">
      <div class="card-header textoNoCentro">
        CADASTRAR USUÁRIO
      </div>
      <div class="card-body">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>

        <div class="row">
          <div class="col-md-3">
            <div class="input-group mb-3">
              <input type="text" class="form-control" id="chave" autofocus name="Chave" [(ngModel)]="usuario.chave"
                placeholder="Digite a chave">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="buscaNomeUsuario()" type="submit">OK</button>
              </div>
            </div>
          </div>

          <div class="col-md-4" *ngIf="nomeUsuario != null">
            <input readonly name="nome" [(ngModel)]="nomeUsuario" type="text" class="form-control">
          </div>

        </div>
        <br>
        <div class="row">
          <div class="col-md-9">
            <label *ngIf="nomeUsuario != null" style="font-weight: bold;">Aplicações que tem acesso</label>
            <br>

            <div style="display: none;" id="divApp">
              <div class="fixarConteudoAplicaoes">
                <mat-selection-list [(ngModel)]="listaAplicacoesSelecionadas" name="AplicacaoSelecionada" #apl
                  class="table">
                  <mat-list-option (click)="GetIdAplicacaoSelecionada()" [value]="apli.idAplicacao"
                    style="display:inline-block; width:60%;" checkboxPosition="before" [color]="cor"
                    *ngFor="let apli of aplica">
                    {{apli.nomeAplicacao}}
                  </mat-list-option>
                </mat-selection-list>
                <p>
                  Item(ns) selecionado(s): {{apl.selectedOptions.selected.length}}
                </p>
              </div>

            </div>
          </div>
        </div>
        <br>

        <br>
        <button *ngIf="nomeUsuario != null" class="btn btn-default" style="border: 1px solid black;" type="submit"
          (click)="voltarPagina()">
          Cancelar</button>
        &nbsp;&nbsp;&nbsp;
        <button *ngIf="nomeUsuario != null" class="btn btn-success" (click)="inserirUsuario()" type="submit">
          Salvar
        </button>

      </div>
    </div>
  </form>
</div>
