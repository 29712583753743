import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SondaDetailComponent } from '../Components/sonda-detail/sonda-detail.component';
import { Sonda } from '../model/sondaModel';
import { SondaService } from '../services/sonda.service';

@Component({
	selector: 'app-sonda',
	templateUrl: './sonda.component.html',
	styleUrls: ['./sonda.component.css', '../app.component.css']
})
export class SondaComponent implements OnInit {
	carregado:boolean = true;
	datasource:MatTableDataSource<Sonda>;
	showColumns = ['nome', 'ativo', 'sincronizado', 'dataSincronizacao', 'acoes'];
	inicialSonda = {nome: "", ativo: true, sincronizado:false, dataSincronizacao: null}

	@ViewChild('paginacao') set paginator(pager:MatPaginator) {
		if (pager) this.datasource.paginator = pager;
	  }

	constructor(private sondaService:SondaService,
		public _paginacao:MatPaginatorIntl,
		public dialog: MatDialog) {

	}

	ngOnInit(): void {
		this.updateTable();

		this._paginacao.firstPageLabel = 'Início';
		this._paginacao.itemsPerPageLabel = 'Itens por página';
		this._paginacao.lastPageLabel = 'Última';
		this._paginacao.nextPageLabel = 'Próxima';
		this._paginacao.previousPageLabel = 'Anterior';
		this._paginacao.getRangeLabel = this.getRangeDisplayText;
	}

	updateTable = () => {
		this.sondaService.getSondas().then(response => {
			this.carregado = false;
			this.datasource = new MatTableDataSource<Sonda>(response.data);
			setTimeout(() => {
				this.datasource.paginator = this.paginator;
				this.carregado = true;
			}, 500);
		}).catch(error => {
			console.log("falha na busca dos dados", error.response.data)
		});
	}

	getRangeDisplayText = (page: number, pageSize: number, length: number) => {
		const initialText = `Mostrando`;

		if (length == 0 || pageSize == 0) {
		  return `${initialText} 0 de ${length}`;
		}

		length = Math.max(length, 0);
		const startIndex = page * pageSize;
		const endIndex = startIndex < length
		  ? Math.min(startIndex + pageSize, length)
		  : startIndex + pageSize;

		return `${initialText} ${startIndex + 1} - ${endIndex} de ${length}`;
	};

	filtrar = (value:string) => {
		this.datasource.filter = value.trim().toLocaleLowerCase()
	}

	cadastrar = () => {
		const dialogRef = this.dialog.open(SondaDetailComponent, {
			width:'600px',
			data: this.inicialSonda
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result.update){
				this.updateTable();
			}
		});
	}

	editar = (sonda) => {
		const dialogRef = this.dialog.open(SondaDetailComponent, {
			width:'600px',
			data: sonda
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result.update){
				this.updateTable();
			}
		});
	}
}
