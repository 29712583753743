<div class="content">
  <form class="p-3" ngNativeValidate>
    <div class="card">
      <div class="card-header textoNoCentro">
        ALTERAR APLICAÇÃO
      </div>
      <div class="card-body" *ngIf="app != null">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>

        <div class="row">
          <div class="col-md-6">
            <b><span>Campos com ( <span style="color: red;">*</span> ) são obrigatórios</span></b>
          </div>
        </div>
        <br>

        <div class="row">
          <div class="col-md-6">
            <label for="NomeAplicacao">Nome*</label>
            <input placeholder="Nome" [(ngModel)]="app.nomeAplicacao" id="NomeAplicacao" name="NomeAplicacao" type="text" required
              class="form-control">
          </div>
          <div class="col-md-6">
            <label>Nome Amigavel*</label>
            <input name="NomeAplicacaoAmigavel" type="text" required class="form-control"
              [(ngModel)]="app.nomeAplicacaoAmigavel" placeholder="Nome Amigavel">
          </div>
        </div>
        <br>

        <div class="row">

          <div class="col-md-6">
            <label>Ordem*</label>
            <input (change)="validaLimiteOrdem()" type="number" [(ngModel)]="app.ordem" name="Ordem" id="Ordem" class="form-control" >
          </div>

          <div class="col-md-6">
            <label>Tipo de Aplicação*</label>
            <select [(ngModel)]="app.tipoAplicacao" name="TipoAplicacao" class="form-control">
              <option>Web</option>
              <option>Desktop</option>
              <option>Mobile</option>
            </select>
          </div>

        </div>
        <br>

        <div class="row">

          <div class="col-md-6">
            <label>Client ID*</label>
            <input required type="text" name="ClientID" class="form-control" [(ngModel)]="app.clientID"
              placeholder="Client ID">
          </div>

          <div class="col-md-6">
            <label>Client Secret*</label>
            <input required type="text" name="ClientSecret" class="form-control" [(ngModel)]="app.clientSecret"
              placeholder="Client Secret">
          </div>

        </div>
        <br>

        <div class="row">
          <div class="col-md-6">
            <label *ngIf="app.icone != null && app.icone != '' ">Alterar Ícone</label>
            <label *ngIf="app.icone == null || app.icone === '' ">Adicionar Ícone</label>
            <input type="file" (change)="onFileSelect($event.target)" title="Selecionar novo ícone"
            accept=".svg" class="form-control">
          </div>

          <div class="col-md-6 imageIcone" *ngIf="this.myImage != undefined" [innerHtml]="myImage">
          </div>

        </div>
        <br>

        <div class="row">
          <div class="col-md-6">
            <label>Url de Acesso</label>
            <input type="text" name="UrlAcesso" class="form-control" [(ngModel)]="app.urlAcesso"
              placeholder="Url de Acesso">
          </div>
          <div class="col-md-6">
            <label>Url de Webhook</label>
            <input type="text" name="UrlWebHook" class="form-control" [(ngModel)]="app.urlWebHook"
              placeholder="Url de Webhook">
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-6">
            <label>Url de Redirecionamento</label>
            <textarea class="form-control" name="UrlRedirecionamento" [(ngModel)]="app.urlRedirecionamento"
              placeholder="Url de Redirecionamento"></textarea>
          </div>
          <div class="col-md-3">
            <label>Aplicação deve receber eventos de sincronização do PWO?</label>
            <select  [(ngModel)]="app.recebeEventos" name="RecebeEventos" class="form-control">
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
		  <div class="col-md-3">
            <label>Aplicação Integrada?</label>
			<select  [(ngModel)]="app.aplicacaoIntegrada" name="aplicacaoIntegrada" id="aplicacaoIntegrada" class="form-control">
				<option value="true">Sim</option>
				<option value="false">Não</option>
			  </select>
          </div>
        </div>
        <br>

        <br>
        <button class="btn btn-default" style="border: 1px solid black;" type="submit" (click)="cancel()">
          Cancelar</button>
        &nbsp;&nbsp;&nbsp;
        <button class="btn btn-success" (click)="update()" type="submit">
          Atualizar
        </button>
      </div>

      <div class="card-body" *ngIf="app == null">
        <span>Registro não encontrado ou já foi removido!</span>
      </div>
    </div>
  </form>
</div>
