import { Injectable } from '@angular/core';
import { http } from './config.service';

const apiPath = "api/public/attachment/";

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor() { }

  getAttachToDownload(id) {
    return http.get(apiPath + id, { responseType: 'arraybuffer'})
  }

  saveAttachment(file, name, description, parent,fase, atividade, operacao, data, tagsParaInserir) {
    const formData = new FormData();
    const params = '?name=' + name + '&description=' + description + '&urlParent=' + parent +
    '&fase=' + fase + '&atividade=' + atividade + '&operacao=' + operacao + '&data=' + data + '&tags=' + tagsParaInserir;

    formData.append('formFile', file);

    const config = { headers: {'Content-Type': 'multipart/form-data' } };

    return http.post(apiPath + params,  formData, config);
  }

  getTags(idIntervencao) {
    return http.get(apiPath + 'tags/' + idIntervencao)
  }

  getAtividades() {
    return http.get('api/public/atividade')
  }

  getOperacoes(idAtividade) {
    return http.get('api/public/operacao/' + idAtividade)
  }

  removeAttach(id) {
    return http.delete(apiPath + id)
  }
}
