import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginData } from 'src/app/model/login-data';
import { AplicacaoService } from 'src/app/services/aplicacao.service';

@Component({
  selector: 'app-atualizar-aplicacao',
  templateUrl: './atualizar-aplicacao.component.html',
  styleUrls: ['../../../app.component.css']
})
export class AtualizarAplicacaoComponent implements OnInit {
  title = 'PWO - Controle de Aplicações';
  loginData: LoginData;
  app = null
  carregado = false
  myImage: SafeHtml;
  icone: any;
  totalAplicacoes: number

  constructor(private snackBar: MatSnackBar,
    private aplicacaoService: AplicacaoService,
    private sanitizer: DomSanitizer,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    window.document.title = this.title;
    this.getAplicacao()
    this.getTotalAplicacoes() // recupera o total de aplicações para validar o limite da Ordem
  }

  cancel(): void {
    this.router.navigate(['/administracao/aplicacao/listar'])
  }

  async update() {
    this.carregado = false;

    const loginData = JSON.parse(localStorage.getItem('loginData'))
    this.app.UserId = loginData.chave
    this.app.ordem = Number(this.app.ordem)
    var recebeEvent = this.app.recebeEventos == "true" ? true : false
    this.app.recebeEventos = recebeEvent

	var appIntegrada = this.app.aplicacaoIntegrada ? true : false
    this.app.aplicacaoIntegrada = appIntegrada

    if (this.myImage != undefined) {
      if (this.icone !== undefined) {
        this.app.icone = this.icone
      }
      else if(this.myImage != undefined && this.icone == undefined){
        this.app.icone = this.app.icone
      }
    }
    if(this.icone !== undefined){
      this.app.icone = this.icone
    }

    await this.aplicacaoService.Atualizar(this.app).then(response => {
      if (response.data != null) {
        setTimeout(() => {
          this.carregado = true;
        }, 1000);
        this.snackBar.open("Ação concluida.", "OK", { duration: 5000, panelClass:['sucesso'] });
        this.router.navigate(['/administracao/aplicacao/listar'])
      }
      else {
        this.carregado = true;
        this.snackBar.open("Erro ao atualizar aplicação.", "OK", { duration: 5000, panelClass:['erro'] });
      }

    }).catch(error => {
      this.carregado = true;
      this.snackBar.open("Erro ao atualizar aplicação.", "OK", { duration: 3000, panelClass:['erro'] })
      console.error(error);
    })
  }

  async getAplicacao() {
    try {
      this.carregado = false;
      const id = this.route.snapshot.paramMap.get('id')
      const response = await this.aplicacaoService.GetAplicacao(id)
      if (response.data != null) {
        this.app = response.data
        if (this.app.icone != '') {
          this.myImage = this.sanitizer.bypassSecurityTrustHtml(this.app.icone);
        }
        this.carregado = true;
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  onFileSelect(input) {

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onloadend = (e: any) => {
        this.icone = e.target.result
      }
      reader.readAsText(input.files[0]);
    }
  }

  async getTotalAplicacoes() {
    try {
      const response = await this.aplicacaoService.GetAplicacoes();
      if (response.data != null) {
        this.totalAplicacoes = response.data.length;
        this.carregado = true;
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  validaLimiteOrdem(){
    if(this.app.ordem > this.totalAplicacoes){
      this.snackBar.open("A ordem limite é: " + this.totalAplicacoes, "OK", { duration: 5000, panelClass:['alerta'] });
      this.app.ordem = this.totalAplicacoes
      return
    }
  }

}
