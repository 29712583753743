import { Injectable } from '@angular/core';
import { http } from "./config.service"

@Injectable({
  providedIn: 'root'
})

export class AplicacaoService {

  GetAplicacoes() {
    return http.get('api/aplicacao/GetAplicacoes')
  }
  GetAplicacao(id) {
    return http.get('api/aplicacao/GetAplicacao/' + id)
  }

  Inserir(Aplicacao){
    return http.post('api/aplicacao/InserirAplicacao', Aplicacao)
  }

  Atualizar(Aplicacao){
    return http.put('api/aplicacao/AtualizarAplicacao', Aplicacao)
  }

  Remover(id){
    return http.delete('api/aplicacao/RemoverAplicacao/' + id)
  }

  ContabilizarAcesso(Aplicacao){
	return http.post('api/acessoaplicacao/registrarAcesso', Aplicacao)
  }
}
