import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientComponent } from './client/client.component';
import { AuthorizationGuard } from './helpers/authorization.guard';
import { LoginComponent } from './login/login.component'
import { CadAplicaComponent } from './Aplicacao/cad-aplica/cad-aplica.component'
import { ListarAplicacoesComponent } from './Aplicacao/listar-aplicacoes/listar-aplicacoes.component';
import { ListarUsuariosComponent } from './Usuario/listar/listar-usuarios/listar-usuarios.component';
import { CadastrarUsuarioComponent } from './Usuario/cadastrar/cadastrar-usuario/cadastrar-usuario.component';
import { AtualizarAplicacaoComponent } from './Aplicacao/atualizar/atualizar-aplicacao/atualizar-aplicacao.component';
import { AtualizarUsuarioComponent } from './Usuario/atualizar/atualizar-usuario/atualizar-usuario.component';
import { AcessoAplicacaoComponent } from './acesso-aplicacao/acesso-aplicacao.component';
import { ListarSchemasComponent } from './Schemas/Listar/listar-schemas/listar-schemas.component';
import { CadastrarSchemaComponent } from './Schemas/Cadastrar/cadastrar-schema/cadastrar-schema.component';
import { AtualizarSchemaComponent } from './Schemas/Atualizar/atualizar-schema/atualizar-schema.component';
import { DocComponent } from './doc/doc.component';
import { IndexComponent } from './Intervencao/index/index.component';
import { NavIntervencoesComponent } from './Components/template/nav-intervencoes/nav-intervencoes.component';
import { MenuComponent } from './MQTT/menu/menu.component';
import { HomeComponent } from './home/home.component';
import { ArvoreDetailComponent } from './Intervencao/arvore-detail/arvore-detail.component';
import { InterventionPanelComponent } from './intervention-panel/intervention-panel.component'
import { CreateClientComponent } from './MQTT/create-client/create-client.component';
import { ListClientsComponent } from './MQTT/list-clients/list-clients.component';
import { UpdateClientComponent } from './MQTT/update-client/update-client.component';
import { ListComponent } from './kpi/list/list.component';
import { OffshoreComponent } from './unidadeOperacional/offshore/offshore.component';
import { AcessoUsuariosComponent } from './graficos/acesso-usuarios/acesso-usuarios.component';
import { SondaComponent } from './sonda/sonda.component';

const routes: Routes = [
  { path: '', redirectTo: '/homePage', pathMatch: 'full' },
  { path: 'AcessoAplicacao', component: AcessoAplicacaoComponent },
  { path: 'Client', component: ClientComponent },
  { path: 'loginPage', component: LoginComponent },
  { path: 'docs', component: DocComponent },
  { path: 'homePage', component: HomeComponent },
  { path: 'painel/:id', component: InterventionPanelComponent },

  { path: 'administracao/aplicacao/cadastrar', component: CadAplicaComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },
  { path: 'administracao/aplicacao/listar', component: ListarAplicacoesComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },
  { path: 'administracao/aplicacao/atualizar/:id', component: AtualizarAplicacaoComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },

  { path: 'administracao/usuario/listar', component: ListarUsuariosComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },
  { path: 'administracao/usuario/cadastrar', component: CadastrarUsuarioComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },
  { path: 'administracao/usuario/atualizar/:id', component: AtualizarUsuarioComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },

  { path: 'administracao/schemas/listar', component: ListarSchemasComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },
  { path: 'administracao/schemas/cadastrar', component: CadastrarSchemaComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },
  { path: 'administracao/schemas/atualizar/:id', component: AtualizarSchemaComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },

  { path: 'administracao/mqtt/create-client', component: CreateClientComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },
  { path: 'administracao/mqtt/list-clients', component: ListClientsComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },
  { path: 'administracao/mqtt/update-client/:id', component: UpdateClientComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },
  { path: 'mqtt', component: MenuComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador', 'PWO_INTERVENCOES'] } },
  { path: 'administracao/kpi', component: ListComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador', 'PWO_INTERVENCOES'] } },

  {
    path: '',
    component: NavIntervencoesComponent,
    canActivate: [AuthorizationGuard],
    data: { roles: ['Administrador', 'PWO_INTERVENCOES'] },
    children: [
      { path: 'intervencao/index', component: IndexComponent },
      { path: 'intervencao/tree-detail', component: ArvoreDetailComponent }
    ]
  },

  { path: 'painel-acessos', component: AcessoUsuariosComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },

  { path: 'administracao/unidadesOffShore', component: OffshoreComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador'] } },
  { path: 'administracao/sonda', component: SondaComponent, canActivate: [AuthorizationGuard], data: { roles: ['Administrador']}},
  // {
  //   path: 'mqtt',
  //   component: MenuComponent,
  //   canActivate: [AuthorizationGuard],
  //   data: { roles: ['Administrador'] },
  //   children: [

  //     { path: 'create-client', component: CreateClientComponent },
  //     { path: 'list-clients', component: ListClientsComponent },
  //     { path: 'update-client/:id', component: UpdateClientComponent }
  //   ]
  // },
  { path: '**', redirectTo: 'homePage' }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
