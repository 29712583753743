import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { intervencao } from 'src/app/model/intervencao';
import { SondaService } from 'src/app/services/sonda.service';
import { UnidadeIntervencaoService } from 'src/app/services/unidadeIntervencao.service';
declare var $: any

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['../../app.component.css', './create.component.css'],
})

export class CreateComponent implements OnInit {
  today: string;
  modalReference: NgbModalRef
  intervencao: intervencao = {
    name: '',
    sonda: null,
    dataInicio: null,
    tipoIntervencao: '',
    well: {},
  }
  qtdTipoIntervencao = 0
  carregado = false
  wells: any
  sondas: any
  idIntervencao: string;
  modo: string;

  dataInicioUpdate: any
  horaInicioUpdate: any

  constructor(
    public activeModal: NgbActiveModal,
    private unidadeIntervencaoService: UnidadeIntervencaoService,
	private sondaService: SondaService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.idIntervencao = localStorage.getItem('intervencaoSelecionada');
    this.modo = localStorage.getItem('modoIntervencao');

    if (this.idIntervencao != null && this.modo === 'update') {
      this.getIntervention();
    } else {
      this.intervencao.dataInicio = new Date().toISOString().split('T')[0];
    }

    this.getSondas()

    var nomeCampo = JSON.parse(localStorage.getItem('CampoSelecionado'))
    var nomeUnidade = JSON.parse(localStorage.getItem('UnidadeSelecionada'))
    var nomePoco = JSON.parse(localStorage.getItem('PocoSelecionado'))

    if (nomeUnidade != null && nomeCampo != null && nomePoco != null) {
      this.getPocos(nomePoco)
      return
    }
    else if (nomeUnidade != null && nomeCampo != null) {
      this.getPocosPorCampo(nomeCampo)
      return
    }
    else if (nomeUnidade != null) {
      this.getPocosPorUnidade(nomeUnidade)
      return
    }

    this.getPocos(nomePoco)
  }

  getIntervention() {
    this.unidadeIntervencaoService.GetIntervention(this.idIntervencao).then(response => {
      if (response.data != null) {
        this.intervencao = response.data;
        let arrData = this.intervencao.dataInicio.split('/');

        this.intervencao.dataInicio = arrData[2].substring(0,4) + "-" + arrData[1] + "-" + arrData[0];
        this.dataInicioUpdate = this.intervencao.dataInicio
        this.horaInicioUpdate = arrData[2].split(' ')[1]
      }
    });
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
    if (this.idIntervencao != null) {
      localStorage.removeItem('intervencaoSelecionada');
      localStorage.removeItem('modoIntervencao');
    }
  }

  async getSondas() {
    try {
      const response = await this.unidadeIntervencaoService.GetSondas();
      if (response.data != null) {
        this.sondas = response.data
        setTimeout(() => {
          this.carregado = true
        }, 100);
      }
      else {
        this.carregado = true
        this.sondas = null;
      }
    } catch (error) {
      this.carregado = true
      console.error(error);
    }
  }

  async getPocos(name) {
    try {
      const response = await this.unidadeIntervencaoService.GetPocos(name);
      if (response.data != null) {
        this.wells = response.data
        if (this.wells.length == 1) {
          this.intervencao.well.url = this.wells[0].url
        }
        setTimeout(() => {
          this.carregado = true
        }, 100)
      }
      else {
        this.carregado = true
        this.wells = null;
      }
    } catch (error) {
      this.carregado = true
      console.error(error);
    }
  }

  async getPocosPorCampo(name) {
    try {
      const response = await this.unidadeIntervencaoService.GetPocosPorCampo(name);
      if (response.data != null) {
        var pocos;
        for (let index = 0; index < response.data.length; index++) {
          pocos = response.data[index].wells;
        }
        this.wells = pocos;
        setTimeout(() => {
          this.carregado = true
        }, 100)
      }
      else {
        this.carregado = true
        this.wells = null;
      }
    } catch (error) {
      this.carregado = true
      console.error(error);
    }
  }

  async getPocosPorUnidade(name) {
    try {
      const response = await this.unidadeIntervencaoService.GetPocosPorUnidade(name);
      if (response.data != null) {
        this.wells = response.data;
        setTimeout(() => {
          this.carregado = true
        }, 100)
      }
      else {
        this.carregado = true
        this.wells = null;
      }
    } catch (error) {
      this.carregado = true
      console.error(error);
    }
  }

  limparSelecaoSonda() {
    this.intervencao.sonda = ''
  }

  limparSelecaoPoco() {
    this.intervencao.well.url = null
  }

  limparSelecaoData() {

    var id = $("#inputData").val()
    if (id == '') {
      this.intervencao.dataInicio = null
    }
  }

  desabilitaCampos() {
    if (this.intervencao.name === '' || this.intervencao.sonda === '' ||
      this.intervencao.sonda === null ||
      this.intervencao.dataInicio === null || this.intervencao.dataInicio === undefined || this.intervencao.tipoIntervencao === '') {
      return false
    }
    return true
  }

  addZero(i) {
    if (i < 10) {i = "0" + i}
    return i;
  }

  async PostIntervencao() {
    if (this.idIntervencao != null && this.modo === 'update') {
      this.PutIntervencao();
      return;
    }

    var today = new Date()
    var date = this.addZero(today.getHours()) + ':' + (this.addZero(today.getMinutes())) + ':' + this.addZero(today.getSeconds()) + '.' + today.getMilliseconds()
    this.intervencao.dataInicio = this.intervencao.dataInicio + 'T' + date + '+00:00'

    this.carregado = false;
    this.intervencao.well.url = this.wells[0].url

    await this.unidadeIntervencaoService.PostIntervencao(this.intervencao).then(response => {
      if (response.data != null) {
        setTimeout(() => {
          this.carregado = true
        }, 100)

        this.closeModal('dismiss')
        this.snackBar.open("Intervenção cadastrada com sucesso.", "OK", { duration: 4000, panelClass: ['sucesso'] });
      }
      else {
        this.carregado = true
        this.closeModal('dismiss')
        this.snackBar.open("Erro ao cadastrar. Entre em contato com o administrador!", "OK", { duration: 4000, panelClass: ['erro'] })
      }

    }).catch(error => {
      this.carregado = true
      this.closeModal('dismiss')
      this.snackBar.open("Erro ao cadastrar. Entre em contato com o administrador! " + error, "OK", { duration: 4000, panelClass: ['erro'] })
    })
  }

  PreencheCampoNome(tipo) {
    if (tipo != "") {
      this.GetQuantidadeIntervencoesPorTipo(tipo)
    }
    else {
      this.intervencao.name = ""
    }
  }

  async GetQuantidadeIntervencoesPorTipo(tipoIntervencao) {
    try {
      const response = await this.unidadeIntervencaoService.GetQuantitativoIntervencoesPorTiipo(tipoIntervencao);
      this.qtdTipoIntervencao = response.data
      this.intervencao.name = this.intervencao.tipoIntervencao + " " + this.qtdTipoIntervencao
    }
    catch (error) {
      console.error(error);
    }
  }


  async PutIntervencao() {
    this.carregado = false;
    this.intervencao.well.url = this.wells[0].url

    if (this.dataInicioUpdate != this.intervencao.dataInicio) {
      var today = new Date()
      var date = this.addZero(today.getHours()) + ':' + (this.addZero(today.getMinutes())) + ':' + this.addZero(today.getSeconds()) + '.' + today.getMilliseconds()
      this.intervencao.dataInicio = this.intervencao.dataInicio + 'T' + date + '+00:00'
    }
    else{
      this.intervencao.dataInicio = this.intervencao.dataInicio + 'T' + this.horaInicioUpdate + '+00:00'
    }

    await this.unidadeIntervencaoService.PutIntervencao(this.idIntervencao, this.intervencao).then(response => {
      if (response.data != null) {
        setTimeout(() => {
          this.carregado = true
        }, 100)

        this.closeModal('dismiss')
        this.snackBar.open("Intervenção atualizada com sucesso.", "OK", { duration: 4000, panelClass: ['sucesso'] });
      }
      else {
        this.carregado = true
        this.closeModal('dismiss')
        this.snackBar.open("Erro ao atualizar. Entre em contato com o administrador!", "OK", { duration: 4000, panelClass: ['erro'] })
      }

    }).catch(error => {
      this.carregado = true
      this.closeModal('dismiss')
      this.snackBar.open("Erro ao atualizar. Entre em contato com o administrador! " + error, "OK", { duration: 4000, panelClass: ['erro'] })
    })
  }
}
