import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {

  private allowedRoles:string[];

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar)  { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      this.allowedRoles=route.data['roles'];

      var authorized:boolean =false;

      if (this.authService.isLoggedIn()) {
        console.debug("Verificando perfis");
        var perfis = this.authService.loginData.perfis;
        for (const perfil of perfis) {
          if (this.allowedRoles.includes(perfil.descricao)) {
            authorized=true;
            break;
          }
        }
      }

      if (authorized) {
        return true;
      } else {
        if (this.authService.isLoggedIn()) {
          this.snackBar.open("Usuário não autorizado.","FECHAR",{duration: 5000, panelClass:['erro']});
          this.router.navigate(['/homePage']);
          return false;

        } else {
          console.debug("Não autenticado");
          this.snackBar.open("Usuário não autenticado.","FECHAR",{duration: 5000, panelClass:['erro']});

          this.router.navigate(['/loginPage'], { queryParams: { ReturnUrl: state.url } });
          return false;
        }
      }
  }
}
