<div class="content">
  <form class="p-3">
    <div class="card">
      <div class="card-header textoNoCentro">
        LISTA DE KPIs
      </div>
      <div class="card-body">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>
        <button class="btn btn-success" (click)="open(mymodal, null)">NOVO</button>
        <br><br><br>



        <div class="row">
          <div class="col-md-6">
            <div class="form-group has-search">
              <span class="form-control-feedback">
                <i class="material-icons">
                  search
                </i>
              </span>
              <input type="text" class="form-control" (keyup)="filtrar($event.target.value)" placeholder="Busca rápida">
            </div>
          </div>
        </div>
        <ngx-skeleton-loader *ngIf="!carregado" appearance="pulse">
        </ngx-skeleton-loader>
        <div class="mat-elevation-z3" *ngIf="carregado">
          <div class="fixarConteudo">
            <mat-table class="table" #table [dataSource]="dataSource">

              <!-- nome -->
              <ng-container matColumnDef="valor">
                <mat-header-cell *matHeaderCellDef> Valor </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.valor}} </mat-cell>
              </ng-container>


              <!-- data de cadastro -->
              <ng-container matColumnDef="dtCadastro">
                <mat-header-cell *matHeaderCellDef> Válido a partir de </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.dataCadastro | date: 'dd/MM/yyyy HH:mm'}} </mat-cell>
              </ng-container>

              <!-- Ações -->
              <ng-container matColumnDef="acoes">
                <mat-header-cell lass="right" *matHeaderCellDef> </mat-header-cell>
                <mat-cell lass="right" *matCellDef="let element">
                  <a title="Clique para editar" style="cursor: pointer;" class="edit" (click)="open(mymodal, element)">
                    <i class="material-icons edit">edit</i>
                  </a>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row class="mat-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="width: 100%; text-align: center;" *ngIf="kpi.IdKpi != null && kpi.IdKpi != ''">Editar KPI</h4>
      <h4 class="modal-title" id="modal-basic-title" style="width: 100%; text-align: center;" *ngIf="kpi.IdKpi == null || kpi.IdKpi == ''">Cadastrar KPI</h4>
      <button style="outline: none" type="button" class="close" aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body corFundoConteudoModal">
      <div class="row">
        <div class="col-md-4 col-lg-4">
          <label>Valor</label>
          <input type="text" placeholder="Ex: 1.5, 0.3, 2.78" autocomplete="off" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'
           name="valor" class="form-control" [(ngModel)]="kpi.Valor">
        </div>
		&nbsp;
		  <div class="col-md-4 col-lg-4" *ngIf="kpi.IdKpi == null || kpi.IdKpi == ''">
			<label >Valido a partir de</label>
			<input type="date" autocomplete="off" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'
				name="dataValido" class="form-control" [(ngModel)]="kpi.DataCadastro">
		</div>
		&nbsp;
      </div>
    </div>
    <div class="modal-footer" style="justify-content: left; border-top: none;">
      <button type="button" *ngIf="kpi.IdKpi != null && kpi.IdKpi != ''" [disabled]="kpi.Valor == null" class="btn btn-success"
        (click)="atualizar()">Atualizar</button>

      <button type="button" *ngIf="kpi.IdKpi == null || kpi.IdKpi == ''" [disabled]="kpi.Valor == null " class="btn btn-success"
        (click)="inserir()">Cadastrar</button>

    </div>
  </ng-template>
