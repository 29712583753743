<div class="content">
  <form class="p-2">
    <div class="card">

      <div class="card-body" *ngIf="unidades != null">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>

        <ngx-skeleton-loader [count]="unidades.length" *ngIf="!carregado" appearance="circle">
        </ngx-skeleton-loader>
        <div *ngIf="carregado">

          <div class="fixarConteudoArvore">
            <div class="row">

              <div class="col-sm-1 col-md-1 col-lg-1" *ngFor="let unidade of unidades">
                <div (click)="CarregarCampos(unidade.name,unidade.url)" title="Ver poços" style="cursor: pointer;">
                  <div style="width: 100%; text-align: center;">
                    <mat-icon style="color: rgb(36, 98, 168); font-size: 50px; ">
                      <span class="material-icons-outlined">
                        folder_open
                      </span>
                    </mat-icon>
                  </div>
                  <div style="width: 100%; text-align: center;">
                    <a class="fontePetrobras itemEstrutura" mat-mat-icon-button>
                      {{unidade.name}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
