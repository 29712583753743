import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { MqttSelectedApps } from 'src/app/model/mqttClient';
import { MqttService } from 'src/app/services/mqtt.service';

@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrls: ['./list-clients.component.css', '../../app.component.css']
})
export class ListClientsComponent implements OnInit {

  displayedColumns = ['nomeSonda', 'aplicacao', 'topic', 'acoes'];
  dataSource: MatTableDataSource<MqttSelectedApps>;
  apps: MqttSelectedApps[];
  carregado = false;
  constructor
    (
      private router: Router,
      private mqttService: MqttService
    ) {
  }

  async getAllSuits() {
    try {
      const response = await this.mqttService.GetAllApss();
      if (response.data != null) {
        this.apps = response.data[0];
        this.dataSource = new MatTableDataSource<MqttSelectedApps>(this.apps);
        setTimeout(() => {
          this.carregado = true;
        }, 1000);
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  filtrar(filtro: string) {
    this.dataSource.filter = filtro.trim().toLocaleLowerCase()
  }

  ngOnInit(): void {
    this.getAllSuits();
  }
}
