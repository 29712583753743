import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MqttHistoricoClient, MqttSelectedApps } from 'src/app/model/mqttClient';
import { MqttService } from 'src/app/services/mqtt.service';
import { LoginData } from '../../model/login-data';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  title = 'PWO - Painel MQTT';
  url: string
  carregado = false;

  displayedColumnsHistoricoMensagens = ['topic', 'messageType', 'typeOrName', 'fileExtension', 'createdAt'];
  dataSourceHistoricoMensagens: MatTableDataSource<MqttHistoricoClient>;

  displayedColumnsHistoricoConexoes = ['situacao', 'data'];
  dataSourceHistoricoConexoes: MatTableDataSource<MqttSelectedApps>;

  listaSondas: any
  listaSituacao: any

  app: MqttSelectedApps = {
    nomeSonda: null,
    situacao: null
  }

  dadosBasicos: MqttSelectedApps = {
    nomeSonda: '',
    nomeAplicacao: '',
    situacao: ''
  }
  apps: any;
  resultFiltro: any = [];
  closeResult: string;
  modalReference: NgbModalRef;

  constructor(
    private mqttService: MqttService,
    private modalService: NgbModal) {

    setTimeout(() => {
      window.location.reload();
    }, 180000); // atualiza a página após 3 minutos
  }


  ngOnInit(): void {
    window.document.title = this.title;

    this.listaSituacao = [
      { situacao: 'Conectado' },
      { situacao: 'Desconectado' },
      { situacao: 'Inativo' },
    ];

    this.getDashBoard()
  }

  async getDashBoard() {
    try {
      await this.mqttService.GetDashBoard().then(response => {
        if (response.data != null) {
          this.apps = response.data[0];
          this.listaSondas = Array.from(this.apps.reduce((m, t) => m.set(t.nomeSonda, t), new Map()).values()); // retorna uma lista sem duplicaidade de sondas
          setTimeout(() => {
            this.carregado = true;
          }, 1000);
        }
        else {
          this.carregado = true;
        }
      })
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  async open(content, element) {
    this.dadosBasicos.nomeSonda = element.nomeSonda
    this.dadosBasicos.nomeAplicacao = element.nomeAplicacao
    this.dadosBasicos.situacao = element.situacao

    try {
      this.mqttService.GetApplicationHistory(element.sondaId, element.aplicacaoId).then(response => {
        this.dataSourceHistoricoMensagens = new MatTableDataSource<MqttHistoricoClient>(response.data);
      });
      await this.mqttService.GetApplicationConnectionHistory(element.sondaId, element.aplicacaoId).then(res => {
        this.dataSourceHistoricoConexoes = new MatTableDataSource<MqttSelectedApps>(res.data[0]);
      });
    } catch (error) {
      console.error(error);
    }

    this.modalReference = this.modalService.open(content, { size: 'lg', backdrop: 'static', centered: true, backdropClass: 'customBackdrop', windowClass: 'tamanhoModal' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async limparSelecaoSonda() {
    this.app.nomeSonda = null
    await this.FiltrarPorSituacao(undefined, undefined)
  }

  async limparSelecaoSituacao() {
    this.app.situacao = null
    await this.FiltrarPorSituacao(undefined, undefined)
  }

  async populaObjetoFiltro(objeto: MqttSelectedApps) {

    const app = new MqttSelectedApps()

    app.sondaId = objeto.sondaId
    app.nomeSonda = objeto.nomeSonda
    app.aplicacaoId = objeto.aplicacaoId
    app.nomeAplicacao = objeto.nomeAplicacao
    app.topicoMQTT = objeto.topicoMQTT
    app.status = objeto.status
    app.situacao = objeto.situacao

    await this.resultFiltro.push(app)
  }

  async FiltrarPorSituacao(situacao, sonda) {

    try {
      this.carregado = false;

      await this.getDashBoard()

      if (situacao != undefined || sonda != undefined) {
        this.resultFiltro = []

        for (let index = 0; index < this.apps.length; index++) {

          if (situacao != undefined && sonda != null) {
            if (this.apps[index].situacao == situacao && this.apps[index].nomeSonda == sonda) {
              this.populaObjetoFiltro(this.apps[index])
            }
          }
          else if (situacao != undefined && sonda == null) {
            if (this.apps[index].situacao == situacao) {
              this.populaObjetoFiltro(this.apps[index])
            }
          }
          else if (sonda != null && situacao == undefined) {
            if (this.apps[index].nomeSonda == sonda) {
              this.populaObjetoFiltro(this.apps[index])
            }
          }

        }
        setTimeout(() => {
          this.carregado = true;
          this.apps = this.resultFiltro
        }, 300);
      }
    } catch (error) {
      console.log(error)
      this.carregado = true;
    }
  }

}
