import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemaService } from 'src/app/services/schema.service';
import { TipoArquivoService } from 'src/app/services/tipoArquivo.service';

@Component({
  selector: 'app-atualizar-schema',
  templateUrl: './atualizar-schema.component.html',
  styleUrls: ['../../../app.component.css']
})
export class AtualizarSchemaComponent implements OnInit {

  title = 'PWO - Controle de Schemas';
  schema = null
  carregado = false
  arquivos: any[];
  constructor(private snackBar: MatSnackBar,
    private tipoArquivoService: TipoArquivoService,
    private schemaService: SchemaService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    window.document.title = this.title;
    this.getSchema()
    this.getTipoArquivos()
  }

  async getSchema() {
    try {
      this.carregado = false;
      const id = this.route.snapshot.paramMap.get('id')
      const response = await this.schemaService.GetSchema(id)
      if (response.status == 200) {
        this.schema = response.data
        console.log(this.schema)
        this.carregado = true;
      }
      else {
        this.schema = null
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  async getTipoArquivos() {
    try {
      const response = await this.tipoArquivoService.GetTiposArquivos();
      if (response.data != null) {
        this.arquivos = response.data;
        this.carregado = true;
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async AtualizarSchema() {
    this.carregado = false;

    const loginData = JSON.parse(localStorage.getItem('loginData'))
    this.schema.chaveUsuarioUpdate = loginData.chave

    await this.schemaService.AtualizarSchema(this.schema).then(response => {
      if (response.data != null) {
        setTimeout(() => {
          this.carregado = true;
        }, 500);
        this.snackBar.open("Schema atualizado com sucesso.", "OK", { duration: 5000, panelClass: ['sucesso'] });
        this.router.navigate(['/administracao/schemas/listar'])
      }
      else {
        this.carregado = true;
        this.snackBar.open("Erro ao atualizar schema.", "OK", { duration: 5000, panelClass: ['erro'] });
      }

    }).catch(error => {
      this.carregado = true;
      this.snackBar.open("Erro ao atualizar schema.", "OK", { duration: 3000, panelClass: ['erro'] })
      console.error(error);
    })
  }

  voltarPagina() {
    this.router.navigate(['/administracao/schemas/listar'])
  }

  desabilitaCampos() {
    if (this.schema.idTipoArquivo === '' || this.schema.versao === '' || this.schema.documentacao === '' || this.schema.schemaJson === '') {
      return false
    }
    return true
  }

  limparTipo() {
    this.schema.idTipoArquivo = ''
  }

}
