import { DecimalPipe } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Kpi } from 'src/app/model/kpi';
import { KpiService } from 'src/app/services/kpi.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['../../app.component.css', './list.component.css']
})
export class ListComponent implements OnInit {

  title = 'PWO - Controle de KPI';

  kpi: Kpi = {
    Valor: null,
    IdKpi: '',
	DataCadastro: null ,
  };

  displayedColumns = ['valor', 'dtCadastro', 'acoes'];

  dataSource: MatTableDataSource<Kpi> | null;
  listaKpi: Kpi[];
  carregado = false;
  modalOptions: NgbModalOptions;
  closeResult: string;
  modalReference: NgbModalRef;

  constructor(
    private kpiService: KpiService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    window.document.title = this.title
    this.getKPI()
	this.kpi.DataCadastro = new Date();
  }

  async getKPI() {
    try {
      const response = await this.kpiService.GetAll();

      if (response.data != null) {

        this.listaKpi = response.data
        this.dataSource = new MatTableDataSource<Kpi>(this.listaKpi);
        setTimeout(() => {
          this.carregado = true;
        }, 100);
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  async atualizar() {

    this.carregado = false;

    if (this.kpi.Valor == null || this.kpi.Valor == 0) {
      this.snackBar.open("Valor não pode ser ZERO.", "OK", { duration: 5000, panelClass: ['alerta'] });
      this.carregado = true;
      this.limpaCampo()
      this.modalReference.close();
      this.getKPI()
      return;
    }
    this.kpi.Valor = Number(this.kpi.Valor);

    await this.kpiService.Update(this.kpi).then(response => {
      if (response.status == 200) {
        this.carregado = true;
        this.limpaCampo()
        this.modalReference.close();
        this.snackBar.open("KPI atualizado com sucesso.", "OK", { duration: 5000, panelClass: ['sucesso'] });
        this.getKPI()
      }
      else {
        this.modalReference.close();
        this.carregado = true;
        this.snackBar.open("Erro ao atualizar.", "OK", { duration: 5000, panelClass: ['erro'] });
      }

    }).catch(error => {
      this.modalReference.close();
      this.carregado = true;
      this.snackBar.open("Erro ao atualizar.", "OK", { duration: 3000, panelClass: ['erro'] })
      console.log(error);
    })
  }

  async inserir() {

    this.carregado = false;

    this.kpi.Valor = Number(this.kpi.Valor);
	this.kpi.DataCadastro = this.kpi.DataCadastro;

    await this.kpiService.Post(this.kpi).then(response => {
      if (response.status == 200) {
        this.carregado = true;
        this.limpaCampo();
        this.modalReference.close();
        this.snackBar.open("KPI inserido com sucesso.", "OK", { duration: 5000, panelClass: ['sucesso'] });
        this.getKPI();
      }
      else {
        this.modalReference.close();
        this.carregado = true;
        this.snackBar.open("Erro ao inserir.", "OK", { duration: 5000, panelClass: ['erro'] });
      }

    }).catch(error => {
      this.modalReference.close();
      this.carregado = true;
      this.snackBar.open("Erro ao inserir.", "OK", { duration: 3000, panelClass: ['erro'] })
      console.log(error);
    })
  }


  filtrar(filtro: string) {
    this.dataSource.filter = filtro.trim()
  }

  open(content, element) {

    console.log(element)
	console.log(this.kpi)
    if (element != '' && element != null) {
      this.kpi.Valor = element.valor
      this.kpi.IdKpi = element.idKpi
	  this.kpi.DataCadastro = element.dataValido
    }
    this.modalReference = this.modalService.open(content, { size: 'md', backdrop: 'static', centered: true, backdropClass: 'customBackdrop' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      this.limpaCampo()
      return `with: ${reason}`;
    }
  }

  limpaCampo() {
    this.kpi.Valor = null
	this.kpi.IdKpi = null
	this.kpi.DataCadastro = null
  }
}
