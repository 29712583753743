<div class="content">
  <form class="p-3" ngNativeValidate>
    <div class="card">
      <div class="card-header textoNoCentro">
        ATUALIZAR CLIENT
      </div>
      <div class="card-body" *ngIf="listaAplicacoes != null">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>


        <div class="row">

          <div class="col-md-3">
            <label for="nomeSonda">Sonda</label>
            <input type="text" class="form-control" name="nomeSonda" id="nomeSonda"
              [(ngModel)]="listaAplicacoes[0].nomeSonda" readonly>
          </div>

        </div>
        <br>
        <br>

        <div class="row">
          <div class="col-md-9">
            <label style="font-weight: bold;">Aplicações</label>
            <br><br>
            <div class="fixarConteudoAplicaoes">
              <mat-selection-list #selected [(ngModel)]="listaAplicacoesSelecionadas" name="AplicacaoSelecionada"
                class="table">


                <mat-list-option [selected]="apli.status" [value]="apli" checkboxPosition="before" [color]="cor"
                  *ngFor="let apli of aplica">

                  <div class="col-md-12">
                    {{apli.nomeAplicacao}}
                    <a [ngbTooltip]="'Remover ' + apli.nomeAplicacao" class="delete"
                      style="cursor: pointer; float: right; z-index: 100;" (click)="remover(apli.sondaId, apli.aplicacaoId)">
                      <i class="material-icons">delete_forever</i>
                    </a>
                  </div>
                </mat-list-option>

              </mat-selection-list>
              <p *ngIf="aplica != undefined">
                Item(ns) selecionado(s): {{selected.selectedOptions.selected.length}}
              </p>
            </div>
          </div>


        </div>
        <br>

        <br>
        <button class="btn btn-default" style="border: 1px solid black;" type="submit" (click)="voltarPagina()">
          Cancelar</button>
        &nbsp;&nbsp;&nbsp;
        <button [disabled]="aplica.length == 0" class="btn btn-success" (click)="update()" type="submit">
          Atualizar
        </button>
      </div>
    </div>
  </form>
</div>
