<!-- Modal Update TipoArquivo -->
<div class="content">
  <form class="p-3">
    <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
      [show]="!carregado">
    </ngx-loading>
    <div class="modal-header alinhaTituloEBotaoModal">
      <h3 class="modal-title fontePetrobras" id="modal-basic-title">Criar Intervenção</h3>
      <button style="outline: none" type="button" class="close" aria-label="Close" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body corFundoConteudoModal">

      <div class="row">
        <div class="col-md-6">
          <mat-label>Data de registro da Intervenção*</mat-label>
          <input id="inputData" [(ngModel)]="intervencao.dataInicio" (ngModelChange)="limparSelecaoData()"
            class="form-control" type="date" name="dataInicio" />
        </div>
        <div class="col-md-6">
          <mat-label>Evento*</mat-label>
          <select (change)="PreencheCampoNome(intervencao.tipoIntervencao)" [(ngModel)]="intervencao.tipoIntervencao" name="tipoIntervencao" class="form-control">
            <option value="">Selecione</option>
            <option value="Perfuração">Perfuração</option>
            <option value="Completação">Completação</option>
            <option value="Avaliação">Avaliação</option>
            <option value="Workover">Workover</option>
          </select>
        </div>
      </div>
      <br>
      <div class="row">

        <div class="col-md-6" *ngIf="wells != undefined && wells.length > 1">
          <mat-label>Poço*</mat-label>
          <ng-select notFoundText="Nenhum registro encontrado!" (clear)="limparSelecaoPoco()" style="min-width: 250px;"
            [items]="wells" bindLabel="name" bindValue="url" placeholder="Selecionar" appendTo="body"
            [(ngModel)]="intervencao.well.url" [ngModelOptions]="{standalone: true}" name="url">
          </ng-select>
        </div>

        <div class="col-md-6" *ngIf="wells != undefined && wells.length == 1">
          <mat-label>Poço*</mat-label>
          <input type="text" class="form-control" name="wellname" [(ngModel)]="wells[0].name" readonly>
        </div>

        <div class="col-md-6">
          <mat-label>Sonda*</mat-label>
          <ng-select notFoundText="Nenhum registro encontrado!" (clear)="limparSelecaoSonda()" style="min-width: 250px;"
            [items]="sondas" bindLabel="unin_Sg_Unid_Int" bindValue="unin_Sg_Unid_Int" placeholder="Selecionar"
            appendTo="body" name="unin_Sg_Unid_Int" [(ngModel)]="intervencao.sonda">
          </ng-select>
        </div>

      </div>
      <br>
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <label>Nome da intervenção*</label>
          <input id="intervencaoName" autocomplete="off" type="text" [(ngModel)]="intervencao.name"
            style="text-transform: uppercase;" name="name" class="form-control">
        </div>
      </div>

    </div>
    <div class="modal-footer alinhaTituloEBotaoModal" style="float: left; border-top: none;">
      <div>
        <button type="submit" (click)="PostIntervencao()" [disabled]="!desabilitaCampos()"
          class="btn btn-primary">Salvar</button>
      </div>
    </div>
  </form>
</div>
<!-- FIM Modal Update TipoArquivo -->
