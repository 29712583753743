<div class="content">
	<ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
		[show]="!carregado">
	</ngx-loading>
	<div class="row">
		<a (click)="voltaParaListaDeAplicacoes()">
			<img style="padding-left: 28px;top: 9px; position: absolute; cursor: pointer;" height="50px" src="assets/Principal_h_cor_RGB.jpg">
		  </a>
	</div>
	<br><br><br>
	<form class="p-3">

		<!-- <div class="row">
			<div class="col-md-6">
				<label><b>Filtrar por período</b></label> &nbsp;&nbsp;&nbsp;
				<mat-form-field appearance="outline">
					<mat-date-range-input [rangePicker]="picker">
						<input (click)="picker.open()" name="dataInicioFiltro" matStartDate placeholder="Inicio">
						<input (click)="picker.open()" name="dataFimFiltro" matEndDate placeholder="Fim">
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker touchUi #picker></mat-date-range-picker>
				</mat-form-field>
				&nbsp;
				<button type="submit" (click)="filtrar()" [disabled]="!desabilitarBotaoFiltro()"
					style="margin-top: 6px; padding: 10px; position: absolute;" class="btn btn-primary">OK</button>
			</div>

		</div> -->
		
		<div class="row">
			<div class="col-md-6">
				<label><b>Filtrar por período</b></label> &nbsp;&nbsp;&nbsp;

				<mat-form-field appearance="outline">
					<mat-label>Início</mat-label>
					<input matInput [matDatepicker]="pickerInicio" name="dataInicioFiltro" 
					(change)="handleChange($event)" 
					(dateChange)="handleChange($event)"
					ngDateMask [format]="'dd/MM/yyyy'" [separator]="'/'"
					>
					<mat-hint>DD/MM/YYYY</mat-hint>
					<mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
					<mat-datepicker #pickerInicio [startAt]="beginDate"></mat-datepicker>
				</mat-form-field>
				&nbsp;&nbsp;

				<mat-form-field appearance="outline">
					<mat-label>Término</mat-label>
					<input matInput [matDatepicker]="pickerFim" [min]="beginDate" name="dataFimFiltro">
					<mat-hint>DD/MM/YYYY</mat-hint>
					<mat-datepicker-toggle matSuffix [for]="pickerFim"></mat-datepicker-toggle>
					<mat-datepicker #pickerFim [startAt]="endDate"></mat-datepicker>
				</mat-form-field>

				&nbsp;
				<button type="submit" (click)="filtrar()" [disabled]="!desabilitarBotaoFiltro()"
					style="margin-top: 6px; padding: 10px; position: absolute;" class="btn btn-primary">OK</button>
			</div>

		</div>
		<hr />
		<div *ngIf="dadosGrafico != undefined">
			<div class="card">
				<div class="card-body">
					<h4 style="color: gray;">Evolução dos Acessos e da Média Semanal/Mensal</h4>

					<div class="chartjs-container">

						<canvas baseChart [datasets]="lineChartData" [labels]="dataPorDia.length > 0 ? lineChartLabels : 0"
							[options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
							[chartType]="lineChartType" height="100px">
						</canvas>

					</div>
				</div>
			</div>
			<br>
			<div class="card">
				<div class="card-body">
					<h4 style="color: gray;">Indicador Chave de Performance</h4>

					<div class="chartjs-container">

						<canvas baseChart [datasets]="lineChartDataKPI" [labels]="dataPorDiaKPI.length > 0 ? lineChartLabelsKPI : 0"
							[options]="lineChartOptionsKPI" [colors]="lineChartColorsKPI"
							chartType="line" height="100px">
						</canvas>

					</div>
				</div>
			</div>
		</div>
	</form>
</div>
