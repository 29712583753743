import { Injectable } from '@angular/core';
import { Sonda } from '../model/sondaModel';
import { http } from './config.service';

@Injectable({
	providedIn: 'root'
})
export class SondaService {
	apiUrl:string = 'api/public/sonda/';

	constructor() {
	}

	getSondas = () => {
		return http.get(this.apiUrl)
	}

	getSondasAtivas = () => {
		return http.get(this.apiUrl + "?Ativo=true")
	}

	getSonda = async (id:string) => {
		return http.get(this.apiUrl + id)
	}

	addSonda = async (model) => {
		return http.post(this.apiUrl, model);
	}

	putSonda = async (model) => {
		return http.put(this.apiUrl, model);
	}

	delSonda = async (id:string) => {
		return http.delete(this.apiUrl + id);
	}
}
