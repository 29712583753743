import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { LoginData } from 'src/app/model/login-data';
import { MqttClient, MqttSelectedApps } from 'src/app/model/mqttClient';
import { AplicacaoService } from 'src/app/services/aplicacao.service';
import { MqttService } from 'src/app/services/mqtt.service';
import { UnidadeIntervencaoService } from 'src/app/services/unidadeIntervencao.service';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css', '../../app.component.css']
})
export class CreateClientComponent implements OnInit {

  aplicacoes: any[];
  sondas: any;
  carregado = false;
  cor: string = "primary";
  displayedColumns = ['sonda', 'aplicacao', 'topic', 'acoes'];
  dataSource: MatTableDataSource<MqttSelectedApps>;

  listaAplicacaoesAdicionadas: MqttSelectedApps[] = []

  appSelecionada: MqttSelectedApps = {
    aplicacaoId: '',
    nomeSonda: '',
    topicoMQTT: '',
    sondaId: null,
    nomeAplicacao: '',
    chaveUsuarioCadastro: ''
  }

  clients: MqttClient = {
    sondaId: null,
    aplicacaoId: null,
    topicoMQTT: null,
  }

  loginData: LoginData

  constructor(
    private unidadeIntervencaoService: UnidadeIntervencaoService,
    private mqttService: MqttService,
    private aplicacaoService: AplicacaoService,
    private snackBar: MatSnackBar) {

    this.loginData = JSON.parse(localStorage.getItem('loginData'))
  }

  ngOnInit(): void {
    this.getSondas();
    this.getAplicacoes();
  }

  PreencheTopic(evt) {
    if (evt != null) {
      if (this.clients.sondaId != null && this.clients.aplicacaoId != null) {
        var sonda = this.sondas.find(x => x.unin_Cd_Unid_Int == this.clients.sondaId)

        if (evt.clientID != undefined) {
          this.clients.topicoMQTT = sonda.unin_Sg_Unid_Int.split('/')[0] + ':' + evt.clientID
        }
        else {
          var aplicacao = this.aplicacoes.find(x => x.idAplicacao == this.clients.aplicacaoId)
          this.clients.topicoMQTT = sonda.unin_Sg_Unid_Int.split('/')[0] + ':' + aplicacao.clientID
        }
      }
    }
  }

  async getAplicacoes() {
    try {
      const response = await this.aplicacaoService.GetAplicacoes();
      if (response.data != null) {
        this.aplicacoes = response.data;
        setTimeout(() => {
          this.carregado = true;
        }, 1000);
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.log(error);
    }
  }

  async getSondas() {
    try {
      const response = await this.unidadeIntervencaoService.GetSondas();
      if (response.data != null) {
        this.sondas = response.data
        setTimeout(() => {
          this.carregado = true;
        }, 1000);
      }
      else {
        this.sondas = null;
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  limparSelecaoSonda() {
    this.clients.sondaId = null
    if (this.clients.topicoMQTT != null) {
      this.clients.topicoMQTT = null
    }
  }

  limparSelecaoAplicacao() {
    this.clients.aplicacaoId = null
    if (this.clients.topicoMQTT != null) {
      this.clients.topicoMQTT = null
    }
  }

  desabilitaCampos() {
    if (this.listaAplicacaoesAdicionadas.length == 0) {
      return false
    }
    return true
  }

  validaCampos() {
    if (this.clients.sondaId == undefined || this.clients.aplicacaoId == undefined || this.clients.topicoMQTT == undefined) {
      return false
    }
    return true
  }

  async adicionarRegistros() {

    var jaExiste = this.listaAplicacaoesAdicionadas.find(x => x.aplicacaoId == this.clients.aplicacaoId && x.sondaId == this.clients.sondaId);

    if (jaExiste != undefined) {
      this.snackBar.open("Registro já inserido na lista!", "OK", { duration: 4000, panelClass: ['alerta'] });
      return;
    }

    await this.mqttService.CheckIfExistsOnDatabase(this.clients.sondaId, this.clients.aplicacaoId).then(response => {
      if (response.data != "") {
        this.snackBar.open("Este registro já encontra-se cadastrado na base de dados!", "OK", { duration: 6000, panelClass: ['alerta'] });
        return
      }
      else {

        var sonda = this.sondas.find(x => x.unin_Cd_Unid_Int == this.clients.sondaId)
        var aplicacao = this.aplicacoes.find(x => x.idAplicacao == this.clients.aplicacaoId)

        this.appSelecionada = new MqttSelectedApps()

        this.appSelecionada.aplicacaoId = this.clients.aplicacaoId
        this.appSelecionada.sondaId = this.clients.sondaId
        this.appSelecionada.topicoMQTT = this.clients.topicoMQTT.toLowerCase()
        this.appSelecionada.nomeAplicacao = aplicacao.clientID

        let sondaName = sonda.unin_Sg_Unid_Int.split('/')[0].toLowerCase();
        this.appSelecionada.nomeSonda = sondaName.toLowerCase()
        this.appSelecionada.chaveUsuarioCadastro = this.loginData.chave

        this.listaAplicacaoesAdicionadas.push(this.appSelecionada)
        this.dataSource = new MatTableDataSource<MqttSelectedApps>(this.listaAplicacaoesAdicionadas);

        this.clients.topicoMQTT = null
        this.clients.sondaId = null
        this.clients.aplicacaoId = null
      }
    });
  }

  removerItemDaLista(idAplicacao, idSonda) {

    const index = this.listaAplicacaoesAdicionadas.findIndex(x => x.aplicacaoId === idAplicacao && x.sondaId == idSonda);
    this.listaAplicacaoesAdicionadas.splice(index, 1);

    this.dataSource = new MatTableDataSource<MqttSelectedApps>(this.listaAplicacaoesAdicionadas);

    this.snackBar.open("Item removido. ", "OK", { duration: 4000, panelClass: ['sucesso'] })
  }

  async PostData() {

    // let listaFormatada = this.listaAplicacaoesAdicionadas.map(function (obj) {
    //   return {
    //     sondaId: obj.sondaId,
    //     aplicacaoId: obj.aplicacaoId,
    //     topicoMQTT: obj.topicoMQTT
    //   }
    // });

    this.carregado = false;
    await this.mqttService.PostApps(this.listaAplicacaoesAdicionadas).then(response => {
      if (response.data != null) {
        setTimeout(() => {
          this.carregado = true;
        }, 1000)

        this.clearFieldsAfterPost();
        this.snackBar.open("Client cadastrado com sucesso.", "OK", { duration: 4000, panelClass: ['sucesso'] });

      }
      else {
        this.carregado = true;
        this.snackBar.open("Erro ao cadastrar. Entre em contato com o administrador!", "OK", { duration: 4000, panelClass: ['erro'] });
      }

    }).catch(error => {
      console.log(error);
      this.carregado = true;
      this.snackBar.open("Erro ao cadastrar. Entre em contato com o administrador! " + error, "OK", { duration: 4000, panelClass: ['erro'] });
    })
  }

  clearFieldsAfterPost() {
    this.clients.topicoMQTT = null
    this.clients.sondaId = null
    this.clients.aplicacaoId = null
    this.listaAplicacaoesAdicionadas = []
  }

}
