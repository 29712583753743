import { Injectable } from '@angular/core';
import { http } from "./config.service"

const url = 'api/kpi/'

@Injectable({
  providedIn: 'root'
})

export class KpiService {

  GetAll() {
    return http.get(url)
  }

  Post(kpi){
    return http.post(url, kpi)
  }

  Update(kpi){
    return http.put(url, kpi)
  }

}
