import { Injectable } from '@angular/core';
import { http } from "./config.service"

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  GetTodosUsuarioAplicacao() {
    return http.get('api/usuarioaplicacao/GetTodosUsuarioAplicacao')
  }

  InserirUsuarioAplicacao(Usuario) {
    return http.post('api/usuarioaplicacao/InserirUsuarioAplicacao/', Usuario)
  }

  AtualizarUsuarioAplicacao(Usuario) {
    return http.put('api/usuarioaplicacao/AtualizarUsuarioAplicacao/', Usuario)
  }

  GetUsuarioAplicacao(id) {
    return http.get('api/usuarioaplicacao/GetUsuarioAplicacao/' + id)
  }

  RemoverUsuarioAplicacao(id) {
    return http.delete('api/usuarioaplicacao/RemoverUsuarioAplicacao/' + id)
  }

  RetornaNomeUsuario(sessionId, chave) {
    return http.get('api/usuarioaplicacao/RetornaNomeUsuario/' + sessionId + '/' + chave)
  }


  GetAcessoAplicacao(id) {
    return http.get('api/acessoaplicacao/' + id)
  }

  ExecutarAplicacao(id, idAplicacao) {
    return http.post('api/aplicacao/ExecutarAplicacao', { id: id, idAplicacao: idAplicacao })
  }

  InserirUsuario(user) {
    return http.post('api/public/user/insertUser/', user)
  }


  GetUserGraphic(dataInicio, dataFim) {

	console.log('URL ACESSO', 'api/graph/usersAccess/' + dataInicio + '/' + dataFim)

    return http.get('api/graph/usersAccess/' + dataInicio + '/' + dataFim)
  }
}
