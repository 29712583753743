<div class="content">
  <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
    [show]="!carregado">
  </ngx-loading>
  <form class="p-3">
    <div class="jumbotron">
      <div class="row">
        <div class="col-md-12">
           <h1 class="textoNoCentro" style="font-weight: bold; text-align: center; color: #008542;">
         DASHBOARD MQTT
        </h1>
        </div>

      </div>
      <br><br>
      <div class="row w-100" style="align-items: center;">
        <div class="col-md-4">
          <h3 style="font-weight: bold;">
            SITUAÇÃO OPERACIONAL
          </h3>
        </div>

        <div class="col-md-2" style="text-align: right;">
          <label style="font-weight: bold;">Filtrar por:</label>
        </div>
        <div class="col-md-2">

          <ng-select notFoundText="Nenhum registro encontrado!"
            (change)="FiltrarPorSituacao(app.situacao, app.nomeSonda)" (clear)="limparSelecaoSituacao()"
            style="min-width: 250px;" [items]="listaSituacao" bindLabel="situacao" bindValue="situacao"
            placeholder="Situação" appendTo="body" name="situacao" [(ngModel)]="app.situacao">
          </ng-select>
        </div>

        <div class="col-md-2">
          <ng-select notFoundText="Nenhum registro encontrado!"
            (change)="FiltrarPorSituacao(app.situacao, app.nomeSonda)" (clear)="limparSelecaoSonda()"
            style="min-width: 250px;" [items]="listaSondas" bindLabel="nomeSonda" bindValue="nomeSonda"
            placeholder="Nome" appendTo="body" name="nomeSonda" [(ngModel)]="app.nomeSonda">
          </ng-select>
        </div>
      </div>
      <br>

      <ngx-skeleton-loader [theme]="{'border-radius': '10px', height: '150px', width: '25%', border: '1px solid white'}"
        [count]="apps.length" *ngIf="!carregado && apps != undefined" appearance="circle">
      </ngx-skeleton-loader>

      <div class="fixarConteudoDashBoard">
        <div class="row w-100" *ngIf="carregado">
          <div class="col-md-3" style="padding-bottom: 20px;" *ngFor="let s of apps">
            <div title="Exibir histórico" class="card sm-1 p-3" [ngClass]="s.situacao">
              <div (click)="open(mymodal, s)" style="cursor: pointer;">
                <div
                  [ngClass]="{'text-danger': s.situacao == 'Desconectado', 'text-success':s.situacao == 'Conectado', 'text-warning':s.situacao == 'Inativo'}"
                  class="text-center mt-3">
                  <h1><b>{{s.nomeSonda}}</b></h1>
                </div>
                <div
                  [ngClass]="{'text-danger': s.situacao == 'Desconectado', 'text-success':s.situacao == 'Conectado', 'text-warning':s.situacao == 'Inativo'}"
                  class="text-center mt-2">
                  <h5>{{s.nomeAplicacao}}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="apps.length == 0">
            <div style="color: red; text-align: center; font-weight: bold; font-size: 16px;">
              <br><br><br><br><br><br>
              <label>Nenhum registro encontrado!</label>
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>

<!-- Modal Historico -->
<ng-template #mymodal let-modal>
  <div class="card-header textoNoCentro">
    <label>{{dadosBasicos.nomeSonda}}
      &nbsp;&nbsp;&nbsp;>>&nbsp;&nbsp;&nbsp;{{dadosBasicos.nomeAplicacao}}&nbsp;&nbsp;&nbsp;>>&nbsp;&nbsp;&nbsp;
      {{dadosBasicos.situacao}}</label>
    <button style="outline: none;" type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="height: 640px;"
    *ngIf="dadosBasicos != null && dataSourceHistoricoMensagens != undefined">
    <div class="row">

      <div class="col-md-7">
        <label style="text-align: center; width: 100%; font-weight: bold;">Histórico de movimentações</label>
        <mat-table class="table" #table [dataSource]="dataSourceHistoricoMensagens">

          <ng-container matColumnDef="topic">
            <mat-header-cell *matHeaderCellDef> Tópico </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.topic}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="messageType">
            <mat-header-cell *matHeaderCellDef> messageType </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.messageType}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="typeOrName">
            <mat-header-cell *matHeaderCellDef> typeOrName </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.typeOrName}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="fileExtension">
            <mat-header-cell *matHeaderCellDef> Extensão </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.fileExtension}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <mat-header-cell *matHeaderCellDef> Data </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.createdAt | date: 'dd/MM/yyyy HH:mm'}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsHistoricoMensagens"></mat-header-row>
          <mat-row class="mat-row" *matRowDef="let row; columns: displayedColumnsHistoricoMensagens;"></mat-row>

          <tr class="row" *matNoDataRow>
            <td class="col-md-12" style="text-align: center; font-weight: bold; font-size: 16px;">
              <br>Nenhum registro encontrado!
            </td>
          </tr>

        </mat-table>
      </div>

      <div class="col-md-4" style="left: 7%;">
        <label style="text-align: center; width: 100%; font-weight: bold;">Histórico de conexões</label>
        <mat-table class="table" #table [dataSource]="dataSourceHistoricoConexoes">

          <ng-container matColumnDef="situacao">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element"
              [ngClass]="element.situacao == 'Desconectado' ? 'text-danger' : 'text-success' "> {{element.situacao}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="data">
            <mat-header-cell *matHeaderCellDef> Data </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.dataConexao | date: 'dd/MM/yyyy HH:mm'}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsHistoricoConexoes"></mat-header-row>
          <mat-row class="mat-row" *matRowDef="let row; columns: displayedColumnsHistoricoConexoes;"></mat-row>

          <tr class="row" *matNoDataRow>
            <td class="col-md-12" style="text-align: center; font-weight: bold; font-size: 16px;">
              <br>Nenhum registro encontrado!
            </td>
          </tr>

        </mat-table>
      </div>
    </div>
    <br>


  </div>
</ng-template>
<!-- FIM Modal-->
