import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientComponent } from './client/client.component';
import { LoginComponent } from './login/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthenticationService } from './services/authentication.service'
import { LoginService } from './services/login.service';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { RenovaTokenService } from './services/renovaToken.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CadAplicaComponent } from './Aplicacao/cad-aplica/cad-aplica.component';
import { DocComponent } from './doc/doc.component';

import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgxLoadingModule } from 'ngx-loading';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FooterComponent } from './Components/template/footer/footer.component';
import { NavComponent } from './Components/template/nav/nav.component'
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ListarAplicacoesComponent } from './Aplicacao/listar-aplicacoes/listar-aplicacoes.component'
import { MatTableModule } from '@angular/material/table';
import { ListarUsuariosComponent } from './Usuario/listar/listar-usuarios/listar-usuarios.component';
import { CadastrarUsuarioComponent } from './Usuario/cadastrar/cadastrar-usuario/cadastrar-usuario.component';
import { AtualizarAplicacaoComponent } from './Aplicacao/atualizar/atualizar-aplicacao/atualizar-aplicacao.component';
import { AtualizarUsuarioComponent } from './Usuario/atualizar/atualizar-usuario/atualizar-usuario.component';
import { AcessoAplicacaoComponent } from './acesso-aplicacao/acesso-aplicacao.component';
import { ListarSchemasComponent } from './Schemas/Listar/listar-schemas/listar-schemas.component';
import { CadastrarSchemaComponent } from './Schemas/Cadastrar/cadastrar-schema/cadastrar-schema.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AtualizarSchemaComponent } from './Schemas/Atualizar/atualizar-schema/atualizar-schema.component';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { IndexComponent } from './Intervencao/index/index.component';
import { NavIntervencoesComponent } from './Components/template/nav-intervencoes/nav-intervencoes.component';
import { MatButtonModule } from '@angular/material/button';
import { HomeComponent } from './home/home.component';
import { ArvoreDetailComponent } from './Intervencao/arvore-detail/arvore-detail.component';
import { CreateComponent } from './Intervencao/create/create.component';
import { InterventionPanelComponent } from './intervention-panel/intervention-panel.component';
import { MatExpansionModule } from '@angular/material/expansion'
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { TagInputModule } from 'ngx-chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule  } from '@angular/material/form-field';
import { MatInputModule   } from '@angular/material/input';
import { MenuComponent } from './MQTT/menu/menu.component';
import { CreateClientComponent } from './MQTT/create-client/create-client.component';
import { ListClientsComponent } from './MQTT/list-clients/list-clients.component';
import { UpdateClientComponent } from './MQTT/update-client/update-client.component';
import { ListComponent } from './kpi/list/list.component';
import { ChartsModule } from 'ng2-charts';
import { OffshoreComponent } from './unidadeOperacional/offshore/offshore.component';
import { AcessoUsuariosComponent } from './graficos/acesso-usuarios/acesso-usuarios.component';
import { SwitcherBarComponent } from './Components/switcher-bar/switcher-bar.component';
import { SondaComponent } from './sonda/sonda.component';
import { SondaDetailComponent } from './Components/sonda-detail/sonda-detail.component';
import { environment } from 'src/environments/environment';
import { NgDateMaskModule } from './Components/ngDateMask/ngDateMask.module';


export const MY_FORMATS = {
	parse: {
	  dateInput: 'DD/MM/yyyy',
	},
	display: {
	  dateInput: 'DD/MM/yyyy',
	  monthYearLabel: 'MM yyyy',
	  dateA11yLabel: 'DD/MM/yyyy',
	  monthYearA11yLabel: 'MM yyyy',
	},
  };

export function initApp(http: HttpClient) {
	return async () => {
		await http.get('api/environment').toPromise().then(res => {
			environment.apiURL = res.toString()
		});
	};
}

@NgModule({
	declarations: [
		AppComponent,
		ClientComponent,
		LoginComponent,
		CadAplicaComponent,
		FooterComponent,
		NavComponent,
		ListarAplicacoesComponent,
		ListarUsuariosComponent,
		CadastrarUsuarioComponent,
		AtualizarAplicacaoComponent,
		AtualizarUsuarioComponent,
		AcessoAplicacaoComponent,
		ListarSchemasComponent,
		CadastrarSchemaComponent,
		AtualizarSchemaComponent,
		DocComponent,
		IndexComponent,
		NavIntervencoesComponent,
		HomeComponent,
		ArvoreDetailComponent,
		CreateComponent,
		InterventionPanelComponent,
		MenuComponent,
		CreateClientComponent,
		ListClientsComponent,
		UpdateClientComponent,
		ListComponent,
		OffshoreComponent,
		SwitcherBarComponent,
		AcessoUsuariosComponent,
		SondaComponent,
		SondaDetailComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MatSnackBarModule,
		NgxSkeletonLoaderModule,
		NgxLoadingModule,
		MatCheckboxModule,
		MatToolbarModule,
		MatSidenavModule,
		MatListModule,
		MatIconModule,
		MatMenuModule,
		MatPaginatorModule,
		MatTableModule,
		MatTabsModule,
		NgbModule,
		MatSelectModule,
		NgSelectModule,
		MatButtonModule,
		MatExpansionModule,
		NgxGalleryModule,
		TagInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		ChartsModule,
		MatInputModule,
		NgDateMaskModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: APP_INITIALIZER, useFactory: initApp, multi: true, deps: [HttpClient] },
		// {
		// 	provide: DateAdapter,
		// 	useClass: MomentDateAdapter,
		// 	deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		// },
		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
		{ provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
		AuthenticationService,
		LoginService,
		RenovaTokenService,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
