// window.onload = function() {
// 	var switcherBar = document.getElementById("switcherBar")

// 	if(switcherBar !== undefined && switcherBar !== null){
// 		LoadApps();
// 	}
// }

async function loadSwitcher(uri, token = undefined){
	await LoadApps(uri, token);
}

async function LoadApps(uri, token) {
	var apps = await GetApps(uri, token);

	var optionsTag = "";

	if(apps === undefined) {
		return;
	}

	var appAtual = DefaultSelected(apps.aplicacao);

	apps.aplicacao.map(function(app) {
		if(appAtual !== null && appAtual === app.nomeAplicacaoAmigavel) {
			optionsTag += "<option value='" + app.urlAcesso + "' selected>" + app.nomeAplicacaoAmigavel + "</option>";
		}else {
			optionsTag += "<option value='" + app.urlAcesso + "'>" + app.nomeAplicacaoAmigavel + "</option>";
		}
	});

	if (lstApps !== undefined && lstApps !== null) {
		lstApps.innerHTML = optionsTag;
	}
}

async function GetApps(uri, token) {
	var result = undefined;

	var reqProps = {
		mode: "cors",
		method: "GET"
	};

	if (token !== undefined) {
		reqProps = {
			mode: "cors",
			method: "GET",
			headers: new Headers({
				Authorization: "Bearer " + token,
				"Content-Type": "application/json",
			})
		}
	}

	await fetch(uri + "/api/acessoaplicacao", reqProps)
		.then(async function (response) {
			result = await response.json();
		}).catch(er => {
			// console.log("ERRO - ", er)
		});
	return result;
}

function TriggerAppsSelect(path){
	window.location = path;
}

function DefaultSelected(apps) {
	var appPath = window.location.href;

	var app = apps.find(obj => obj.urlAcesso === appPath);

	if (app === null || app === undefined) {
		return null;
	}

	return app.nomeAplicacaoAmigavel;
}

var switcher = function () {

	var abrirSwitcher = async function (uri){
		await LoadApps(uri);
	};

	var selecionarApp = function (path){
		window.location = path;
	}

	return {
		loadSwitcher : abrirSwitcher,
		TriggerAppsSelect : selecionarApp
	}
}();
