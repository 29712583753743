import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Aplicacao } from '../../model/aplicacao';
import { AplicacaoService } from '../../services/aplicacao.service';

@Component({
  selector: 'app-listar-aplicacoes',
  templateUrl: './listar-aplicacoes.component.html',
  styleUrls: ['../../app.component.css']
})

export class ListarAplicacoesComponent implements OnInit {
  title = 'PWO - Controle de Aplicações';

  displayedColumns = ['nome', 'tipo', 'integrada', 'acoes'];
  dataSource: MatTableDataSource<Aplicacao>;
  aplicacoes: Aplicacao[];
  carregado = false;


  constructor(
    private aplicacaoService: AplicacaoService,
    private snackBar: MatSnackBar) { }


  ngOnInit(): void {
    window.document.title = this.title;
    this.getAplicacoes();

  }

  async remover(valor) {
    this.carregado = false;
    await this.aplicacaoService.Remover(valor).then(response => {
      if (response.data != null) {
        setTimeout(() => {
          this.carregado = true;
        }, 500);
        this.snackBar.open("Registro removido com sucesso.", "OK", { duration: 4000, panelClass: ['sucesso'] });
        this.getAplicacoes();
      }
      else {
        this.carregado = true;
        this.snackBar.open("Erro ao remover.", "OK", { duration: 4000, panelClass: ['erro'] });
      }

    }).catch(error => {
      this.carregado = true;
      this.snackBar.open("Erro ao remover. ", "OK", { duration: 4000, panelClass: ['erro'] })
      console.error(error);
    })
  }

  filtrar(filtro: string) {

    this.dataSource.filter = filtro.trim().toLocaleLowerCase()
  }

  async getAplicacoes() {
    try {
      const response = await this.aplicacaoService.GetAplicacoes();
      if (response.data != null) {
        this.aplicacoes = response.data;
        this.dataSource = new MatTableDataSource<Aplicacao>(this.aplicacoes);
        setTimeout(() => {
          this.carregado = true;
        }, 500);
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
    }
  }
}
