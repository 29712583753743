
<mat-sidenav-container fullscreen [style]="showSwitcher ? 'top: 40px;' : ''">
  <mat-sidenav class="sidenav" *ngIf="verMenu && loginData != null" mode="side" #sidenav fixedInViewport="true" [fixedTopGap]="showSwitcher ? 40 : 0" [opened]="!isShowing && verMenu" position="start">
    <a (click)="voltaParaListaDeAplicacoes()">
      <img style="padding-left: 14px;top: 14px; position: fixed;" height="50px" src="assets/Principal_h_cor_RGB.jpg">
    </a>
    <br>
    <mat-nav-list class="nav-list">

      <a routerLink="../../administracao/usuario/listar" mat-list-item>
        <i class="material-icons" style="font-size: 40px">
          supervised_user_circle
        </i>
        Usuários
      </a>

      <a  routerLink="../../administracao/aplicacao/listar" mat-list-item>
        <i class="material-icons" style="font-size: 40px">
          admin_panel_settings
        </i>
        Aplicações
      </a>

      <a  routerLink="../../administracao/schemas/listar" mat-list-item>
        <i class="material-icons" style="font-size: 40px">
          assignment
        </i>
        Esquemas
      </a>

      <a routerLink="../../administracao/mqtt/list-clients" mat-list-item>
        <i class="material-icons" style="font-size: 40px">
          published_with_changes
        </i>
        MQTT
      </a>

      <a routerLink="../../administracao/kpi" mat-list-item>
        <i class="material-icons" style="font-size: 40px">
          moving
        </i>
        KPI
      </a>

      <a routerLink="../../administracao/unidadesOffShore" mat-list-item>
        <i class="material-icons" style="font-size: 40px">
          filter_list
        </i>
        Un - OffShore
      </a>

      <a  routerLink="../../docs" mat-list-item>
        <i class="material-icons" style="font-size: 40px">
          plagiarism
        </i>
        Documentação
      </a>

	  <a  routerLink="../../administracao/sonda" mat-list-item>
        <i class="material-icons" style="font-size: 40px">
			directions_boat
        </i>
        Sonda
      </a>

    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>

    <mat-toolbar class="header mat-elevation-z" *ngIf="loginData != null && verMenu" >
      <button class="btn" style="cursor: pointer; color: white;" title="Retrair menu" mat-icon-button (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>


      </button>
      <label class="title-group">
        PWO
      </label>

      <span class="fill-remaining-space" >

        <button class="btn btnOpcao" style="color: white;" title="opções" mat-icon-button [matMenuTriggerFor]="menu">
          <div style="float: right;">
            Bem vindo, {{loginData.nome}} &nbsp;&nbsp;&nbsp;
          </div>
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
          <button (click)="forceLogout()" mat-menu-item style="outline: none">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sair</span>
          </button>
        </mat-menu>
      </span>

    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>

