import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HomeService } from "../services/home.service";

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
	carregado = false;
	temStorage = false;
	returnUrl: string;

	constructor(
		private homeService: HomeService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit(): void {
		this.validaCookieAndLocalStorage();
		this.returnUrl = this.route.snapshot.queryParams["ReturnUrl"];
	}

	validaCookieAndLocalStorage() {
		this.temStorage = JSON.parse(localStorage.getItem("loginData")) != null;
		const response = this.homeService
			.checkCookies(this.temStorage)
			.then((Response) => {
				if (response != null) {
					const temCookies = Response.data;
					if (temCookies && this.temStorage) {
						window.location.href = "/AcessoAplicacao";
					} else {
						localStorage.removeItem("loginData");

						if (this.returnUrl !== undefined) {
							this.router.navigate(['/loginPage'], {queryParams: {ReturnUrl : this.returnUrl}});
						} else {
							window.location.href = "/loginPage";
						}
					}
				}
			});
	}
}
