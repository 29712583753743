import { Injectable } from '@angular/core';
import { http } from "./config.service"


const apiPath = "api/public/unidadeoffshore/";

@Injectable({
  providedIn: 'root'
})

export class UnidadeOffShoreService {

  GetAll() {
    return http.get(apiPath + 'all')
  }

  Put(unidadeoffshore){
    return http.put(apiPath, unidadeoffshore)
  }

  Post(unidadeoffshore){
    return http.post(apiPath, unidadeoffshore)
  }
}
