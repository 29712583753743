import { Injectable, OnDestroy } from '@angular/core';
import { LoginData } from '../model/login-data';
import { RenovaTokenService } from './renovaToken.service';
import { interval, Subscription } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { LoginService } from './login.service';


const tokenUpdater = interval(1000 * 60);

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

	loginData: LoginData;
	updateSubscription: Subscription;
	tokenNovo: any;
	validateToken: any = {
		token: '',
	};
	constructor(
		private router: Router,
		private renovaTokenService: RenovaTokenService, private loginService: LoginService) {

		try {
			this.loginData = JSON.parse(localStorage.getItem('loginData'));

			if (this.loginData && !this.updateSubscription) {
				if (this.isExpired()) {
					this.logout();
				}

				this.updateSubscription = tokenUpdater.subscribe(x => {
					this.validateToken.token = this.loginData.token
					this.tokenNovo = this.renovaTokenService.renovaToken(this.validateToken).subscribe(
						(response) => {
							if (response != null) {
								var loginData: LoginData = JSON.parse(localStorage.getItem('loginData'));
								loginData.token = response;
								localStorage.removeItem('loginData');
								localStorage.setItem('loginData', JSON.stringify(loginData));
							}
							else {
								this.logout()
							}
						}
					)
				});
			}
		}
		catch (error) {
			console.log(error)
		}
	}

	ngOnDestroy() {
		console.debug("OnDestroy");
	}

	logout() {

		if (this.updateSubscription)
			this.updateSubscription.unsubscribe();
		this.loginData = null;

		localStorage.removeItem('loginData');

		localStorage.removeItem("CampoSelecionado");

		localStorage.removeItem("UrlCampoSelecionado");

		localStorage.removeItem("PocoSelecionado");

		localStorage.removeItem("UrlPocoSelecionado");

		localStorage.removeItem("UnidadeSelecionada");

		localStorage.removeItem("UrlUnidadeSelecionada");

		localStorage.removeItem("modoIntervencao");

		localStorage.removeItem("intervencaoSelecionada");

		localStorage.removeItem("SourceArvorePocos");

		this.loginService.logOut();

		this.router.navigate(['/homePage']);

		return false;
	}

	public isLoggedIn() {
		if (this.loginData == null) return false;

		return true;
	}

	isLoggedOut() {
		return !this.isLoggedIn();
	}

	getExpiration() {
		const decoded = jwt_decode(this.loginData.token);

		if (decoded.exp === undefined) return null;

		const date = new Date(0);
		date.setUTCSeconds(decoded.exp);
		return date;
	}

	isExpired() {

		try {
			const decoded = jwt_decode(this.loginData.token);

			if (decoded === undefined) {
				return true
			}
		} catch (error) {
			return true
		}


		const date = this.getExpiration();
		if (date === undefined) return false;
		return !(date.valueOf() > new Date().valueOf());
	}
}

