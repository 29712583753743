<div class="content">
  <form class="p-3" ngNativeValidate (ngSubmit)="inserirAplicacao()">
    <div class="card">
      <div class="card-header textoNoCentro">
        CADASTRAR APLICAÇÃO
      </div>
      <div class="card-body">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>

        <div class="row">
          <div class="col-md-6">
           <b><span>Campos com ( <span style="color: red;">*</span>  ) são obrigatórios</span></b>
          </div>
        </div>
        <br>

        <div class="row">
          <div class="col-md-6">
            <label>Nome*</label>
            <input name="NomeAplicacao" type="text" required class="form-control" [(ngModel)]="aplicacao.NomeAplicacao" placeholder="Nome">
          </div>
          <div class="col-md-6">
            <label>Nome Amigavel*</label>
            <input name="NomeAplicacaoAmigavel" type="text" required class="form-control" [(ngModel)]="aplicacao.NomeAplicacaoAmigavel"
              placeholder="Nome Amigavel">
          </div>
        </div>
        <br>

        <div class="row">

          <div class="col-md-6">
            <label for="Ordem">Ordem</label>
            <input (change)="validaLimiteOrdem()" type="number" [(ngModel)]="aplicacao.Ordem" name="Ordem" id="Ordem" class="form-control" >
          </div>

          <div class="col-md-6">
            <label>Tipo de Aplicação</label>
            <select  [(ngModel)]="aplicacao.TipoAplicacao" name="TipoAplicacao" id="TipoAplicacao" class="form-control">
              <option>Web</option>
              <option>Desktop</option>
              <option>Mobile</option>
            </select>
          </div>

        </div>
        <br>

        <div class="row">

          <div class="col-md-6">
            <label>Client ID*</label>
            <input required type="text" name="ClientID" class="form-control" [(ngModel)]="aplicacao.ClientID" placeholder="Client ID">
          </div>

          <div class="col-md-6">
            <label>Client Secret*</label>
            <input required type="text" name="ClientSecret" class="form-control" [(ngModel)]="aplicacao.ClientSecret" placeholder="Client Secret">
          </div>

        </div>
        <br>

        <div class="row">
          <div class="col-md-6">
            <label>Ícone</label>
            <input type="file" (change)="onFileSelect($event.target)" title="Selecionar ícone" accept=".svg" class="form-control">
          </div>
        </div>
        <br>

        <div class="row">
          <div class="col-md-12">
            <label>Url de Acesso</label>
            <input type="text" name="UrlAcesso" class="form-control" [(ngModel)]="aplicacao.UrlAcesso" placeholder="Url de Acesso">
          </div>
          <div class="col-md-12">
            <label>Url de Webhook</label>
            <input type="text" name="UrlWebHook" class="form-control" [(ngModel)]="aplicacao.UrlWebHook" placeholder="Url de Webhook">
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-6">
            <label>Url de Redirecionamento</label>
            <textarea class="form-control" name="UrlRedirecionamento" [(ngModel)]="aplicacao.UrlRedirecionamento"
              placeholder="Url de Redirecionamento"></textarea>
          </div>

          <div class="col-md-3">
            <label>Aplicação deve receber eventos de sincronização do PWO?</label>
            <select  [(ngModel)]="aplicacao.RecebeEventos" name="RecebeEventos" id="RecebeEventos" class="form-control">
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>

		  <div class="col-md-3">
            <label>Aplicação Integrada?</label>
			<select  [(ngModel)]="aplicacao.AplicacaoIntegrada" name="AplicacaoIntegrada" id="AplicacaoIntegrada" class="form-control">
				<option value="true">Sim</option>
				<option value="false">Não</option>
			  </select>
          </div>

        </div>
        <br>

        <br>
        <button class="btn btn-default" style="border: 1px solid black;" type="submit" (click)="voltarPagina()"> Cancelar</button>
        &nbsp;&nbsp;&nbsp;
        <button class="btn btn-success" type="submit">
          Salvar
        </button>
      </div>
    </div>
  </form>
</div>
