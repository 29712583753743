import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginData } from 'src/app/model/login-data';
import { AplicacaoService } from 'src/app/services/aplicacao.service';
import { Aplicacao } from '../../model/aplicacao';

@Component({
  selector: 'app-cad-aplica',
  templateUrl: './cad-aplica.component.html',
  styleUrls: ['../../app.component.css']
})
export class CadAplicaComponent implements OnInit {
  title = 'PWO - Controle de Aplicações';
  loginData: LoginData;
  icone: any;
  totalAplicacoes: number
  //aplicacao: Aplicacao = {} as Aplicacao;

  aplicacao: Aplicacao = {
    NomeAplicacao: '',
    NomeAplicacaoAmigavel: '',
    Ordem: null,
    TipoAplicacao: null,
    ClientID: '',
    ClientSecret: '',
    Icone: '',
    UrlAcesso: '',
    UrlRedirecionamento: '',
    UrlWebHook: '',
    UserId: '',
    RecebeEventos: null,
	AplicacaoIntegrada: null
  };

  carregado = false;
  constructor(private snackBar: MatSnackBar,
    private aplicacaoService: AplicacaoService,
    private router: Router) { }

  ngOnInit(): void {
    window.document.title = this.title;

    this.aplicacao.Ordem = 1 // default valeu no combo
    this.aplicacao.TipoAplicacao = 'Web' // default valeu no combo
    this.aplicacao.RecebeEventos = true // default valeu no combo
	this.aplicacao.AplicacaoIntegrada = true // default valeu no combo
    setTimeout(() => {
      this.carregado = true;
    }, 1000);
    this.getTotalAplicacoes()
  }

  async getTotalAplicacoes() {
    try {
      const response = await this.aplicacaoService.GetAplicacoes();
      if (response.data != null) {
        this.totalAplicacoes = response.data.length;
      }
      else {
        this.carregado = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  validaLimiteOrdem(){
    if(this.aplicacao.Ordem > this.totalAplicacoes){
      this.snackBar.open("A ordem limite é: " + this.totalAplicacoes, "OK", { duration: 5000, panelClass:['alerta'] });
      this.aplicacao.Ordem = this.totalAplicacoes
      return
    }
  }

  async inserirAplicacao() {
    this.carregado = false;
    const loginData = JSON.parse(localStorage.getItem('loginData'))
    this.aplicacao.UserId = loginData.chave
    this.aplicacao.Icone = this.icone
    this.aplicacao.Ordem = Number(this.aplicacao.Ordem)

	var recebeEvent = this.aplicacao.RecebeEventos == true ? true : false
    this.aplicacao.RecebeEventos = recebeEvent

	var appIntegrada = this.aplicacao.AplicacaoIntegrada == true ? true : false
    this.aplicacao.AplicacaoIntegrada = appIntegrada

    await this.aplicacaoService.Inserir(this.aplicacao).then(response => {
      if (response.data != null) {
        setTimeout(() => {
          this.carregado = true;
        }, 1000);
        this.snackBar.open("Ação concluida.", "OK", { duration: 3000, panelClass:['sucesso'] });
        this.router.navigate(['/administracao/aplicacao/listar'])
      }
      else {
        this.carregado = true;
        this.snackBar.open("Erro ao cadastrar.", "OK", { duration: 5000, panelClass:['erro'] });
      }

    }).catch(error => {
      console.log(error)
      this.carregado = true;
      this.snackBar.open("Erro ao cadastrar. " + error, "OK", { duration: 3000, panelClass:['erro'] })
    })
  }

  voltarPagina(): void {
    this.router.navigate(['/administracao/aplicacao/listar'])
  }

  onFileSelect(input) {

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onloadend  = (e: any) => {
        this.icone = e.target.result
      }

      reader.readAsText(input.files[0]);
    }
  }

}
