<div class="content">
	<form class="p-3">
		<div class="card">
			<div class="card-header textoNoCentro">CADASTRO DE SONDA</div>
			<div class="card-body">
				<ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}" [show]="!carregado"></ngx-loading>
				<div class="row">
					<div class="col-md-6">
						<input type="text"
							   class="form-control"
							   name="nome"
							   id="nome"
							   [(ngModel)]="data.nome"
								   required
							   placeholder="Nome da Sonda">
					</div>
					<div class="col-md-6">
						<mat-checkbox aria-label="Ativo" class="form-control" style="border: none;"
									  name="ativo"
									  id="ativo"
									  text="Ativo"
									  [(ngModel)]="data.ativo">Ativo</mat-checkbox>
					</div>
				</div>
				<div style="clear: both;">&nbsp;</div>
				<div class="row">
					<div class="col-md-6">
						<input type="date"
								disabled
								class="form-control"
								name="dataSincronizacao"
								id="dataSincronizacao"
								[(ngModel)]="data.dataSincronizacao"
								placeholder="Data de Sincronização">
					</div>
					<div class="col-md-6">
						<mat-checkbox class="form-control" style="border: none;"
										name="sincronizado"
										[disabled]="!data.sincronizado"
										id="sincronizado"
										[(ngModel)]="data.sincronizado">Sincronizado</mat-checkbox>
					</div>
				</div>
				<div style="clear: both;">&nbsp;</div>
				<div class="row">
					<div class="col-md-12" style="text-align: right;">
						<button (click)="onSaveClick()" class="btn btn-primary" style="margin-right: 15px;">Salvar</button>
						<button (click)="onCancelClick()" class="btn btn-primary">Cancelar</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
