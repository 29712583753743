import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Schema } from 'src/app/model/schema';
import { TipoArquivo } from 'src/app/model/tipoArquivo';
import { SchemaService } from 'src/app/services/schema.service';
import { TipoArquivoService } from 'src/app/services/tipoArquivo.service';

@Component({
  selector: 'app-listar-schemas',
  templateUrl: './listar-schemas.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ListarSchemasComponent implements OnInit {

  tabSelecionada: number
  primeiroAcessoTabTipoArquivo: boolean = true
  title = 'PWO - Controle de Esquemas';

  tipo: TipoArquivo = {
    NomeArquivo: '',
    DescricaoArquivo: '',
    ChaveUsuarioUpdate: ''
  };


  displayedColumns = ['nome', 'descricao', 'dtCadastro', 'acoes'];

  displayedColumnsSchemas = ['versao', 'documentacao', 'tipoArquivo', 'dtCadastro', 'acoes'];
  dataSource: MatTableDataSource<TipoArquivo> | null;
  dataSourceSchema: MatTableDataSource<Schema>;

  arquivos: TipoArquivo[];
  schemas: Schema[];
  carregado = false;
  carregadoSchema = false;
  modalOptions: NgbModalOptions;
  closeResult: string;
  modalReference: NgbModalRef;

  constructor(
    private tipoArquivoService: TipoArquivoService,
    private schemaService: SchemaService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
    }
  }


  ngOnInit(): void {
    window.document.title = this.title
    this.getSchemas()

  }

  filtrar(filtro: string) {
    this.dataSource.filter = filtro.trim().toLocaleLowerCase()

  }

  filtrarSchema(filtroSchema: string) {
    this.dataSourceSchema.filter = filtroSchema.trim().toLocaleLowerCase()
  }

  async getSchemas() {
    try {
      const response = await this.schemaService.GetSchemas();
      if (response.data != null) {
        this.schemas = response.data
        this.dataSourceSchema = new MatTableDataSource<Schema>(this.schemas);
        setTimeout(() => {
          this.carregadoSchema = true;
        }, 100);
      }
      else {
        this.carregadoSchema = true;
      }
    } catch (error) {
      this.carregadoSchema = true;
      console.error(error);
    }
  }

  async getTipoArquivos() {
    try {
      const response = await this.tipoArquivoService.GetTiposArquivos();
      if (response.data != null) {
        this.arquivos = response.data;
        this.dataSource = new MatTableDataSource<TipoArquivo>(this.arquivos);
        setTimeout(() => {
          this.carregado = true;
        }, 100);

      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  async Atualizar() {

    this.carregado = false;

    const loginData = JSON.parse(localStorage.getItem('loginData'))
    this.tipo.ChaveUsuarioUpdate = loginData.chave


    await this.tipoArquivoService.AtualizarTipoArquivo(this.tipo).then(response => {
      if (response.status == 200) {
        this.carregado = true;
        this.modalReference.close();
        this.snackBar.open("Tipo atualizado com sucesso.", "OK", { duration: 5000, panelClass: ['sucesso'] });
        this.getTipoArquivos()
      }
      else {
        this.modalReference.close();
        this.carregado = true;
        this.snackBar.open("Erro ao atualizar.", "OK", { duration: 5000, panelClass: ['erro'] });
      }

    }).catch(error => {
      this.modalReference.close();
      this.carregado = true;
      this.snackBar.open("Erro ao atualizar.", "OK", { duration: 3000, panelClass: ['erro'] })
      console.log(error);
    })
  }

  async removerSchema(idSchema) {
    this.carregado = false;
    await this.schemaService.RemoverSchema(idSchema).then(response => {
      if (response.data != null) {
        this.carregado = true;
        this.snackBar.open("Registro removido com sucesso.", "OK", { duration: 4000, panelClass: ['sucesso'] });
        this.getSchemas();
      }
      else {
        this.carregado = true;
        this.snackBar.open("Erro ao remover.", "OK", { duration: 4000, panelClass: ['erro'] });
      }

    }).catch(error => {
      this.carregado = true;
      this.snackBar.open("Erro ao remover. ", "OK", { duration: 4000, panelClass: ['erro'] })
      console.error(error);
    })
  }

  open(content, element) {
    this.tipo.NomeArquivo = element.nomeArquivo
    this.tipo.DescricaoArquivo = element.descricaoArquivo
    this.tipo.IdTipoArquivo = element.idTipoArquivo
    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  tabClick(tab) {
    if (tab.index == 1) {
      this.getTipoArquivos()
      this.primeiroAcessoTabTipoArquivo = false
    }
  }
}
