<div class="content">
  <br>
  <button mat-raised-button class="btn btn-success" style="margin-left: 26px; margin-top: 5px; margin-right: -5px;"
    [matMenuTriggerFor]="menu">
    Criar
    <i class="material-icons" style="top: 5px;">
      arrow_drop_down
    </i>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item style="outline: none" (click)="open('add')">
      <mat-icon style="color: #4242dd; font-size: 30px; margin-top: 3px;">create_new_folder</mat-icon>
      <span style="margin-left: -2px !important; vertical-align:super">Intervenção</span>
    </button>
  </mat-menu>
  <button mat-raised-button class="btn btn-primary hiddenElement" id="btnOpcoes"
    style="margin-left: 26px; margin-top: 5px;" [matMenuTriggerFor]="menuEdit">
    Opções
    <i class="material-icons" style="top: 5px;">arrow_drop_down</i>
  </button>
  <mat-menu #menuEdit="matMenu">
    <button mat-menu-item style="outline: none" (click)="open('update')">
      <mat-icon style="color: #4242dd; font-size: 28px; margin-top: 5px; padding: 0;">edit</mat-icon>
      <span style="margin-left: -2px !important; line-height: 30px; vertical-align:super">Editar</span>
    </button>
    <hr>
    <button mat-menu-item style="outline: none" (click)="alertaModalRemocaoIntervention(mymodalRemoveIntervention)">
      <mat-icon style="color: #f02020; font-size: 28px; margin-top: 5px; padding: 0;">delete</mat-icon>
      <span style="margin-left: -2px !important; line-height: 30px; vertical-align:super">Remover</span>
    </button>
  </mat-menu>
  <hr>

  <form class="p-2">
    <span style="color: rgb(23, 112, 163); font-size: 12px; margin-left: 20px;">
      <a class="fontePetrobras" (click)="GetCampos()" style=" white-space: nowrap; color: #4242dd; cursor: pointer;"
        mat-mat-icon-button title="Ver campos">
        {{nomeUnidade}}
      </a>
      <a class="fontePetrobras" *ngIf="isVisiblePocos" (click)="GetPocos()"
        style=" white-space: nowrap; color: #4242dd; cursor: pointer;" mat-mat-icon-button title="Ver poços">
        / {{nomeCampo}}
      </a>
      <span *ngIf="isVisibleIntervencoes">
        <a class="fontePetrobras" (click)="CarregarPoco(nomeCampo, urlCampo)"
          style=" white-space: nowrap; color: #4242dd; cursor: pointer;" mat-mat-icon-button title="Ver poços">
          / {{nomeCampo}}
        </a>
        <a class="fontePetrobras" style=" white-space: nowrap; color: #4242dd;" mat-mat-icon-button>
          / {{nomePoco}}
        </a>
      </span>
    </span>
    <br>

    <div *ngIf="isVisibleCampos">

      <div class="card">

        <div class="card-body" *ngIf="campos != null">
          <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
            [show]="!carregado">
          </ngx-loading>

          <ngx-skeleton-loader [count]="campos.length" *ngIf="!carregado" appearance="circle">
          </ngx-skeleton-loader>
          <div *ngIf="carregado">

            <div class="fixarConteudoArvore">
              <div class="row">

                <div class="col-sm-1 col-md-1 col-lg-1" *ngFor="let campo of campos">
                  <div (click)="CarregarPoco(campo.name,campo.url)" title="Ver poços" style="cursor: pointer;">
                    <div style="width: 100%; text-align: center;">
                      <mat-icon style="color: rgb(36, 98, 168); font-size: 50px; ">
                        <span class="material-icons-outlined">
                          folder_open
                        </span>
                      </mat-icon>
                    </div>
                    <div style="width: 100%; text-align: center;">
                      <a class="fontePetrobras itemEstrutura" mat-mat-icon-button>
                        {{campo.name}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isVisiblePocos">

      <div class="card">
        <div class="card-body" *ngIf="pocos != null">
          <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
            [show]="!carregado">
          </ngx-loading>

          <ngx-skeleton-loader [count]="pocos.length" *ngIf="!carregado" appearance="circle">
          </ngx-skeleton-loader>
          <div *ngIf="carregado">

            <div class="fixarConteudoArvore">
              <div class="row">

                <div class="col-sm-1 col-md-1 col-lg-1" *ngFor="let poco of pocos">
                  <div (click)="CarregarInterventions(poco.url, poco.name)" title="Ver intervenções"
                    style="cursor: pointer;">
                    <div style="width: 100%; text-align: center;">
                      <mat-icon style="color: rgb(36, 98, 168); font-size: 50px;">
                        <span class="material-icons-outlined">
                          folder_open
                        </span>
                      </mat-icon>
                    </div>
                    <div style="width: 100%; text-align: center;">
                      <a class="fontePetrobras itemEstrutura" mat-mat-icon-button>
                        {{poco.name}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isVisibleIntervencoes">

      <div class="card">
        <div class="card-body" *ngIf="intervencoes != null">
          <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
            [show]="!carregado">
          </ngx-loading>
          <ngx-skeleton-loader [count]="intervencoes.length" *ngIf="!carregado" appearance="circle">
          </ngx-skeleton-loader>
          <div *ngIf="carregado">
            <div class="fixarConteudoArvore">
              <div class="row">
                <div class="col-sm-1 col-md-1 col-lg-1" *ngFor="let inter of intervencoes">
                  <div (click)="selectItem(inter.codigo)" (dblclick)="mensagemIntervencao(inter.codigo)"
                    [id]="inter.codigo" title="Detalhes" style="cursor: default;">
                    <div style="width: 100%; text-align: center;">
                      <mat-icon style="color: rgb(36, 98, 168); font-size: 50px;">
                        <span class="material-icons-outlined">
                          folder_open
                        </span>
                      </mat-icon>
                    </div>
                    <div style="width: 100%; text-align: center;">
                      <a class="fontePetrobras itemEstrutura" mat-mat-icon-button>
                        {{inter.name}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body textoNoCentro" *ngIf="intervencoes == null">
          <p style="color: red; font-size: 16px;">Este poço não possui intervenções.</p>
        </div>
      </div>
    </div>
  </form>
</div>


<!-- Modal Remove intervencao -->
<ng-template #mymodalRemoveIntervention let-modal>
  <div class="modal-header">
    <h4 *ngIf="!permissaoExclusao" class="modal-title" id="modal-basic-title" style="width: 100%; text-align: center;">
      Exclusão não permitida!</h4>
    <h4 *ngIf="permissaoExclusao" class="modal-title" id="modal-basic-title" style="width: 100%; text-align: center;">
      Confirmação</h4>
    <button type="button" style="outline: none" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngIf="!permissaoExclusao">
      <label style="margin-left: 31px;">Esta intervenção possui <b style="color: red;">publicações e/ou anexos
          vinculados</b>, remova-os e tente novamente.</label>
    </div>
    <div class="row" *ngIf="permissaoExclusao">
      <div class="col-md-10">
        <label>Digite <b style="color: red;">REMOVER</b>, para confirmar a exclusão.</label>
        <input autofocus (keyup.enter)="modal.dismiss('Cross click'); removerIntervencao()" [(ngModel)]="habilitaRemocao" style="text-transform: uppercase;"
          type="text" class="form-control">
      </div>
    </div>


  </div>
  <div class="modal-footer">
    <button type="button" *ngIf="!permissaoExclusao" class="btn btn-primary"
      (click)="modal.dismiss('Cross click')">Fechar</button>

    <button type="submit" [disabled]="!desabilitaCampos()" *ngIf="permissaoExclusao" class="btn btn-primary"
      (click)="modal.dismiss('Cross click'); removerIntervencao();">OK</button>

  </div>
</ng-template>
<!-- FIM Modal Remove intervencao -->
