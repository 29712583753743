<div class="content">
<form class="p-3" ngNativeValidate>
  <div class="card">
    <div class="card-header textoNoCentro">
      CONTROLE DE UNIDADES OFFSHORE
    </div>
    <div class="card-body">
      <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
        [show]="!carregado">
      </ngx-loading>
      <div class="row">
        <div class="col-md-12" *ngIf="unidades != null">
          <div>
            <div class="fixarConteudoAplicaoes">
              <mat-selection-list #un
                class="table">
                <mat-list-option [value]="un.codigo" [selected]="un.visivel" (click)="insertOrUpdate(un)"
                  style="display:inline-block; width:60%;" checkboxPosition="before" [color]="cor"
                  *ngFor="let un of unidades">
                  {{un.nome}}
                </mat-list-option>
              </mat-selection-list>
              <p *ngIf="unidades != null">
                Item(ns) selecionado(s): {{un.selectedOptions.selected.length}}
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <br>
  </div>
</form>
</div>
