<div class="content" >
  <form class="p-3">
    <div class="card">
      <div class="card-header textoNoCentro">
        ATUALIZAR SCHEMA
      </div>
      <div class="card-body" *ngIf="schema != null" >
        <div class="row">
          <div class="col-md-6">
            <b><span>Campos com ( <span style="color: red;">*</span> ) são obrigatórios</span></b>
          </div>
        </div>
        <br>

        <div class="row">
          <div class="col-md-6">
            <mat-label>Tipo de Arquivo*</mat-label>

              <ng-select  (clear)="limparTipo()" style="min-width: 250px;" [items]="arquivos"
              bindLabel="nomeArquivo" bindValue="idTipoArquivo" placeholder="Selecionar Tipo de arquivo" appendTo="body"
                [(ngModel)]="schema.idTipoArquivo" name="nomeArquivo">
              </ng-select>

          </div>

          <div class="col-md-6">
            <mat-label>Versão*</mat-label>
            <input mat-input type="text" maxlength="32" name="Versao" class="form-control" [(ngModel)]="schema.versao"
              placeholder="Digite a Versão">
              <mat-hint>Máximo de 32 caracteres</mat-hint>
          </div>

        </div>

        <div class="row">


        </div>
        <br>
        <div class="row">
          <div class="col-md-6">
            <label>Documentação*</label>
            <textarea rows="7" type="text" maxlength="255" name="Documentacao" class="form-control"
              [(ngModel)]="schema.documentacao" placeholder="Digite a Documentação">
          </textarea>
          <mat-hint>Máximo de 255 caracteres</mat-hint>
          </div>
          <div class="col-md-6">
            <label>Schema( em Json)*</label>
            <textarea rows="7" type="text" name="SchemaJson" class="form-control"
              [(ngModel)]="schema.schemaJson" placeholder="Digite o Schema( em Json)">
          </textarea>
          </div>
        </div>

        <br>

        <br>
        <button class="btn btn-default" style="border: 1px solid black;" type="submit" (click)="voltarPagina()"> Cancelar</button>
        &nbsp;&nbsp;&nbsp;
        <button class="btn btn-success" (click)="AtualizarSchema()" [disabled]="!desabilitaCampos()" type="submit">
          Atualizar
        </button>

      </div>

      <div class="card-body" *ngIf="schema == null">
        <span>Registro não encontrado ou já foi removido!</span>
      </div>
    </div>
  </form>

</div>
