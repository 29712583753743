<div class="content">
  <form class="p-3">
    <div class="card">
      <div class="card-header textoNoCentro">
        CADASTRAR CLIENT
      </div>
      <div class="card-body">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>
        <div class="row">
          <div class="col-md-6">
            <b><span>Campos com ( <span style="color: red;">*</span> ) são obrigatórios</span></b>
          </div>
        </div>
        <br><br>

        <div class="row">
          <div class="col-md-3">
            <mat-label style="font-weight: bold;">Sonda*</mat-label>
            <ng-select notFoundText="Nenhum registro encontrado!" (clear)="limparSelecaoSonda()"
              (change)="PreencheTopic($event)" [(ngModel)]="clients.sondaId" style="min-width: 250px;" [items]="sondas"
              bindLabel="unin_Sg_Unid_Int" bindValue="unin_Cd_Unid_Int" [placeholder]="'Digite ou selecione'"
              appendTo="body" name="unin_Sg_Unid_Int">
            </ng-select>
          </div>

          <div class="col-md-3">

            <mat-label style="font-weight: bold;">Aplicação*</mat-label>
            <ng-select notFoundText="Nenhum registro encontrado!" (clear)="limparSelecaoAplicacao()"
              (change)="PreencheTopic($event)" [(ngModel)]="clients.aplicacaoId" style="min-width: 250px;"
              [items]="aplicacoes" bindLabel="nomeAplicacaoAmigavel" bindValue="idAplicacao"
              [placeholder]="'Digite ou selecione'" appendTo="body" name="idAplicacao">
            </ng-select>
          </div>

          <div class="col-md-3" style="top: -2px;">
            <mat-label style="font-weight: bold;">Topic (sugestão)*</mat-label>
            <div class="input-group-append">
              <input required type="text" style="text-transform: lowercase;" name="topic" class="form-control" [(ngModel)]="clients.topicoMQTT">
              &nbsp;&nbsp;
              <button class="btn btn-primary" [disabled]="!validaCampos()" (click)="adicionarRegistros()"
                type="submit">Add</button>
            </div>
          </div>

        </div>

        <br>
        <div class="row" *ngIf="listaAplicacaoesAdicionadas.length > 0">
          <div class="col-md-9">

            <mat-label style="font-weight: bold;">Lista de Aplicações</mat-label>
            <br><br>

            <div class="mat-elevation-z3">
              <div class="fixarConteudoAplicaoes">
                <mat-table class="table"  #table [dataSource]="dataSource">

                  <ng-container matColumnDef="sonda">
                    <mat-header-cell *matHeaderCellDef> Sonda </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.nomeSonda}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="aplicacao">
                    <mat-header-cell *matHeaderCellDef> Aplicação </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.nomeAplicacao}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="topic">
                    <mat-header-cell *matHeaderCellDef> Topic </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.topicoMQTT}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="acoes">
                    <mat-header-cell *matHeaderCellDef>Remover</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      &nbsp;&nbsp;&nbsp;
                      <a title="Clique para remover" class="delete" style="cursor: pointer;"
                        (click)="removerItemDaLista(element.aplicacaoId, element.sondaId)">
                        <i class="material-icons">delete_forever</i>
                      </a>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row class="mat-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
        <br>

        <div style="float: left; border-top: none;">
          <div>
            <button type="submit" [disabled]="!desabilitaCampos()" (click)="PostData()" class="btn btn-primary">Salvar</button>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>
