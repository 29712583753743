import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AplicacaoService } from 'src/app/services/aplicacao.service';
import { Usuario } from '../../../model/usuario'
import { UsuarioService } from '../../../services/usuario.service';
@Component({
  selector: 'app-atualizar-usuario',
  templateUrl: './atualizar-usuario.component.html',
  styleUrls: ['../../../app.component.css']
})
export class AtualizarUsuarioComponent implements OnInit {
  title = 'PWO - Controle de Usuários';
  usuario: Usuario
  carregado = false
  aplica: any[]
  listaAplicacoes: any[]

  listaAplicacoesSelecionadas: any;
  cor: string = "primary"

  constructor(private snackBar: MatSnackBar,
    private usuarioService: UsuarioService,
    private aplicacaoService: AplicacaoService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    window.document.title = this.title;
    this.getUsuario()
    this.getAplicacoes()


  }

  async updateUsuario() {

    if (this.listaAplicacoesSelecionadas === undefined || this.listaAplicacoesSelecionadas.length == 0) {
      this.snackBar.open("Selecione uma aplicação.", "OK", { duration: 3000, panelClass:['alerta'] });
      return
    }

    this.carregado = false;

    const loginData = JSON.parse(localStorage.getItem('loginData'))
    this.usuario.ChaveUsuarioLogin = loginData.chave
    this.usuario.IdAplicacao = this.listaAplicacoesSelecionadas
    await this.usuarioService.AtualizarUsuarioAplicacao(this.usuario).then(response => {
      if (response.data != null) {
        setTimeout(() => {
          this.carregado = true;
        }, 1000);
        this.snackBar.open("Ação concluida.", "OK", { duration: 5000, panelClass: ['sucesso'] });
        this.router.navigate(['administracao/usuario/listar'])
      }
      else {
        this.carregado = true;
        this.snackBar.open("Erro ao atualizar usuario.", "OK", { duration: 5000, panelClass: ['erro'] });
      }

    }).catch(error => {
      this.carregado = true;
      this.snackBar.open("Erro ao atualizar usuario.", "OK", { duration: 3000, panelClass: ['erro'] })
    })
  }

  async getAplicacoes() {
    try {
      const response = await this.aplicacaoService.GetAplicacoes();
      if (response.data != null) {
        this.listaAplicacoes = response.data
        setTimeout(() => {
          this.carregado = true;
        }, 1000);
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.log(error);
    }
  }

  selecionaCheckBox(evt){
  var aplicacoesUsuario = this.aplica.map(apl => apl.id);

  return aplicacoesUsuario.includes(evt);
  }

  async getUsuario() {
    try {
      const id = this.route.snapshot.paramMap.get('id')
      const response = await this.usuarioService.GetUsuarioAplicacao(id)
      if (response.data != '') {
        this.usuario = response.data

        this.aplica = this.usuario.aplicacao
        setTimeout(() => {
          this.carregado = true;
        }, 1000);
      }
      else {
        this.usuario = null
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.log(error);
    }
  }

  voltarPagina(): void {
    this.router.navigate(['/administracao/usuario/listar'])
  }

}
