import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { unidadeOffShore } from 'src/app/model/unidadeOffShore';
import { UnidadeOffShoreService } from 'src/app/services/unidadeOffShore.service';

@Component({
  selector: 'app-offshore',
  templateUrl: './offshore.component.html',
  styleUrls: ['./offshore.component.css', '../../app.component.css']
})
export class OffshoreComponent implements OnInit {

  unidades: unidadeOffShore[];
  unidadeAlteracao: unidadeOffShore = {
    Codigo: '',
    Nome: '',
    Visivel: null
  }

  unidadeSelecionada: any

  cor: string = "primary"
  carregado = false;

  title = 'PWO - Controle de Unidades OffShore';


  constructor(
    private snackBar: MatSnackBar,
    private unidadeOffShoreService: UnidadeOffShoreService
  ) { }

  ngOnInit(): void {
    window.document.title = this.title
    this.getUnidades()
  }

  async getUnidades() {
    try {
      await this.unidadeOffShoreService.GetAll().then(res => {
        if (res.data != null) {
          this.unidades = res.data;
          setTimeout(() => {
            this.carregado = true;
          }, 100);
        }
        else {
          this.carregado = true;
        }
      });
    } catch (error) {
      this.carregado = true;
      console.log(error);
    }
  }

  async insertOrUpdate(unin) {

    this.unidadeAlteracao.Codigo = unin.codigo;
    this.unidadeAlteracao.Nome = unin.nome;
    this.unidadeAlteracao.Visivel = unin.visivel ? false : true

    try {
      if (unin.codigo == "") {
        await this.unidadeOffShoreService.Post(this.unidadeAlteracao).then(res => {
          if (res.status == 201) {
            this.snackBar.open("Ação concluida.", "OK", { duration: 2000, panelClass: ['sucesso'] });
          }
          else {
            this.snackBar.open("Erro ao atualizar registro.", "OK", { duration: 2000, panelClass: ['sucesso'] });
          }
        })
      }
      else {
        await this.unidadeOffShoreService.Put(this.unidadeAlteracao).then(res => {
          if (res.status == 202) {
            this.snackBar.open("Ação concluida.", "OK", { duration: 2000, panelClass: ['sucesso'] });
          }
          else {
            this.snackBar.open("Erro ao atualizar registro.", "OK", { duration: 2000, panelClass: ['sucesso'] });
          }
        })
      }
    } catch (error) {
      this.snackBar.open("Erro ao atualizar registro.", "OK", { duration: 2000, panelClass: ['sucesso'] });
      console.log(error)
    }

  }
}
