import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuPocoService } from 'src/app/services/MenuPoco.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css', '../../app.component.css']
})
export class IndexComponent implements OnInit {

  carregado = false
  unidades: any[]

  constructor(private menuService: MenuPocoService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.GetUnidades()
  }

  CarregarCampos(OpunitName, OpunitUrl) {

    localStorage.removeItem("UnidadeSelecionada")
    localStorage.removeItem("UrlUnidadeSelecionada")
    localStorage.removeItem("CampoSelecionado")
    localStorage.removeItem("UrlCampoSelecionado")
    localStorage.removeItem("PocoSelecionado")
    localStorage.removeItem("UrlPocoSelecionado")
    localStorage.removeItem("intervencaoSelecionada")


    let urlDestino = '/intervencao/tree-detail/'

    var urlUnidade = OpunitUrl.split(/[\s/]+/);

    localStorage.setItem('UnidadeSelecionada', JSON.stringify(OpunitName));
    localStorage.setItem('UrlUnidadeSelecionada', JSON.stringify(urlUnidade[urlUnidade.length-1]));

    this.router.navigateByUrl('/intervencao/index', { skipLocationChange: true }).then(() => {
      this.router.navigate([urlDestino]);
    });
  }

  async GetUnidades() {
    try {
      this.carregado = false;
      const response = await this.menuService.GetUnidades();
      if (response.data != null) {
        this.unidades = response.data

        if (this.unidades.length == 0) {
          this.unidades = null
        }
        setTimeout(() => {
          this.carregado = true
        }, 300);
      }
    }
    catch (error) {
      this.carregado = true
      console.error(error);
    }
  }

}
