import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sonda } from 'src/app/model/sondaModel';
import { SondaService } from 'src/app/services/sonda.service';

@Component({
	selector: 'sonda-detail',
	templateUrl: './sonda-detail.component.html',
	styleUrls: ['./sonda-detail.component.css', '../../app.component.css']
})
export class SondaDetailComponent implements OnInit {
	carregado:boolean = false;

	constructor(private sondaService: SondaService,
		private message: MatSnackBar,
		public dialogRef: MatDialogRef<SondaDetailComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Sonda) { }

	ngOnInit(): void {
		this.carregado = true;
	}

	onSaveClick = () => {
		var update:boolean = true;

		if(this.data.nome === null || this.data.nome === undefined || this.data.nome === "")
		{
			this.message.open("O campo nome é obrigatório", "OK", {duration:3000, panelClass:['erro']});
			return;
		}

		if(this.data.codigo !== undefined){
			this.sondaService.putSonda(this.data)
			.then(result => {
				this.message.open("Sonda alterada com sucesso", "OK", {duration:3000, panelClass:['sucesso']});
			}).catch(error => {
				this.message.open("Falha ao alterar sonda " + error.response.data, "OK", {duration:3000, panelClass:['erro']});
				update = false;
			});
		}else{
			this.sondaService.addSonda(this.data)
				.then(result => {
					this.message.open("Sonda gravada com sucesso", "OK", {duration:3000, panelClass:['sucesso']});
				}).catch(error => {
					this.message.open("Falha ao inserir sonda " + error.response.data, "OK", {duration:3000, panelClass:['erro']});
					update = false;
				});
		}

		this.dialogRef.close({update: update});
	}

	onCancelClick() : void{
		this.dialogRef.close({update: false});
	}
}
