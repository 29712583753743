import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Schema } from 'src/app/model/schema';
import { TipoArquivo } from 'src/app/model/tipoArquivo';
import { SchemaService } from 'src/app/services/schema.service';
import { TipoArquivoService } from 'src/app/services/tipoArquivo.service';

@Component({
  selector: 'app-cadastrar-schema',
  templateUrl: './cadastrar-schema.component.html',
  styleUrls: ['../../../app.component.css']
})
export class CadastrarSchemaComponent implements OnInit {
  title = 'PWO - Controle de Schemas';
  idTipoCadastrado = null;
  arquivos: any[];
  idTipoArquivoSelecionado = null
  schema: Schema = {
    IdTipoArquivo: '',
    Versao: '',
    Documentacao: '',
    SchemaJson: '',
    Autor: '' //chave do usuario logado
  };


  tipoArquivoInsert: TipoArquivo = {
    NomeArquivo: '',
    DescricaoArquivo: ''
  };

  modalOptions: NgbModalOptions;
  closeResult: string;
  modalReference: NgbModalRef;
  carregado = false;
  constructor(
    private snackBar: MatSnackBar,
    private tipoArquivoService: TipoArquivoService,
    private schemaService: SchemaService,
    private modalService: NgbModal,
    private router: Router) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
    }
  }

  ngOnInit(): void {
    window.document.title = this.title;
    this.getTipoArquivos()
  }

  desabilitaCampos() {
    if (this.schema.IdTipoArquivo === '' || this.schema.Versao === '' || this.schema.Documentacao === '' || this.schema.SchemaJson === '') {
      return false
    }
    return true
  }

  limparTipo() {
    this.schema.IdTipoArquivo = ''
  }

  async getTipoArquivos() {
    try {
      const response = await this.tipoArquivoService.GetTiposArquivos();
      if (response.data != null) {
        this.arquivos = response.data;

        //ORDENA POR DATA DE CADASTRO DESC

        // this.arquivos.sort(function (a, b) {
        //   var data1 = a.geradoEm, data2 = b.geradoEm;
        //   if (data1 > data2) return -1;
        //   if (data1 < data2) return 1;
        //   return 0;
        // });

        this.carregado = true;
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  voltarPagina() {
    this.router.navigate(['/administracao/schemas/listar'])

  }

  open(content) {
    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async InserirTipoArquivo() {

    this.carregado = false;
    //this.idTipoCadastrado = null
    const loginData = JSON.parse(localStorage.getItem('loginData'))
    this.tipoArquivoInsert.ChaveUsuarioCadastro = loginData.chave
    //this.idTipoCadastrado = this.tipoArquivoInsert.NomeArquivo

    await this.tipoArquivoService.Inserir(this.tipoArquivoInsert).then(response => {
      if (response.data != null) {
        this.modalReference.close();
        this.carregado = true;

        this.snackBar.open("Ação concluida.", "OK", { duration: 20000, panelClass: ['sucesso'] });
        this.getTipoArquivos()
        this.tipoArquivoInsert.NomeArquivo = ''
        this.tipoArquivoInsert.DescricaoArquivo = ''
      }
      else {
        this.modalReference.close();
        this.carregado = true;
        this.snackBar.open("Erro ao adicionar tipo de arquivo.", "OK", { duration: 5000, panelClass: ['erro'] });
      }

    }).catch(error => {
      this.modalReference.close();
      this.carregado = true;
      this.snackBar.open("Erro ao adicionar tipo de arquivo.", "OK", { duration: 3000, panelClass: ['erro'] })
      console.error(error);
    })
  }

  async InserirSchema() {
    this.carregado = false;
    const loginData = JSON.parse(localStorage.getItem('loginData'))
    this.schema.Autor = loginData.chave
    this.schema.IdTipoArquivo = this.idTipoArquivoSelecionado
    this.modalReference = null
    await this.schemaService.Inserir(this.schema).then(response => {
      if (response.status == 201) {
        this.carregado = true;
        this.snackBar.open("Schema cadastrado com sucesso.", "OK", { duration: 5000, panelClass: ['sucesso'] });
        this.router.navigate(['/administracao/schemas/listar'])
        this.tipoArquivoInsert.NomeArquivo = ''
        this.tipoArquivoInsert.DescricaoArquivo = ''
      }
      else {
        this.carregado = true;
        this.snackBar.open("Erro ao cadastrar schema.", "OK", { duration: 5000, panelClass: ['erro'] });
      }

    }).catch(error => {
      this.carregado = true;
      this.snackBar.open("Erro ao cadastrar schema.", "OK", { duration: 3000, panelClass: ['erro'] })
      console.error(error);
    })
  }

  VerificaTipoJaCadastrado() {
    var tipoCadastrado = this.arquivos.find(arq => arq.nomeArquivo == this.tipoArquivoInsert.NomeArquivo.toUpperCase())
    if (tipoCadastrado != null) {
      this.modalReference.close();
      this.snackBar.open("Tipo ja cadastrado. Favor verificar", "OK", { duration: 5000, panelClass: ['alerta'] });
      this.tipoArquivoInsert.NomeArquivo = ''
      this.tipoArquivoInsert.DescricaoArquivo = ''
    }
  }

  getValueIdArquivo(evt) {
    if (evt != undefined) {
      this.idTipoArquivoSelecionado = evt.idTipoArquivo
    }

  }
}
