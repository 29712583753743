import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

// import { AuthenticationService } from '../services/authentication.service'
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoginService } from "../services/login.service";
import { LoginData } from "../model/login-data";
import { Subject } from "rxjs";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
	loginData: LoginData;
	loginForm: FormGroup;
	loading = false;
	loginError = false;
	submitted = false;
	returnUrl: string;

	dadosLogin: any = {
		chave: "",
		senha: "", //chave do usuario logado
	};

	title = "PWO - Login";

	constructor(
		private snackBar: MatSnackBar,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private loginMutation: LoginService
	) {}

	ngOnInit() {
		window.document.title = this.title;

		this.loginForm = this.formBuilder.group({
			username: ["", Validators.required],
			password: ["", Validators.required],
		});

		// get return url from route parameters or default to '/'
		this.returnUrl =
			this.route.snapshot.queryParams.ReturnUrl || "AcessoAplicacao";
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.loginForm.controls;
	}

	async onSubmit() {
		this.submitted = true;

		var loginSuccess: Subject<boolean> = new Subject<boolean>();

		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}

		this.loading = true;

		var usu = this.f.username.value;
		var senha = this.f.password.value;

		this.dadosLogin.chave = usu;
		this.dadosLogin.senha = senha;

		try {
			var result = await this.loginMutation.login(this.dadosLogin);

			if (result.data != "") {
				this.loginData = result.data;
				this.setSession();
				loginSuccess.next(true);
				loginSuccess.complete();

				this.loading = false;
				window.location.href = this.returnUrl;
			} else {
				this.loginError = true;
				this.loading = false;
				this.snackBar.open(
					"Erro na autenticação. Usuário não encontrado ou inativo!",
					"FECHAR",
					{ duration: 10000, panelClass: ["erro"] }
				);
			}
		} catch (error) {
			this.loading = false;
			this.loginError = true;
		}
	}

	private setSession() {
		localStorage.setItem("loginData", JSON.stringify(this.loginData));
	}
}
