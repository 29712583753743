<div class="content">
  <form class="p-3">
    <div class="card">
      <div class="card-header textoNoCentro">
        CADASTRAR ESQUEMA
      </div>
      <div class="card-body">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>

        <div class="row">
          <div class="col-md-6">
            <b><span>Campos com ( <span style="color: red;">*</span> ) são obrigatórios</span></b>
          </div>
        </div>
        <br>

        <div class="row">
          <div class="col-md-6">
            <mat-label> Selecionar Tipo de arquivo*</mat-label>
            <div class="input-group mb-6">

              <br>
              <ng-select  (clear)="limparTipo()" style="min-width: 250px;" [items]="arquivos"
              bindLabel="nomeArquivo" (change)="getValueIdArquivo($event)" placeholder="Selecionar Tipo de arquivo" appendTo="body"
                [(ngModel)]="schema.IdTipoArquivo" name="IdTipoArquivo">
              </ng-select>

              <div class="input-group-append">
                <a style="cursor: pointer;" title="Adicionar novo" (click)="open(mymodal)" class="adicionar">
                  <i style="color: green; font-size: 30px;" class="material-icons adicionar">add</i>
                </a>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <mat-label>Versão*</mat-label>
            <input mat-input type="text" maxlength="32" name="Versao" class="form-control" [(ngModel)]="schema.Versao"
              placeholder="Digite a Versão">
            <mat-hint>Máximo de 32 caracteres</mat-hint>
          </div>

        </div>

        <div class="row">


        </div>
        <br>
        <div class="row">
          <div class="col-md-6">
            <label>Documentação*</label>
            <textarea rows="7" type="text" maxlength="255" name="Documentacao" class="form-control"
              [(ngModel)]="schema.Documentacao" placeholder="Digite a Documentação">
          </textarea>
            <mat-hint>Máximo de 255 caracteres</mat-hint>
          </div>
          <div class="col-md-6">
            <label>Schema( em Json)*</label>
            <textarea rows="7" type="text" name="SchemaJson" class="form-control" [(ngModel)]="schema.SchemaJson"
              placeholder="Digite o Schema( em Json)">
          </textarea>
          </div>
        </div>

        <br>

        <br>
        <button class="btn btn-default" style="border: 1px solid black;" type="submit" (click)="voltarPagina()">
          Cancelar</button>
        &nbsp;&nbsp;&nbsp;
        <button class="btn btn-success" (click)="InserirSchema()" [disabled]="!desabilitaCampos()" type="submit">
          Salvar
        </button>

      </div>
    </div>
  </form>

  <!-- Modal Update TipoArquivo -->
  <ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Adicionar Tipo de Arquivo</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" *ngIf="tipoArquivoInsert != null">
      <div class="row">
        <div class="col-md-11 col-lg-11">
          <label>Nome</label>
          <input style="text-transform: uppercase;" type="text" (blur)="VerificaTipoJaCadastrado()" maxlength="32"
            name="NomeArquivo" class="form-control" [(ngModel)]="tipoArquivoInsert.NomeArquivo">
          <mat-hint>Máximo de 32 caracteres</mat-hint>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-11 col-lg-11">
          <label>Descrição*</label>
          <textarea maxlength="255" type="text" name="DescricaoArquivo" class="form-control"
            [(ngModel)]="tipoArquivoInsert.DescricaoArquivo"></textarea>
          <mat-hint>Máximo de 255 caracteres</mat-hint>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" [disabled]="tipoArquivoInsert.DescricaoArquivo == '' || tipoArquivoInsert.NomeArquivo == ''"
        class="btn btn-success" (click)="InserirTipoArquivo()">Cadastrar</button>

    </div>
  </ng-template>
  <!-- FIM Modal Update TipoArquivo -->

</div>
