import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // title = 'Físico e Financeiro de Poços';
  showSwitcher: boolean;
  url: string;

  constructor(private router: Router){
	this.router.events.subscribe(event => {
		if (event instanceof NavigationEnd) {
		  // event is an instance of NavigationEnd, get url!
		  this.url = event.urlAfterRedirects;
		  if (this.url.indexOf('intervencao') > 0 || this.url.indexOf('painel') > 0) {
			this.showSwitcher = true
		  }
		  else {
			this.showSwitcher = false
		  }
		}
	  })
  }
}
