import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoginData } from '../../../model/login-data';
import { AuthenticationService } from '../../../services/authentication.service';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  loginData: LoginData;
  url: string
  verMenu: boolean;
  showSwitcher: boolean;
  isShowing: boolean;

  constructor(private authService: AuthenticationService,
    private router: Router) {
    this.loginData = JSON.parse(localStorage.getItem('loginData'))
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!
        this.url = event.urlAfterRedirects;
        if (this.url === '/AcessoAplicacao' ||
         this.url === '/loginPage' ||
          this.url === '/homePage' ||
           this.url === '/docs' ||
            this.url.indexOf('intervencao') >=0 ||
            this.url.indexOf('painel') >= 0 ||
            this.url === '/mqtt') {
          this.verMenu = false
        }
        else {
          this.verMenu = true
        }

		if (this.url.includes('intervencao') || this.url.includes('painel')) {
			this.showSwitcher = true
		  }
		  else {
			this.showSwitcher = false
		  }
      }
    })
  }
  ngOnDestroy() {
    this.verMenu = false
  }

  voltaParaListaDeAplicacoes() {
    this.verMenu = false
    this.router.navigate(['/homePage'])
  }

  toggleSidenav() {
     this.isShowing = !this.isShowing;
  }
  ngOnInit(): void {
  }

  forceLogout(): void {

    this.authService.logout();
    this.verMenu = false
  }

}
