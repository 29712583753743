<div class="content">
  <form class="p-3">
    <div class="card">
      <div class="card-header textoNoCentro">
        LISTA DE USUÁRIOS
      </div>
      <div class="card-body">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>
        <button class="btn btn-success" routerLink="../cadastrar">NOVO</button>
        <br><br><br>



        <div class="row">
          <div class="col-md-6">
            <div class="form-group has-search">
              <span class="form-control-feedback">
                <i class="material-icons">
                  search
                </i>
              </span>
              <input type="text" class="form-control" (keyup)="filtrar($event.target.value)" placeholder="Busca rápida por CHAVE">
            </div>
          </div>
        </div>
        <ngx-skeleton-loader *ngIf="!carregado" appearance="pulse">
        </ngx-skeleton-loader>
        <div class="mat-elevation-z3" *ngIf="carregado">

          <mat-table class="table" #table [dataSource]="dataSource">

            <!-- chave Column -->
            <ng-container matColumnDef="chave">
              <mat-header-cell *matHeaderCellDef> Chave </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.chave}} </mat-cell>
            </ng-container>

            <!-- nome Column -->
            <!-- <ng-container matColumnDef="nome">
              <mat-header-cell *matHeaderCellDef> Nome </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{element.aplicacao.nome}}
              </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="nome">
              <mat-header-cell *matHeaderCellDef >Aplicações que tem acesso</mat-header-cell>
              <mat-cell *matCellDef="let element">
                  <table>
                      <tr *ngFor="let app of element.aplicacao">
                          <td>{{app.nome}}</td>
                      </tr>
                  </table>
              </mat-cell>
          </ng-container>

            <!-- Ações -->
            <ng-container  matColumnDef="acoes">
              <mat-header-cell class="right" *matHeaderCellDef> Ações </mat-header-cell>
              <mat-cell class="right" *matCellDef="let element">
                <a title="Clique para editar" class="edit" routerLink="/administracao/usuario/atualizar/{{element.idUsuarioAplicacao}}">
                  <i class="material-icons edit">edit</i>
                </a>
                &nbsp;&nbsp;&nbsp;
                <a title="Clique para remover" *ngIf="element.chave != chaveUsuarioLogado" class="delete" style="cursor: pointer;"
                  (click)="remover(element.idUsuarioAplicacao)">
                  <i class="material-icons">delete_forever</i>
                </a>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row class="mat-row"  *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <mat-paginator #paginacao
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Itens por página"></mat-paginator>
        </div>
      </div>
    </div>
  </form>
