import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { LoginData } from '../model/login-data';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AplicacaoService } from '../services/aplicacao.service';

@Component({
	selector: 'app-acesso-aplicacao',
	templateUrl: './acesso-aplicacao.component.html',
	styleUrls: ['./acesso-aplicacao.component.css']
})

export class AcessoAplicacaoComponent implements OnInit {

	carregado = false;
	loginData: LoginData
	aplica: any[]
	title = 'PWO - Acesso Aplicações';
	perfilAdm = null
	perfilIntervencao = null
	constructor(
		private acessoaplicacao: UsuarioService,
		private aplicacao: AplicacaoService,
		private router: Router,
		private authService: AuthenticationService,
		public sanitizer: DomSanitizer) { }

	ngOnInit(): void {
		this.loginData = JSON.parse(localStorage.getItem('loginData'))
		if (this.loginData != null) {
			this.getAplicacao(this.loginData.chave)

			var perfilAdministrador = this.loginData.perfis.find(p => p.descricao == 'Administrador')
			if (perfilAdministrador != undefined) {
				this.perfilAdm = perfilAdministrador.id
			}
			else {
				this.perfilAdm = 0
			}

			var perfilInter = this.loginData.perfis.find(p => p.descricao == 'PWO_INTERVENCOES')
			if (perfilInter != undefined) {
				this.perfilIntervencao = perfilInter.id
			}
			else {
				this.perfilIntervencao = 0
			}
		}
		else {
			this.carregado = true;
		}

		window.document.title = this.title;
	}
	async getAplicacao(chave) {
		try {
			const response = await this.acessoaplicacao.GetAcessoAplicacao(chave)
			if (response.data != null) {
				this.aplica = response.data
				setTimeout(() => {
					this.carregado = true
				}, 300);
			}
			else {
				this.carregado = true
				this.aplica = null
			}
		} catch (error) {
			this.carregado = true
			console.error(error);
		}
	}

	administracao() {
		this.router.navigate(['../../administracao/usuario/listar'])
	}

	forceLogout(): void {
		this.authService.logout();
	}

	ExecutarAplicacao(chave, idAplicacao) {
		return this.acessoaplicacao.ExecutarAplicacao(chave, idAplicacao);
	}

	async ContablizarAcesso(app) {
		try {
			await this.aplicacao.ContabilizarAcesso(app).then(res =>{
				window.location.href = app.urlAcesso
			})
		} catch (error) {
			console.log(error)
			window.location.href = app.urlAcesso
		}
	}

	abrirPainel(){
		window.location.href = "/intervencao/index"
	}
}
