import { Injectable } from '@angular/core';
import { http } from "./config.service"

@Injectable({
  providedIn: 'root'
})

export class UnidadeIntervencaoService {

  GetSondas() {
    return http.get('api/public/unidadeIntervencao/GetSondas')
  }

  GetPocos(name) {
    if (name == null) {
      return http.get('api/public/wells/')
    }
    return http.get('api/public/wells/?name=' + name)
  }

  GetPocosPorCampo(name = null) {
    return http.get('api/public/oilfields/?name=' + name)
  }

  GetPocosPorUnidade(name = null) {
    return http.get('api/public/unidadeIntervencao/GetWellsByUN/?name=' + name)
  }

  PostIntervencao(intervencao) {
    return http.post('api/public/interventions/', intervencao)
  }

  PutIntervencao(id, intervencao) {
    return http.put('api/public/interventions/' + id, intervencao)
  }

  GetInterventions() {
    return http.get("api/public/interventions");
  }

  GetIntervention(id) {
    return http.get("api/public/interventions/" + id);
  }

  GetFilesFromIntervention(id) {
    return http.get("api/public/intervention/files/" + id);
  }

  GetAttachmentsFromIntervention(id) {
    return http.get("api/public/intervention/attachments/" + id);
  }

  GetQuantitativoIntervencoesPorTiipo(tipoIntervencao) {
    return http.get("api/public/intervention/tiposIntervencao/" + tipoIntervencao);
  }

  GetImagesAttachments(id) {
    return http.get("api/public/intervention/imagesAttachments/" + id);
  }

  RemoveIntervention(id) {
    return http.delete("api/public/intervention/" + id);
  }

  RemoveFile(id) {
    return http.delete("api/public/file/" + id);
  }

}
