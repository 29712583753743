import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Aplicacao } from 'src/app/model/aplicacao';
import { AplicacaoService } from 'src/app/services/aplicacao.service';
import { Usuario } from '../../../model/usuario'
import { UsuarioService } from '../../../services/usuario.service';

@Component({
  selector: 'app-cadastrar-usuario',
  templateUrl: './cadastrar-usuario.component.html',
  styleUrls: ['../../../app.component.css']
})
export class CadastrarUsuarioComponent implements OnInit {

  aplicacoes: Aplicacao[];
  aplica: any[]
  nomeUsuario = null
  listaAplicacoesSelecionadas: any;
  title = 'PWO - Controle de Usuários';

  usuario: Usuario = {
    ChaveUsuarioLogin: '',
    chave: '',
  };

  user: Usuario = {
    chave: '',
    name:'',
    email:''
  };

  cor: string = "primary"
  carregado = false;

  constructor(private snackBar: MatSnackBar,
    private usuarioService: UsuarioService,
    private aplicacaoService: AplicacaoService,
    private router: Router) {
  }

  ngOnInit(): void {
    window.document.title = this.title;
    setTimeout(() => {
      this.carregado = true;
    }, 1000);
    //this.getAplicacoes()
  }

  GetIdAplicacaoSelecionada() {
    console.log(this.listaAplicacoesSelecionadas);
  }

  async buscaNomeUsuario() {
    if (this.usuario.chave != '' && this.usuario.chave != null) {
      this.carregado = false
      const loginData = JSON.parse(localStorage.getItem('loginData'))
      let dadosUsuario = await this.usuarioService.RetornaNomeUsuario(loginData.sessionId, this.usuario.chave)

      if (dadosUsuario.data == '') {
        this.carregado = true
        this.nomeUsuario = null
        document.getElementById('divApp').style.display = 'none'
        this.snackBar.open("Nenhum registro encontrado. Verifique a chave e tente novamente.", "OK", { duration: 7000, panelClass:['alerta'] });
        return
      }
      this.carregado = true
      this.user.name = this.nomeUsuario = dadosUsuario.data[0]
      this.user.email = dadosUsuario.data[1]
      this.getAplicacoes()
      document.getElementById('divApp').style.display = 'block'

    }
    else {
      this.carregado = true
      this.nomeUsuario = null
      this.snackBar.open("Digite a chave do usuário.", "OK", { duration: 3000, panelClass:['alerta'] });
      document.getElementById('divApp').style.display = 'none'
    }

  }

  async getAplicacoes() {
    try {
      const response = await this.aplicacaoService.GetAplicacoes();
      if (response.data != null) {
        this.aplicacoes = response.data;
        this.aplica = this.aplicacoes
        setTimeout(() => {
          this.carregado = true;
        }, 1000);
      }
      else {
        this.carregado = true;
      }
    } catch (error) {
      this.carregado = true;
      console.log(error);
    }
  }

  async inserirUsuario() {

    if (this.listaAplicacoesSelecionadas === undefined) {
      this.snackBar.open("Selecione a aplicação.", "OK", { duration: 3000, panelClass:['alerta'] });
      return
    }

    if (this.usuario.chave == '' || this.usuario.chave == null) {
      this.snackBar.open("Digite a chave do usuário.", "OK", { duration: 3000, panelClass:['alerta'] });
      return
    }

    try {
      this.carregado = false;
      const loginData = JSON.parse(localStorage.getItem('loginData'))
      this.usuario.IdAplicacao = this.listaAplicacoesSelecionadas
      this.usuario.ChaveUsuarioLogin = loginData.chave
      this.user.chave = this.usuario.chave
      await this.usuarioService.InserirUsuarioAplicacao(this.usuario).then(response => {
        if (response.data != null) {

          //inserindo na tabela de usuario
          this.usuarioService.InserirUsuario(this.user).then(resp =>{
            if (resp.data != null) {
              setTimeout(() => {
                this.carregado = true;
              }, 1000);
              this.snackBar.open("Ação concluida.", "OK", { duration: 3000, panelClass:['sucesso'] });
              this.router.navigate(['/administracao/usuario/listar'])
            }
          })
        }
        else {
          this.carregado = true;
          this.snackBar.open("Erro ao cadastrar usuário.", "OK", { duration: 5000, panelClass:['erro'] });
        }

      })
    }
    catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  voltarPagina(): void {
    this.router.navigate(['/administracao/usuario/listar'])
  }

}
