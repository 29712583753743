<div class="content">
	<form class="p-3">
		<div class="card">
			<div class="card-header textoNoCentro">LISTA DE SONDAS</div>
			<div class="card-body">
				<ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}" [show]="!carregado">
				</ngx-loading>
				<button class="btn btn-success" (click)="cadastrar()">NOVO</button>
				<div style="clear: both;">&nbsp;</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group has-search">
							<span class="form-control-feedback">
								<i class="material-icons">search</i>
							</span>
							<input type="text" class="form-control" (keyup)="filtrar($event.target.value)" placeholder="Busca por nome" />
						</div>
					</div>
				</div>
				<ngx-skeleton-loader *ngIf="!carregado" appearance="pulse"></ngx-skeleton-loader>
				<div class="mat-elevation-z3" *ngIf="carregado">
					<mat-table class="table" #table [dataSource]="datasource">
						<ng-container matColumnDef="codigo">
							<mat-header-cell *matHeaderCellDef>Código</mat-header-cell>
							<mat-cell *matCellDef="let sonda">{{sonda.codigo}}</mat-cell>
						</ng-container>
						<ng-container matColumnDef="nome">
							<mat-header-cell *matHeaderCellDef>Nome</mat-header-cell>
							<mat-cell *matCellDef="let sonda">{{sonda.nome}}</mat-cell>
						</ng-container>
						<ng-container matColumnDef="ativo">
							<mat-header-cell *matHeaderCellDef>Ativo</mat-header-cell>
							<mat-cell *matCellDef="let sonda">
								<mat-checkbox disabled [checked]="sonda.ativo"></mat-checkbox>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="sincronizado">
							<mat-header-cell *matHeaderCellDef>Sincronizado</mat-header-cell>
							<mat-cell *matCellDef="let sonda">
								<mat-checkbox disabled [checked]="sonda.sincronizado"></mat-checkbox>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="dataSincronizacao">
							<mat-header-cell *matHeaderCellDef>Data da última sincronização</mat-header-cell>
							<mat-cell *matCellDef="let sonda">{{sonda.dataSincronizacao}}</mat-cell>
						</ng-container>
						<ng-container matColumnDef="acoes">
							<mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
							<mat-cell *matCellDef="let sonda"><mat-icon (click)="editar(sonda)" style="cursor: pointer;">edit</mat-icon></mat-cell>
						</ng-container>
						<mat-header-row *matHeaderRowDef="showColumns"></mat-header-row>
						<mat-row class="mat-row" *matRowDef="let row; columns: showColumns;"></mat-row>
					</mat-table>
					<mat-paginator #paginacao [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Itens por página"></mat-paginator>
				</div>
			</div>
		</div>
	</form>
</div>
