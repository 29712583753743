import { Injectable } from '@angular/core';
import { http } from "./config.service"

@Injectable({
  providedIn: 'root'
})
export class MenuPocoService {

  GerarMenuHierarquiaPocos() {
    return http.get('api/menuPocos/unops')
  }

  GetMenuHierarquiaPocos() {
    return http.get('api/menuPocos')
  }

  GetInterventionsByUrlWell(codigoWell) {
    return http.get('api/menuPocos/GetInterventions/' + codigoWell)
  }

  GetCamposPorUnidade(urlUnidade){
    return http.get('api/menuPocos/GetCamposPorUnidade/' + urlUnidade)
  }

  GetPocosPorCampo(urlCampo){
    return http.get('api/menuPocos/GetPocosPorCampo/' + urlCampo)
  }

  GetUnidades() {
    return http.get('api/public/opunits/')
  }

  Search(value) {
    return http.get('api/menuPocos/search/' + value)
  }
}
