<div id="Funcodetela">

  <h2 class="p-3 fontePetrobras">PWO - Poço Web Operações</h2>
  <form [formGroup]="loginForm">

    <div class="d-flex p-3  align-items-center ">

      <div class="card p-3">
        <div class="form-group">
          <span style="color: rgb(102, 100, 100); font-weight: bold; font-size: 12px;">Informe suas credenciais de
            acesso</span>
          <br>
          <br>
          <label for="username">Usuário</label>
          <input type="text" autofocus formControlName="username" class="form-control" style="text-transform: lowercase;"
            [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Preencha o campo Usuário</div>
          </div>

        </div>
        <div class="form-group">
          <label for="password">Senha</label>
          <input type="password" id="input-senha" formControlName="password" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />

          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Preencha o campo Senha</div>
          </div>
        </div>

        <button (click)="onSubmit()" class="btn btn-success " type="submit" [disabled]="loading">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Entrar
        </button>

      </div>
    </div>
    <div class="form-group" style="color: rgb(0, 0, 0); font-weight: bold; width: 265px; margin-left: 32px;"
      [style.visibility]="loginError ? 'visible' : 'hidden'">
      Falha no login. Tente novamente.
    </div>

  </form>

  <img class="logoMarca" src="assets/Principal_h_cor_RGB.jpg">
</div>
