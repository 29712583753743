import { Injectable } from '@angular/core';
import { http } from "./config.service"

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	// login2(usuario, senha){
	//   return http.post('api/login/login/', usuario , senha)
	// }

	login(dadosLogin) {
		return http.post('api/login/login/', dadosLogin)
	}

	logOut() {
		return http.post('api/logout/')
	}
}
