import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { intervencao } from 'src/app/model/intervencao';
import { MenuPocoService } from 'src/app/services/MenuPoco.service';
import { UnidadeIntervencaoService } from 'src/app/services/unidadeIntervencao.service';
import { CreateComponent } from '../create/create.component';

@Component({
  selector: 'app-arvore-detail',
  templateUrl: './arvore-detail.component.html',
  styleUrls: ['../../app.component.css', './arvore-detail.component.css']
})

export class ArvoreDetailComponent implements OnInit {
  carregado = false
  isVisibleCampos = false
  isVisiblePocos = false
  isVisibleIntervencoes = false
  closeResult: string;
  modalReference: NgbModalRef;

  nomeUnidade: any
  urlUnidade: any
  nomeCampo: any
  urlCampo: any

  nomePoco: any
  urlPoco: any

  campos: any[]
  pocos: any[]
  intervencoes: intervencao[]

  idInterventionToRemove: string

  permissaoExclusao: boolean
  habilitaRemocao: string = ''

  constructor(private menuService: MenuPocoService,
    private snackBar: MatSnackBar,
    private router: Router,
    private modalService: NgbModal,
    private interventionService: UnidadeIntervencaoService) {
  }

  ngOnDestroy() {
    localStorage.removeItem("intervencaoSelecionada");
  }

  ngOnInit(): void {
    this.nomeCampo = JSON.parse(localStorage.getItem('CampoSelecionado'))
    this.urlCampo = JSON.parse(localStorage.getItem('UrlCampoSelecionado'))
    this.urlUnidade = JSON.parse(localStorage.getItem('UrlUnidadeSelecionada'))
    this.nomeUnidade = JSON.parse(localStorage.getItem('UnidadeSelecionada'))

    this.urlPoco = JSON.parse(localStorage.getItem('UrlPocoSelecionado'))
    this.nomePoco = JSON.parse(localStorage.getItem('PocoSelecionado'))


    if (this.urlCampo != null && this.urlUnidade != null && this.urlPoco != null) {
      this.GetIntervencoes()
      return
    }

    if (this.urlCampo != null) {
      this.GetPocos()
      return
    }
    if (this.urlUnidade != null) {
      this.GetCampos()
      return
    }

  }

  async GetCampos() {
    localStorage.removeItem('CampoSelecionado')
    localStorage.removeItem('UrlCampoSelecionado')
    localStorage.removeItem('UrlPocoSelecionado')
    localStorage.removeItem('PocoSelecionado')
    if (this.urlUnidade != null) {
      try {
        this.carregado = false;
        const response = await this.menuService.GetCamposPorUnidade(this.urlUnidade);
        if (response.data != null) {
          this.campos = response.data

          if (this.campos.length == 0) {
            this.campos = null
          }
          else {
            this.isVisibleCampos = true
            this.isVisiblePocos = false
            this.isVisibleIntervencoes = false
          }
          setTimeout(() => {
            this.carregado = true
          }, 500);

        }
      }
      catch (error) {
        this.carregado = true
        console.error(error);
      }
    }
  }

  CarregarPoco(campoName, campoUrl) {

    localStorage.setItem('CampoSelecionado', JSON.stringify(campoName))
    localStorage.setItem('UrlCampoSelecionado', JSON.stringify(campoUrl))

    this.nomeCampo = campoName
    this.urlCampo = campoUrl
    this.GetPocos()
  }

  async GetPocos() {
    try {
      localStorage.removeItem('PocoSelecionado')
      this.carregado = false;
      const response = await this.menuService.GetPocosPorCampo(this.urlCampo);
      if (response.data != null) {
        this.pocos = response.data

        if (this.pocos.length == 0) {
          this.pocos = null
          this.snackBar.open("Este campo não possui poço.", "OK", { duration: 4000, panelClass: ['erro'] })
        }
        else {
          this.isVisibleCampos = false
          this.isVisibleIntervencoes = false
          this.isVisiblePocos = true
        }

        setTimeout(() => {
          this.carregado = true
        }, 500);
      }

    }
    catch (error) {
      this.carregado = true
      this.isVisibleCampos = false
      this.isVisiblePocos = true
      console.error(error);
    }
  }

  CarregarInterventions(pocoUrl, pocoName) {

    localStorage.setItem('PocoSelecionado', JSON.stringify(pocoName))
    localStorage.setItem('UrlPocoSelecionado', JSON.stringify(pocoUrl))

    localStorage.setItem('CampoSelecionado', JSON.stringify(this.nomeCampo))
    localStorage.setItem('UnidadeSelecionada', JSON.stringify(this.nomeUnidade))

    this.nomePoco = pocoName
    this.urlPoco = pocoUrl
    this.GetIntervencoes()
  }

  async GetIntervencoes() {
    try {
      this.carregado = false;
      const response = await this.menuService.GetInterventionsByUrlWell(this.urlPoco)
      if (response.data != null) {
        this.intervencoes = response.data

        if (this.intervencoes.length == 0) {
          this.intervencoes = null
        }
        this.isVisibleCampos = false
        this.isVisiblePocos = false
        this.isVisibleIntervencoes = true

        setTimeout(() => {
          this.carregado = true
        }, 500);

      }
      else {
        this.intervencoes = null
        this.snackBar.open("Este poço não possui intervenções.", "OK", { duration: 4000, panelClass: ['erro'] })
        this.carregado = true;
      }
    }
    catch (error) {
      this.carregado = true;
      this.isVisibleCampos = false
      this.isVisiblePocos = false
      this.isVisibleIntervencoes = true
      console.error(error);
    }
  }

  selectItem(id) {
    var inters = document.getElementsByClassName('selectButton');
    var btnOpcoes = document.getElementById('btnOpcoes');

    for (let index = 0; index < inters.length; index++) {
      inters.item(index).classList.remove('selectButton');
    }

    var inter = document.getElementById(id);

    if (inter !== null) {

      inter.classList.add('selectButton');

      localStorage.setItem("intervencaoSelecionada", id);

      btnOpcoes.classList.remove('hiddenElement');

      this.idInterventionToRemove = id

    } else {
      localStorage.removeItem("intervencaoSelecionada");
      btnOpcoes.classList.add('hiddenElement');
    }
  }

  removerSelecao() {
    var inters = document.getElementsByClassName('selectButton');

    for (let index = 0; index < inters.length; index++) {
      inters.item(index).classList.remove('selectButton');
    }
  }

  mensagemIntervencao(id) {

    this.router.navigate(['/painel/' + id]);
  }

  open(modo) {
    localStorage.setItem('modoIntervencao', modo);

    this.modalReference = this.modalService.open(CreateComponent, { size: 'lg', backdrop: 'static', centered: true, backdropClass: 'customBackdrop' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      var temPoco = JSON.parse(localStorage.getItem('PocoSelecionado'))
      if (temPoco != null) {
        this.GetIntervencoes()
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async alertaModalRemocaoIntervention(content) {
    await this.interventionService.GetFilesFromIntervention(this.idInterventionToRemove).then(res => {
      if (res.data.arquivos.length > 0) {
        this.permissaoExclusao = false
        this.carregaModalExlusaoNegada(content)
        return;
      }
      else {
        this.interventionService.GetAttachmentsFromIntervention(this.idInterventionToRemove).then(resAtt => {
          if (resAtt.data.length > 0) {
            this.permissaoExclusao = false
            this.carregaModalExlusaoNegada(content)
            return;
          }
          else {

            this.permissaoExclusao = true
            this.carregaModalExlusaoNegada(content)
            return;
          }
        });
      }
    });
  }

  carregaModalExlusaoNegada(content) {
    this.modalReference = this.modalService.open(content, { size: 'md', backdrop: 'static', centered: true, backdropClass: 'customBackdrop' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async removerIntervencao() {

    try {
      await this.interventionService.RemoveIntervention(this.idInterventionToRemove).then(dele => {
        if (dele.status == 204) {
          localStorage.removeItem("intervencaoSelecionada");
          this.habilitaRemocao = ''
          this.snackBar.open("Intervenção removida com sucesso.", "OK", { duration: 4000, panelClass: ['sucesso'] });
          this.GetIntervencoes()
        }
        else {
          this.snackBar.open("Erro ao remover Intervenção.", "OK", { duration: 4000, panelClass: ['erro'] });
        }
      })

    } catch (error) {
      this.snackBar.open("Erro ao remover Intervenção.", "OK", { duration: 4000, panelClass: ['erro'] });
      console.log(error);
    }
  }

  desabilitaCampos() {
    if (this.habilitaRemocao.toUpperCase() !== 'REMOVER') {
      return false
    }
    return true
  }
}
