import { Injectable } from '@angular/core';
import { http } from "./config.service"

@Injectable({
  providedIn: 'root'
})
export class MqttService {

  PostApps(apps) {
    return http.post('api/mqtt/AppsSonda', apps)
  }

  Put(app){
    return http.put('api/mqtt/AppSonda', app)
  }

  GetAllApss() {
    return http.get('api/mqtt/GetAllAppsSondas/')
  }

  GetSuitById(sondaId) {
    return http.get('api/mqtt/AppsSondas/' + sondaId)
  }

  CheckIfExistsOnDatabase(sondaId, appId) {
    return http.get('api/mqtt/AppSonda/checkIfExists/' +sondaId+ '/' + appId)
  }

  Delete(sondaId, aplicacaoId){
    return http.delete('api/mqtt/AppSonda/' + sondaId + '/' + aplicacaoId)
  }

  GetDashBoard() {
    return http.get('api/mqtt/AppsSondas/DashBoard/')
  }

  GetApplicationHistory(sondaId, appId) {
    return http.get('api/mqtt/AppSonda/History/'  +sondaId+ '/' + appId)
  }

  GetApplicationConnectionHistory(sondaId, appId) {
    return http.get('api/mqtt/AppSonda/Connections/'  +sondaId+ '/' + appId)
  }


}
