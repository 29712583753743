import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class RenovaTokenService {

  constructor(private httpClient: HttpClient) { }

  renovaToken(validateToken): Observable<string> {
    let url = 'api/login/renovaToken/'
    return this.httpClient.post(url, validateToken, { responseType: 'text' });
  }
}
