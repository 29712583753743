<div id="Funcodetela">
  <!-- <div>
    <img src="src\app\login_fundo.jpeg" alt="">
  </div> -->
  <div class="box">
    <div class="content">
      <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
        [show]="!carregado">
      </ngx-loading>
      <form class="p-5">
        <div *ngIf="loginData != null">
          <div class="row">
            <div class="card col-xs-12" style=" opacity: 0.6;">
              <label style="font-weight: bold">Olá, {{loginData.nome}}. Seja bem
                vindo!</label>
            </div>
          </div>
          <br><br>

			<div class="row" *ngIf="this.perfilAdm > 0 || this.perfilIntervencao > 0">
					<div (click)="abrirPainel()" class="card col-md-3 col-sm-3 col-xs-12" style="max-width: 356px;">
						<div class="card-body" style="margin-left: -31px; cursor: pointer; ">
							<div>
								<button style="display:flex; background-color:#ffffff; outline: none; border: none;">
									<mat-icon style="color: rgb(117, 117, 117); font-size: 40px"> library_books</mat-icon>
									&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
									<span class="titulo"
										style="line-height: 24px; font-weight: bold; color: #008542;">INTERVENÇÕES</span>
								</button>
							</div>
							<div style="margin-left: 64px;">
								<span style="line-height: 12px; font-weight: bold; color: rgb(163, 162, 162); word-break: keep-all;
									word-wrap: normal;">Gerencie as informações
									de intervenções</span>
							</div>
						</div>
					</div>
				&nbsp;&nbsp;
				<div class="card col-md-3 col-sm-3 col-xs-12" style="max-width: 356px;" routerLink="../mqtt">
					<div class="card-body" style="margin-left: -31px; cursor: pointer; ">
						<div>
							<button style="display:flex; background-color:#ffffff; outline: none; border: none;">
								<mat-icon style="color: rgb(117, 117, 117); font-size: 40px"> published_with_changes</mat-icon>
								&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
								<span class="titulo" style="line-height: 24px; font-weight: bold; color: #008542;">MQTT</span>
							</button>
						</div>
						<div style="margin-left: 64px;">
						<span style="line-height: 12px; font-weight: bold; color: rgb(163, 162, 162);word-break: keep-all;
						word-wrap: normal;">Visualize um dashboard
						das conexões</span>
						</div>
					</div>
				</div>
				&nbsp;&nbsp;
				<div class="card col-md-3 col-sm-3 col-xs-12" style="max-width: 340px;" routerLink="../painel-acessos">
					<div class="card-body" style="margin-left: -31px; cursor: pointer; ">
						<div>
							<button style="display:flex; background-color:#ffffff; outline: none; border: none;">
							<mat-icon style="color: rgb(117, 117, 117); font-size: 40px"> insert_chart</mat-icon>
							&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
							<span class="titulo" style="line-height: 24px; font-weight: bold; color: #008542;">Painel de Acessos</span>
							</button>
						</div>
						<div style="margin-left: 64px;">
							<span style="line-height: 12px; font-weight: bold; color: rgb(163, 162, 162);word-break: keep-all;
								word-wrap: normal;">Visualizar acompanhamento</span>
						</div>
					</div>
				</div>
			</div>

          <br><br>
          <div class="card" style="max-width: 1070px; margin-left: -17px;">
            <div class="card-header titulo">
              APLICAÇÕES INTEGRADAS
            </div>

            <div class="card-body" *ngIf="carregado" style="background-color: #e9e9e9;">
              <div class="fixarConteudo" *ngIf="aplica != null">
                <div class="row" *ngFor="let app of aplica">
                  <div *ngFor="let app of app.aplicacao">
                    <div class="card" style=" margin-left: 10px; padding: 30px;">
                      <a style="text-decoration: none; max-width: 170px; min-width: 170px; color: #008542; cursor: pointer;" (click)="ContablizarAcesso(app)"
                        [title]=" 'Clique para acessar - ' + app.nomeAplicacaoAmigavel">
                        <div class="box-part text-center">
                          <i [innerHTML]="sanitizer.bypassSecurityTrustHtml(app.icone)"
                            *ngIf="app.icone != null || app.icone != ''">
                          </i>
                          <div *ngIf="app.icone == null || app.icone == ''">
                            <h4>{{app.nomeAplicacaoAmigavel}}</h4>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="aplica == null">
                <div class="row">
                  <p class="fontePetrobras" style="color: red; font-weight: bold; margin-left: 26%;">Nenhuma aplicação
                    encontrada! Entre em
                    contato com o administrador.</p>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button *ngIf="this.perfilAdm > 0"
                style="display:flex; background-color:#fff; outline: none; border: none;" (click)="administracao()">
                <mat-icon style="font-size: 16px; padding-top:4px;">settings</mat-icon>
                <span class="fontePetrobras" style="line-height: 24px; color: blue;">Administração</span>
              </button>
              <button style="display:flex; background: #fff;outline: none; border: none;" (click)="forceLogout()">
                <mat-icon style="font-size: 16px; padding-top:4px;">exit_to_app</mat-icon>
                <span class="fontePetrobras" style="line-height:24px; color: blue;">Sair</span>
              </button>
            </div>
          </div>
        </div>

        <div class="card" *ngIf="loginData == null">
          <div class="card-header textoNoCentro">
            APLICAÇÕES INTEGRADAS
          </div>
          <div class="card-body">
            <div>
              <h6>Você não está logado!</h6>
              <br>
              <span>
                <button class="btn btn-success" routerLink="../loginPage">Clique aqui para fazer login</button>
              </span>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
