import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import 'src/assets/js/switcher.js';

declare const switcher : any;

@Component({
  selector: 'app-switcher-bar',
  templateUrl: './switcher-bar.component.html',
  styleUrls: ['./switcher-bar.component.css']
})
export class SwitcherBarComponent implements OnInit {
  	constructor() { }
  	apiURL = environment.apiURL;

  	ngOnInit(): void {
		  this.getSwitcher();
  	}

	getSwitcher() {
		console.log("switcherFunction", this.apiURL);
		switcher.loadSwitcher(this.apiURL);
	}

	getAppsSelect(e) {
		console.log("acessou o appSelect", e.target.value);
		switcher.TriggerAppsSelect(e.target.value);
	}
}
