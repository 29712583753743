<div class="content">
  <form class="p-3">
    <div class="card">
      <div class="card-header textoNoCentro">
        LISTA DE ESQUEMAS
      </div>
      <div class="card-body">
        <mat-tab-group (selectedTabChange)="tabClick($event)">
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">assignment</mat-icon>&nbsp;
              Esquemas
            </ng-template>&nbsp;
            <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
              [show]="!carregadoSchema">
            </ngx-loading>

            <div class="card-body">
              <button class="btn btn-success" routerLink="../cadastrar">NOVO</button>
              <br><br><br>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group has-search">
                    <span class="form-control-feedback">
                      <i class="material-icons">
                        search
                      </i>
                    </span>
                    <input type="text" class="form-control" (keyup)="filtrarSchema($event.target.value)"
                      placeholder="Busca rápida">
                  </div>
                </div>
              </div>
              <br>
              <ngx-skeleton-loader *ngIf="!carregadoSchema" appearance="pulse">
              </ngx-skeleton-loader>
              <div class="row">
                <div class="col-md-12">
                  <label style="font-weight: bold;" *ngIf="dataSourceSchema != undefined && carregadoSchema">Total de
                    {{dataSourceSchema.filteredData.length}} registro(s)</label>
                </div>

              </div>
              <div class="mat-elevation-z3" *ngIf="carregadoSchema">

                <mat-table class="table" #table [dataSource]="dataSourceSchema">

                  <!-- tipoArquivo -->
                  <ng-container matColumnDef="tipoArquivo">
                    <mat-header-cell *matHeaderCellDef> Tipo Arquivo </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.nomeArquivo}} </mat-cell>
                  </ng-container>

                  <!-- versao -->
                  <ng-container matColumnDef="versao">
                    <mat-header-cell *matHeaderCellDef> Versão </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.versao}} </mat-cell>
                  </ng-container>

                  <!-- documentacao -->
                  <ng-container matColumnDef="documentacao">
                    <mat-header-cell *matHeaderCellDef> Documentacao </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.documentacao}} </mat-cell>
                  </ng-container>

                  <!-- data de cadastro -->
                  <ng-container matColumnDef="dtCadastro">
                    <mat-header-cell *matHeaderCellDef> Data de Cadastro </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.geradoEm | date: 'dd/MM/yyyy HH:mm'}} </mat-cell>
                  </ng-container>

                  <!-- Ações -->
                  <ng-container matColumnDef="acoes">
                    <mat-header-cell *matHeaderCellDef> Ações </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <a title="Clique para editar" class="edit"
                        routerLink="/administracao/schemas/atualizar/{{element.idSchema}}">
                        <i class="material-icons edit">edit</i>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a title="Clique para remover" class="delete" style="cursor: pointer;"
                        (click)="removerSchema(element.idSchema)">
                        <i class="material-icons">delete_forever</i>
                      </a>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumnsSchemas"></mat-header-row>
                  <mat-row class="mat-row" *matRowDef="let row; columns: displayedColumnsSchemas;"></mat-row>
                </mat-table>
              </div>
            </div>

          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">insert_drive_file</mat-icon>
              Tipos de Arquivo
            </ng-template> &nbsp;
            <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
              [show]="!carregado">
            </ngx-loading>
            <br><br>

            <div class="card-body">

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group has-search">
                    <span class="form-control-feedback">
                      <i class="material-icons">
                        search
                      </i>
                    </span>
                    <input type="text" class="form-control" (keyup)="filtrar($event.target.value)"
                      placeholder="Busca rápida">
                  </div>
                </div>
              </div>
              <br>
              <ngx-skeleton-loader *ngIf="!carregado" appearance="pulse">
              </ngx-skeleton-loader>

              <div class="row">
                <div class="col-md-12">
                  <label style="font-weight: bold;" *ngIf="dataSource != undefined">Total de
                    {{dataSource.filteredData.length}} registro(s)</label>
                </div>

              </div>

              <div class="mat-elevation-z3" *ngIf="carregado">

                <mat-table class="table"  #table [dataSource]="dataSource">

                  <!-- nome -->
                  <ng-container matColumnDef="nome">
                    <mat-header-cell *matHeaderCellDef> Nome </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.nomeArquivo}} </mat-cell>
                  </ng-container>

                  <!-- descricao -->
                  <ng-container matColumnDef="descricao">
                    <mat-header-cell *matHeaderCellDef> Descrição </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.descricaoArquivo}} </mat-cell>
                  </ng-container>

                   <!-- data de cadastro -->
                   <ng-container matColumnDef="dtCadastro">
                    <mat-header-cell *matHeaderCellDef> Data de Cadastro </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.geradoEm | date: 'dd/MM/yyyy HH:mm'}} </mat-cell>
                  </ng-container>

                  <!-- Ações -->
                  <ng-container matColumnDef="acoes">
                    <mat-header-cell *matHeaderCellDef> Ações </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <a style="cursor: pointer;" title="Clique para editar" (click)="open(mymodal, element)"
                        class="edit">
                        <i class="material-icons edit">edit</i>
                      </a>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row class="mat-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </form>

  <!-- Modal Update TipoArquivo -->
  <ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Editar Tipo de Arquivo</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" *ngIf="tipo != null">
      <div class="row">
        <div class="col-md-11 col-lg-11">
          <label>Nome</label>
          <input type="text" style="text-transform: uppercase;" readonly name="NomeArquivo" class="form-control"
            [(ngModel)]="tipo.NomeArquivo">
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-11 col-lg-11">
          <label>Descrição*</label>
          <textarea type="text" name="DescricaoArquivo" class="form-control"
            [(ngModel)]="tipo.DescricaoArquivo"></textarea>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" [disabled]="tipo.DescricaoArquivo == ''" class="btn btn-success"
        (click)="Atualizar()">Atualizar</button>

    </div>
  </ng-template>
  <!-- FIM Modal Update TipoArquivo -->
