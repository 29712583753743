<div class="content">
  <form class="p-3">
    <div class="card">
      <div class="card-header textoNoCentro">
       LISTA DE APLICAÇÕES
      </div>
      <div class="card-body">
        <ngx-loading [config]="{primaryColour: 'green', secondaryColour: 'green', tertiaryColour: 'green'}"
          [show]="!carregado">
        </ngx-loading>
        <button class="btn btn-success" routerLink="../cadastrar">NOVO</button>
        <br><br><br>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group has-search">
              <span class="form-control-feedback">
                <i class="material-icons">
                  search
                </i>
              </span>
              <input type="text" class="form-control" (keyup)="filtrar($event.target.value)" placeholder="Busca rápida">
            </div>
          </div>
        </div>
        <ngx-skeleton-loader *ngIf="!carregado" appearance="pulse">
        </ngx-skeleton-loader>
        <div class="mat-elevation-z3" *ngIf="carregado">

          <mat-table class="table" #table [dataSource]="dataSource">

            <!-- nome -->
            <ng-container matColumnDef="nome">
              <mat-header-cell *matHeaderCellDef> Nome </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.nomeAplicacao}} </mat-cell>
            </ng-container>

            <!-- tipo -->
            <ng-container matColumnDef="tipo">
              <mat-header-cell *matHeaderCellDef> Tipo </mat-header-cell>
              <mat-cell *matCellDef="let element" [innerHTML]="element.tipoAplicacao"></mat-cell>
            </ng-container>

			  <!-- aplicacao integrada -->
			  <ng-container matColumnDef="integrada">
				<mat-header-cell *matHeaderCellDef> Aplicação Integrada? </mat-header-cell>
				<mat-cell *matCellDef="let element" [innerHTML]="element.aplicacaoIntegrada ? 'SIM' : 'NÃO' "></mat-cell>
			  </ng-container>

             <!-- Ações -->
             <ng-container matColumnDef="acoes">
              <mat-header-cell *matHeaderCellDef> Ações </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a title="Clique para editar" class="edit" routerLink="/administracao/aplicacao/atualizar/{{element.idAplicacao}}">
                  <i class="material-icons edit">edit</i>
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a title="Clique para remover" class="delete" style="cursor: pointer;" (click)="remover(element.idAplicacao)">
                    <i class="material-icons">delete_forever</i>
                    </a>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row class="mat-row"  *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  </form>
