import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { LoginData } from 'src/app/model/login-data';
import { OpUnit } from 'src/app/model/OpUnit';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MenuPocoService } from 'src/app/services/MenuPoco.service';

declare var $: any

@Component({
  selector: 'app-nav-intervencoes',
  templateUrl: './nav-intervencoes.component.html',
  styleUrls: ['./nav-intervencoes.component.css']
})
export class NavIntervencoesComponent implements OnInit, AfterContentChecked {

  filtro: string
  newFiltro: string;
  loginData: LoginData;
  title = 'PWO - Controle de Intervenções';
  url: string
  verMenu: boolean
  isShowing: boolean;
  carregado = false;
  loadWells = false;
  primeiraBusca = true;
  displayedColumns = ['UN'];
  dataSource: MatTableDataSource<OpUnit>;
  dadosMenu: OpUnit[];

  constructor(
    private authService: AuthenticationService,
    private menuPocoService: MenuPocoService,
    private router: Router) {

    this.loginData = JSON.parse(localStorage.getItem('loginData'))
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!
        this.url = event.urlAfterRedirects;
        if (this.url === '/AcessoAplicacao' || this.url === '/loginPage' || this.url === '/docs' || this.url === '/homePage' || this.url === '/painel' || this.url.indexOf('mqtt') >= 0) {
          this.verMenu = false
        }
        else {
          this.verMenu = true
        }
      }
    })
  }

  async getMenu() {
    try {
      var response = await this.menuPocoService.GetMenuHierarquiaPocos()
      if (response.data != null) {
        this.dataSource = new MatTableDataSource<OpUnit>(response.data);
        this.dadosMenu = response.data;
        this.carregado = true
		$("#inputFiltro").focus();
      }
    } catch (error) {
      this.carregado = true
      console.error(error);
    }
  }

  ngOnDestroy() {
    this.verMenu = false
  }

  voltaParaListaDeAplicacoes() {

    localStorage.removeItem("UnidadeSelecionada")
    localStorage.removeItem("UrlUnidadeSelecionada")
    localStorage.removeItem("CampoSelecionado")
    localStorage.removeItem("UrlCampoSelecionado")
    localStorage.removeItem("PocoSelecionado")
    localStorage.removeItem("UrlPocoSelecionado")
    this.verMenu = false
    this.router.navigate(['/homePage'])
  }

  toggleSidenav() {
    this.isShowing = !this.isShowing;
  }

  ngAfterContentChecked() {
    var escondeLabelWells = $(".ulWell label[mostraLabel = 'false']")
    escondeLabelWells.addClass("hiddenElement");

  }

  ngOnInit(): void {
    window.document.title = this.title;
    this.getMenu();

  }

  forceLogout(): void {
    this.verMenu = false
    this.authService.logout();

  }

  verIntervencoes(urlPoco, OpunitName, OpunitUrl, OilFieldName, OilFieldUrl, pocoName) {
    let urlDestino = '/intervencao/tree-detail/'

    // localStorage.removeItem("CampoSelecionado")
    // localStorage.removeItem("UrlCampoSelecionado")

    localStorage.setItem('CampoSelecionado', JSON.stringify(OilFieldName));
    localStorage.setItem('UrlCampoSelecionado', JSON.stringify(OilFieldUrl));
    localStorage.setItem('UnidadeSelecionada', JSON.stringify(OpunitName));
    localStorage.setItem('UrlUnidadeSelecionada', JSON.stringify(OpunitUrl));

    localStorage.setItem('PocoSelecionado', JSON.stringify(pocoName));
    localStorage.setItem('UrlPocoSelecionado', JSON.stringify(urlPoco));

    this.router.navigateByUrl('/intervencao/index', { skipLocationChange: true }).then(() => {
      this.router.navigate([urlDestino]);
    });
  }

  verCampos(OpunitName, OpunitUrl) {

    localStorage.removeItem("UnidadeSelecionada")
    localStorage.removeItem("UrlUnidadeSelecionada")
    localStorage.removeItem("CampoSelecionado")
    localStorage.removeItem("UrlCampoSelecionado")
    localStorage.removeItem("PocoSelecionado")
    localStorage.removeItem("UrlPocoSelecionado")

    let urlDestino = '/intervencao/tree-detail/'
    localStorage.setItem('UnidadeSelecionada', JSON.stringify(OpunitName));
    localStorage.setItem('UrlUnidadeSelecionada', JSON.stringify(OpunitUrl));

    this.router.navigateByUrl('/intervencao/index', { skipLocationChange: true }).then(() => {
      this.router.navigate([urlDestino]);
    });
  }

  verPocos(OilFieldName, OilFieldUrl, OpunitName, OpunitUrl) {

    let urlDestino = '/intervencao/tree-detail/'

    localStorage.removeItem("UnidadeSelecionada")
    localStorage.removeItem("UrlUnidadeSelecionada")
    localStorage.removeItem("CampoSelecionado")
    localStorage.removeItem("UrlCampoSelecionado")
    localStorage.removeItem("PocoSelecionado")
    localStorage.removeItem("UrlPocoSelecionado")

    localStorage.setItem('CampoSelecionado', JSON.stringify(OilFieldName));
    localStorage.setItem('UrlCampoSelecionado', JSON.stringify(OilFieldUrl));
    localStorage.setItem('UnidadeSelecionada', JSON.stringify(OpunitName));
    localStorage.setItem('UrlUnidadeSelecionada', JSON.stringify(OpunitUrl));

    this.router.navigateByUrl('/intervencao/index', { skipLocationChange: true }).then(() => {
      this.router.navigate([urlDestino]);
    });
  }

  cleanFilter() {
    console.log("limpando filtros...");
    $(".label_exp[mostraLabel = 'true']").attr("mostraLabel", "false");
    $(".label_exp[mostraLabel = 'false']").removeClass("hiddenElement");

    $(".label_exp[mostraLabelCampos = 'true']").attr("mostraLabelCampos", "false");
    $(".label_exp[mostraLabelCampos = 'false']").removeClass("hiddenElement");

    $('.unidade').removeClass("hiddenElement");
    $('.unidade').addClass("showElement");

    $('.campo').removeClass("hiddenElement");
    $('.campo').addClass("showElement");

    $('.poco').removeClass("hiddenElement");
    $('.poco').addClass("showElement");
  }

  BuscaRapida(filtro) {

    filtro = filtro.trim().toUpperCase()

    $("mat-row").css("display", "flex");

    $(".label_exp[expanded = 'true']").trigger("click");
    $(".label_exp[expanded = 'true']").attr("expanded", "false");

    $(".label_exp[mostraLabel = 'false']").attr("mostraLabel", "true");
    $(".label_exp[mostraLabel = 'true']").removeClass("hiddenElement");

    $(".label_exp[mostraLabelCampos = 'false']").attr("mostraLabelCampos", "true");
    $(".label_exp[mostraLabelCampos = 'true']").removeClass("hiddenElement");


    if (filtro === "") {
      this.cleanFilter();

      return;
    }

    document.querySelectorAll("li").forEach(function (li) {

      if (li.textContent.match(filtro.toUpperCase())) {
        li.classList.remove("hiddenElement");
        li.classList.add("showElement");

        var id = $("#" + li.id)
        var label = id.children(".label_exp")

        if (label.attr("expanded") == "false") {

          label.trigger("click")
          label.attr("expanded", "true")

          if (li.classList.contains('unidade')) {
            $(li).removeClass("hiddenElement");
            $(li).addClass("showElement");

            $(li).parents('.campo').removeClass("hiddenElement");
            $(li).parents('.campo').addClass("showElement");
          }

          if (li.classList.contains('campo')) {
            $(li).parents('.unidade').removeClass("hiddenElement");
            $(li).parents('.unidade').addClass("showElement");

            $(".label_exp[mostraLabelCampos = 'true']").removeClass("showElement")
            $(".label_exp[mostraLabelCampos = 'true']").addClass("hiddenElement")

          }

          if (li.classList.contains('poco')) {

            $(".label_exp[mostraLabelCampos = 'true']").attr("mostraLabelCampos", "false")
            $(".label_exp[mostraLabelCampos = 'false']").addClass("showElement")

            $(li).parents('.unidade').removeClass("hiddenElement");
            $(li).parents('.unidade').addClass("showElement");

            $(li).parents('.campo').removeClass("hiddenElement");
            $(li).parents('.campo').addClass("showElement");
          }
        }
        return
      }
      else {
        li.classList.remove("showElement");
        li.classList.add("hiddenElement");
      }
    });

    var escondeNaoSelecionado = $(".label_exp[expanded = 'false']").parents("mat-row")
    escondeNaoSelecionado.css("display", "none")

    var mostraNaoSelecionado = $(".label_exp[expanded = 'true']").parents("mat-row")
    mostraNaoSelecionado.css("display", "block")

  }

  AlteraEstadoLabel(valor) {
    var statusAtual = valor.getAttribute("expanded")
    if (statusAtual == "true") {
      valor.setAttribute("expanded", "false")
    }
    else {
      valor.setAttribute("expanded", "true")
    }
  }

  async loadTreeWells(unopId, fieldId) {
    let unop = this.dataSource.data.find(u => u.url === unopId);
    let field = unop.oilFields.find(c => c.url == fieldId);

    if(field.wells === undefined || field.wells === null){
      var response = await this.menuPocoService.GetPocosPorCampo(fieldId);
      field.wells = [];

      response.data.forEach(element => {
        field.wells.push(element);
      });

      //this.dataSource.data = this.dadosMenu;
      this.loadWells = true;
    }
  }

  async loadTreeInterventions(unopId, fieldId, wellId) {
    let unop = this.dataSource.data.find(u => u.url === unopId);
    let field = unop.oilFields.find(c => c.url == fieldId);
    let well = field.wells.find(p => p.url == wellId);

    if (well.interventions === undefined || well.interventions === null ){
      var response = await this.menuPocoService.GetInterventionsByUrlWell(wellId);
      well.interventions = [];

      response.data.forEach(element => {
        well.interventions.push(element);
      });

      //this.dataSource.data = this.dadosMenu;
    }
  }

  PainelIntervencao(id, nodeWellUrl, unName, unUurl, nodeOilName, nodeOilUrl,  nodeWellName) {

    localStorage.setItem('PocoSelecionado', JSON.stringify(nodeWellName));
    localStorage.setItem('UrlPocoSelecionado', JSON.stringify(nodeWellUrl));

    localStorage.setItem('CampoSelecionado', JSON.stringify(nodeOilName));
    localStorage.setItem('UrlCampoSelecionado', JSON.stringify(nodeOilUrl));
    localStorage.setItem('UnidadeSelecionada', JSON.stringify(unName));
    localStorage.setItem('UrlUnidadeSelecionada', JSON.stringify(unUurl));


    this.router.navigate(['/painel/' + id]);
  }
}
