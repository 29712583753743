import { AfterContentChecked, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnidadeIntervencaoService } from '../services/unidadeIntervencao.service';
import { interventionFiles, File, Revision } from '../model/interventionFiles';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment } from '../model/attachments';
import { AttachmentService } from '../services/attachment.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxGalleryOptions, NgxGalleryOrder } from '@kolkov/ngx-gallery';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatTabGroup } from '@angular/material/tabs';
declare var $: any

@Component({
  selector: 'app-intervention-panel',
  templateUrl: './intervention-panel.component.html',
  styleUrls: ['./intervention-panel.component.css', '../app.component.css']
})
export class InterventionPanelComponent implements OnInit, AfterContentChecked {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  verGaleria: boolean = false
  arquivoJaExiste: boolean = false
  jaAbriuGaleria: boolean = false
  carregado = false;
  intervention: interventionFiles;
  attachments: Attachment[];
  files: File[];
  atividades: any[]
  operacoes: any[]
  galleryOptions: NgxGalleryOptions[];
  galeria: Attachment[];
  galeriaFull: Attachment[];
  arquivoGenericos: Attachment[];
  arquivoGenericosFull: Attachment[];

  displayedColumns = ['name', 'sonda', 'url'];
  idIntervention = '';
  closeResult: string;
  modalReference: NgbModalRef;

  anexo: any;
  nomeArquivo: string;
  fase: string;
  atividade: string;
  operacao: string;
  descricaoAnexo: string;
  data: string;
  tags: any;
  loading = false;
  autocompleteItems: any[];
  autocompleteItemsFiltro: any[];
  tagsParaInserir: string[];

  filtrosGaleria: Attachment = {
    atividade: null,
    operacao: null,
    fase: null,
    name: null
  };

  idAnexoToRemove: string
  idFileToRemove: string

  tagsFiltro: any[]
  filtroListaAtividades: any[] = []
  filtroListaOperacoes: any[] = []
  arrayImagensTagsEncontradas: Attachment[] = []
  arrayArquivosByTagsEncontrados: Attachment[] = []

  constructor(
    private route: ActivatedRoute,
    private interventionService: UnidadeIntervencaoService,
    private attachmentService: AttachmentService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private snackBar: MatSnackBar,
    private modalService: NgbModal
  ) {
    this.galleryOptions =
      [
        {
          image: false,
          height: "800px",
          width: "80%",
          previewCloseOnClick: true,
          previewCloseOnEsc: true,
          previewZoom: true,
          previewRotate: true,
          thumbnailsOrder: NgxGalleryOrder.Page,
          previewKeyboardNavigation: true,
          previewFullscreen: true,
          thumbnailsColumns: 4,
          thumbnailsRows: 4
        },
        { "breakpoint": 500, "width": "100%" }
      ]
  }

  ngOnInit() {
    this.getTags();

    this.getAllAtividades();
    this.idIntervention = this.route.snapshot.paramMap.get('id');
    this.getFilesFromIntervention(this.idIntervention);
    this.getAttachmentsFromIntervention(this.idIntervention);
    //this.getTagsFiltro()

    this.data = new Date().toISOString().split('T')[0];
  }

  ngAfterContentChecked() {
    window.document.title = this.intervention != undefined ? this.intervention.name : "Painel";
  }

  async getFilesFromIntervention(id) {

    try {
      const response = await this.interventionService.GetFilesFromIntervention(id);
      if (response.status == 200) {
        this.intervention = response.data;
        this.files = this.intervention.arquivos;
        setTimeout(() => {
          this.carregado = true
        }, 100)
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  async getAttachmentsFromIntervention(id) {
    try {
      const response = await this.interventionService.GetAttachmentsFromIntervention(id);
      if (response.status == 200) {
        this.attachments = response.data;
        setTimeout(() => {
          this.carregado = true
        }, 1000)
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  async getAttachDownload(attach) {
    this.carregado = false;
    let response = await this.attachmentService.getAttachToDownload(attach.anexoId);

    const file = new Blob([response.data], { type: attach.mimeType });

    let link = document.getElementById(attach.anexoId);
    let url = URL.createObjectURL(file);

    link.setAttribute("href", url);
    link.click();

    setTimeout(() => {
      this.carregado = true
    }, 100)
  }

  getLastRevision(file: File): Revision {
    if (file.revisions.length > 0)
      return file.revisions.slice(-1).pop();

    return null;
  }

  calculateDiff(file: File) {
    let d2: Date = new Date();
    let d1 = Date.parse(file.created_at.toString());

    if (file.revisions.length > 0) {
      d1 = Date.parse(file.revisions.slice(-1).pop().created_at.toString());
    }

    var timeDiff = d2.getTime() - d1;
    var diff = timeDiff / (1000 * 3600 * 24);

    if (diff > 365) {
      diff = diff / 365;

      return "há " + Math.floor(diff) + " anos";
    }

    return "há " + Math.floor(diff) + " dias";
  }

  open(content) {

    this.modalReference = this.modalService.open(content, { size: 'lg', backdrop: 'static', centered: true, backdropClass: 'customBackdrop' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.limpaCampos()
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
		this.getAllAtividades()
      return `with: ${reason}`;
    }
  }

  limparSelecaoData() {

    var id = $("#inputData").val()
    if (id == '') {
      this.data = null
    }
  }

  limparSelecaoDataFiltro() {

    var id = $("#inputDataFiltro").val()
    if (id == '') {
      this.filtrosGaleria.data = null
    }
  }

  onFileSelect(input) {

    if (input.files && input.files[0]) {
      this.anexo = input.files[0];
      this.nomeArquivo = this.anexo.name.split('.')[0];

      if (this.attachments.find(x => x.name == this.nomeArquivo)) {
        this.arquivoJaExiste = true;
        this.anexo = null;
        $('#selecionarFile').val(null)
      }
      else {
        this.arquivoJaExiste = false;
        this.anexo = input.files[0];
        this.nomeArquivo = this.anexo.name.split('.')[0];
      }

      if (this.tags == undefined) {
        this.getTags()
        this.tags = []
      }

    }
  }

  saveFile() {
    this.loading = true;
    this.tagsParaInserir = this.tags.map(x => x.display)

    if (this.intervention.tipoIntervencao != "Perfuração") {
      this.fase = ""
    }
    else {
      this.fase = this.fase != undefined ? this.fase : ""
    }

    this.atividade = this.atividade != undefined ? this.atividade : ""
    this.operacao = this.operacao != undefined ? this.operacao : ""
    this.descricaoAnexo = this.descricaoAnexo != undefined ? this.descricaoAnexo : ""
    let urlParent = `api/public/intervention/${this.idIntervention}`;
    try {
      document.getElementById('btnSalvarAnexo').setAttribute("disabled", "disabled");
      this.attachmentService.saveAttachment(this.anexo, this.nomeArquivo + '__' + this.idIntervention, this.descricaoAnexo, urlParent, this.fase, this.atividade, this.operacao, this.data, this.tagsParaInserir).then(response => {

        this.modalReference.dismiss('Cross click')
        this.getAttachmentsFromIntervention(this.idIntervention);

        var tipoArquivo = this.anexo.type.split('/')[0];
        tipoArquivo == 'image' ? this.GetImagesAttachmentsFromIntervention(this.idIntervention) : this.GetPDFAndWordAttachmentsFromIntervention()
        this.getTags()
        //this.getTagsFiltro()
        this.loading = false;
        this.snackBar.open("Anexo adicionado.", "OK", { duration: 4000, panelClass: ['sucesso'] });
      });
    } catch (error) {
      this.loading = false;
      document.getElementById('btnSalvarAnexo').removeAttribute("disabled");
      this.getAttachmentsFromIntervention(this.idIntervention);
      this.modalReference.dismiss('Cross click')
      this.snackBar.open("Falha ao adicionar anexo.", "OK", { duration: 4000, panelClass: ['erro'] });
    }
  }

  getUrlFile(content: Object) {
    let uriFile = "data:text/json;charset=UTF-8,";
    let fileEnconded = encodeURIComponent(JSON.stringify(content));
    return this.sanitizer.bypassSecurityTrustUrl(uriFile + fileEnconded);
  }

  goBack() {
    this.router.navigate(['/intervencao/tree-detail']);
  }

  AbrirGaleria(opcao) {
    this.carregado = false;
    this.verGaleria = opcao
    if (this.verGaleria && !this.jaAbriuGaleria) {
      this.jaAbriuGaleria = true;
      this.GetImagesAttachmentsFromIntervention(this.idIntervention);
    }
    else {
      this.getTags()
      this.getAllAtividades()
      this.carregado = true
    }
  }

  async GetImagesAttachmentsFromIntervention(id) {
    try {
      const response = await this.interventionService.GetImagesAttachments(id);
      if (response.data.length > 0) {
        this.galeria = response.data
        this.galeriaFull = response.data;

        this.arquivoGenericos = this.attachments.filter(x => !x.mimeType.substring(0, 5).startsWith('image'));
        this.arquivoGenericosFull = this.arquivoGenericos

        setTimeout(() => {
          this.carregado = true
        }, 100)
      }
      else {
        this.snackBar.open("Nenhuma imagem encontrada.", "OK", { duration: 4000, panelClass: ['erro'] });
        this.jaAbriuGaleria = true;
        this.AbrirGaleria(true);
        this.carregado = true
        this.galeria = []
      }
    } catch (error) {
      this.carregado = true;
      console.error(error);
      this.galeria = []
    }
  }

  async GetPDFAndWordAttachmentsFromIntervention() {
    try {
      this.carregado = false
      this.arquivoGenericos = this.attachments.filter(x => !x.mimeType.substring(0, 5).startsWith('image'));
      this.arquivoGenericosFull = this.arquivoGenericos
      setTimeout(() => {
        this.carregado = true
      }, 100)

    } catch (error) {
      this.carregado = true;
      console.error(error);
      this.arquivoGenericos = []
    }
  }

  desabilitaCampos() {
    if (this.anexo == null || this.tags.length == 0 || (this.atividade != null && this.operacao == null)) {
      return false
    }
    return true
  }

  limpaCampos() {
    this.tags = []
    this.atividades = []
    this.data = ""
    this.descricaoAnexo = ""
    this.fase = ""
    this.anexo = null
    this.limparSelecaoAtividade()
  }

  async getTags() {
    try {
      await this.attachmentService.getTags("null").then(res => {
        if (res.data != "") {
          this.autocompleteItems = res.data;
        }
      }).then(resp => {
        this.getTagsFiltro()
      })
    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  getTagsFiltro() {
    try {
      this.attachmentService.getTags(this.idIntervention).then(res => {
        if (res.data != "") {
          this.autocompleteItemsFiltro = res.data;
        }
      })

    } catch (error) {
      this.carregado = true;
      console.error(error);
    }
  }

  limparSelecaoAtividade() {
    this.atividade = null
    this.operacoes = []
    this.limparSelecaoOperacao()
  }

  limparSelecaoOperacao() {
    this.operacao = null
  }

  limparSelecaoAtividadeFiltro() {
    this.filtrosGaleria.atividade = null
    this.filtroListaOperacoes = []
    this.limparSelecaoOperacaoFiltro()
  }

  limparSelecaoOperacaoFiltro() {
    this.filtrosGaleria.operacao = null
  }

  PreencheOperacoesFiltro(event) {
    if (this.filtrosGaleria.atividade != null) {
      this.getAllOperacoes(event.idAtividade)
    }
  }

  PreencheOperacoes(event) {
    if (this.atividade != null) {
      this.getAllOperacoes(event.idAtividade)
    }
  }

  async getAllAtividades() {
    try {
      const response = await this.attachmentService.getAtividades();
      if (response.data != null) {
        this.atividades = response.data
        this.filtroListaAtividades = response.data
      }
      else {
        this.atividades = null;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAllOperacoes(idAtividade) {
    try {
      const response = await this.attachmentService.getOperacoes(idAtividade);
      if (response.data != null) {
        this.operacoes = response.data;
        this.filtroListaOperacoes = response.data;
      }
      else {
        this.operacoes = null;
      }
    } catch (error) {
      console.error(error);
    }
  }

  limpaCamposFiltros() {

    $('[name="dataInicioFiltro"]').val(null);
    $('[name="dataFimFiltro"]').val(null);
    this.tags = []
    this.filtrosGaleria.atividade = null
    this.filtrosGaleria.data = null
    this.filtrosGaleria.fase = null
    this.filtrosGaleria.tags = null
    this.tagsFiltro = null
    this.filtrosGaleria.name = null
    this.arrayImagensTagsEncontradas = []
    this.arrayArquivosByTagsEncontrados = []
    this.limparSelecaoAtividadeFiltro()
  }

  filtrar() {

    this.carregado = false;
    try {
      var pFase = this.filtrosGaleria.fase;
      var pAtivi = this.filtrosGaleria.atividade;
      var pOperacao = this.filtrosGaleria.operacao

      var pTags = this.tagsFiltro != null ? this.tagsFiltro : null;
      this.galeria = this.galeriaFull;
      this.arquivoGenericos = this.arquivoGenericosFull;

      var ini = $('[name="dataInicioFiltro"]').val();
      var fim = $('[name="dataFimFiltro"]').val();

      if (ini != "" && fim == "") {
        var nowDate = new Date();
        fim = nowDate.getDate() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getFullYear();
      }

      if (ini > fim) {
        this.snackBar.open("A data inicial não pode ser maior que hoje!.", "OK", { duration: 4000, panelClass: ['erro'] });
        this.carregado = true
        return
      }

      if (pTags != undefined && pTags.length > 0 && pTags != null) {

        var teste;

        for (let index = 0; index < pTags.length; index++) {
          const element = pTags[index];
          teste += teste != undefined ? ',' + element.display : element.display
        }

        teste = teste.replace('undefined', '')
        var t = teste.split(',')
        this.arrayImagensTagsEncontradas = []
        this.arrayArquivosByTagsEncontrados = []

        for (let index = 0; index < this.galeria.length; index++) {
          const element = this.galeria[index];

          for (let index2 = 0; index2 < t.length; index2++) {
            const element2 = t[index2];
            if (element.tags.indexOf(element2) >= 0) {

              this.arrayImagensTagsEncontradas.push(element)

              this.arrayImagensTagsEncontradas = this.arrayImagensTagsEncontradas.filter((el, i, a) => i === a.indexOf(el))
            }
          }
        }

        for (let index = 0; index < this.arquivoGenericos.length; index++) {
          const element = this.arquivoGenericos[index];

          for (let index2 = 0; index2 < t.length; index2++) {
            const element2 = t[index2];
            if (element.tags.indexOf(element2) >= 0) {

              this.arrayArquivosByTagsEncontrados.push(element)
              this.arrayArquivosByTagsEncontrados = this.arrayArquivosByTagsEncontrados.filter((el, i, a) => i === a.indexOf(el))
            }
          }
        }

        this.galeria = this.arrayImagensTagsEncontradas
        this.arquivoGenericos = this.arrayArquivosByTagsEncontrados
      }

      this.galeria = this.galeria.filter(function (elem) {
        return ((ini != "" && fim != "") ? elem.data >= ini && elem.data <= fim : "&nbsp") &&
          (pAtivi != null ? elem.atividade == pAtivi : "&nbsp") &&
          (pOperacao != null ? elem.operacao == pOperacao : "&nbsp") &&
          (pFase != null ? elem.fase == pFase : "&nbsp")
      });

      this.arquivoGenericos = this.arquivoGenericos.filter(function (elem) {
        return ((ini != "" && fim != "") ? elem.data >= ini && elem.data <= fim : "&nbsp") &&
          (pAtivi != null ? elem.atividade == pAtivi : "&nbsp") &&
          (pOperacao != null ? elem.operacao == pOperacao : "&nbsp") &&
          (pFase != null ? elem.fase == pFase : "&nbsp")
      });

      if (this.galeria.length == 0 && this.arquivoGenericos.length > 0) {
        this.tabGroup.selectedIndex = 1
      }
      else if (this.galeria.length > 0 && this.arquivoGenericos.length == 0) {
        this.tabGroup.selectedIndex = 0
      }
      else {
        this.tabGroup.selectedIndex = 0
      }

      setTimeout(() => {
        this.carregado = true
      }, 300);

    } catch (error) {
      this.galeria = this.galeriaFull;
      this.arquivoGenericos = this.arquivoGenericosFull;

      this.carregado = true;
      this.snackBar.open("Ocorreu algum erro inesperado!.", "OK", { duration: 4000, panelClass: ['erro'] });
      console.log(error)
    }
  }

  openModalToRemoveAttachAndFile(content, element) {

    this.idAnexoToRemove = element.anexoId

    if (this.idAnexoToRemove == undefined) {
      this.idFileToRemove = element.id
    }

    this.modalReference = this.modalService.open(content, { size: 'lg', backdrop: 'static', centered: true, backdropClass: 'customBackdrop' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.idAnexoToRemove = undefined
      this.idFileToRemove = undefined
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  removeAttach() {
    try {
      this.attachmentService.removeAttach(this.idAnexoToRemove).then(dele => {

        if (dele.status == 204) {

          this.snackBar.open("Anexo removido com sucesso.", "OK", { duration: 4000, panelClass: ['sucesso'] });
          this.getAttachmentsFromIntervention(this.idIntervention)
        }
        else {
          this.snackBar.open("Erro ao remover Anexo.", "OK", { duration: 4000, panelClass: ['erro'] });
        }
      })

    } catch (error) {
      this.snackBar.open("Erro ao remover Anexo.", "OK", { duration: 4000, panelClass: ['erro'] });
      console.log(error);
    }
  }

  removeFile() {
    try {
      this.interventionService.RemoveFile(this.idFileToRemove).then(dele => {

        if (dele.status == 204) {
          this.snackBar.open("Arquivo removido com sucesso.", "OK", { duration: 4000, panelClass: ['sucesso'] });
          this.getFilesFromIntervention(this.idIntervention)
        }
        else {
          this.snackBar.open("Erro ao remover Arquivo.", "OK", { duration: 4000, panelClass: ['erro'] });
        }
      })

    } catch (error) {
      this.snackBar.open("Erro ao remover Arquivo.", "OK", { duration: 4000, panelClass: ['erro'] });
      console.log(error);
    }
  }
}
